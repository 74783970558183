<template>
	<Teleport to="#app">
		<div
			class="z-50 fixed inset-0 bg-black bg-opacity-60 flex justify-center items-start filter drop-shadow-lg overflow-y-scroll"
		>
			<div class="z-20 absolute inset-0 bg-transparent" @click.self="handleAwayClick"></div>

			<div class="z-30 w-full mx-4 md:mx-0 py-0 max-w-screen-sm bg-brown-900 rounded my-4 md:my-12 shadow-lg">
				<slot name="default" :confirm="confirm" :cancel="cancel" :close="close" />
			</div>
		</div>
	</Teleport>
</template>
<script lang="ts">
import { useAppStore } from '@/stores/AppStore'
import { defineComponent, PropType, onMounted, onUnmounted } from 'vue'

export default defineComponent({
	props: {
		closeOnClickAway: {
			type: Boolean,
			default: false,
			required: false,
		},
	},
	emits: ['close', 'cancel', 'confirm'],

	setup(props, { emit: $emit, expose }) {
		const appStore = useAppStore()
		onMounted(() => {
			if (appStore.modalStackCount == 0) {
				document.body.classList.add('overflow-hidden')
			}

			appStore.modalStackCount += 1
		})

		onUnmounted(() => {
			appStore.modalStackCount -= 1
			if (appStore.modalStackCount < 1) {
				document.body.classList.remove('overflow-hidden')
			}
		})

		let close = () => {
			$emit('close')
		}

		const confirm = (evt: any) => {
			$emit('confirm', evt)
		}

		const cancel = (evt: any) => {
			$emit('cancel', evt)
		}

		const handleAwayClick = (evt: any) => {
			if (props.closeOnClickAway) {
				close()
			}
		}

		// allow this to be called from by parent when used with 'ref'
		expose({ close, confirm, cancel })

		return {
			props,
			close,
			confirm,
			cancel,
			handleAwayClick,
		}
	},
})
</script>
