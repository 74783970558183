<template>
	<span>
		<span v-if="null !== amount"
			>${{ $format(amount, forceDecimals) }}
			<span v-if="props.showUsd">USD</span>
		</span>
		<loading-placeholder v-else class="w-12 rounded-lg">&nbsp;</loading-placeholder>
	</span>
</template>
<script lang="ts">
import { useExchangeStore } from '@/stores/ExchangeStore'
import { computed, defineComponent, onMounted, PropType, ref, watch } from 'vue'
import LoadingPlaceholder from './LoadingPlaceholder.vue'
import sleep from '@/util/sleep'

export default defineComponent({
	components: { LoadingPlaceholder },
	props: {
		bpx: {
			required: true,
			type: [Number, String],
		},
		isDecimal: {
			required: false,
			type: Boolean,
			default: () => false,
		},
		showUsd: {
			required: false,
			type: Boolean,
			default: () => true,
		},
		forceDecimals: {
			required: false,
			type: Boolean,
			default: () => false,
		},
	},
	setup(props) {
		const exchStore = useExchangeStore()
		const amount = ref(null)

		function updateAmount() {
			if (String(props.bpx).length == 0) {
				amount.value = null
			}

			exchStore.usd(String(props.bpx).replace(/\D/g, ''), props.isDecimal).then((val) => {
				if (val == 0) {
					return sleep(0.25).then(() => updateAmount())
				}

				amount.value = Math.round(val) / 100
			})
		}

		watch(
			() => props.bpx,
			(newAmt) => updateAmount(),
		)

		updateAmount()

		return {
			props,
			amount,
		}
	},
})
</script>
