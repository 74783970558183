<style>
.bg-animate {
	-webkit-animation: bgFade 3s ease infinite;
	-moz-animation: bgFade 3s ease infinite;
	animation: bgFade 3s ease infinite;
	background-size: 300%;
}

@keyframes bgFade {
	0%,
	100% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
}
</style>
<template>
	<div class="bg-gradient-to-r from-white via-gray-200 to-white bg-animate">
		<slot name="default" />
	</div>
</template>
<script lang="ts">
import { defineComponent, PropType } from 'vue'

export default defineComponent({
	setup(props) {
		return {}
	},
})
</script>
