import { library } from '@fortawesome/fontawesome-svg-core'

import {
	faArrowUpRightFromSquare,
	faAward,
	faBagShopping,
	faBan,
	faCardsBlank,
	faCartMinus,
	faCartPlus,
	faCheck,
	faChevronLeft,
	faChevronRight,
	faCircleCheck,
	faCircleExclamation,
	faClock,
	faCreditCard,
	faExternalLink,
	faEye,
	faGavel,
	faHeart,
	faHouse,
	faLayerGroup,
	faPaperPlane,
	faPiggyBank,
	faShoppingBag,
	faShoppingCart,
	faSpinnerThird,
	faStore,
	faMoneyBillTransfer,
	faMailboxFlagUp,
	faEnvelope,
} from '@awesome.me/kit-428607b2fd/icons/classic/regular'

library.add(
	faArrowUpRightFromSquare,
	faAward,
	faBagShopping,
	faBan,
	faCardsBlank,
	faCartMinus,
	faCartPlus,
	faCheck,
	faChevronLeft,
	faChevronRight,
	faCircleCheck,
	faCircleExclamation,
	faClock,
	faCreditCard,
	faExternalLink,
	faEye,
	faGavel,
	faHeart,
	faHouse,
	faLayerGroup,
	faPaperPlane,
	faPiggyBank,
	faShoppingBag,
	faShoppingCart,
	faSpinnerThird,
	faStore,
	faMoneyBillTransfer,
	faMailboxFlagUp,
	faEnvelope,
)
