import { library } from '@fortawesome/fontawesome-svg-core'

import {
	faBarsSort,
	faClose,
	faCircleExclamation,
	faDroplet,
	faExclamationCircle,
	faGlobe,
	faMinus,
	faPlus,
	faSignIn,
	faSignOut,
	faSpinnerScale,
	faSpinnerThird,
	faTimes,
	faMoneyBillTransfer,
} from '@awesome.me/kit-428607b2fd/icons/sharp/solid'

library.add(
	faBarsSort,
	faClose,
	faCircleExclamation,
	faDroplet,
	faExclamationCircle,
	faGlobe,
	faMinus,
	faPlus,
	faSignIn,
	faSignOut,
	faSpinnerScale,
	faSpinnerThird,
	faTimes,
	faMoneyBillTransfer,
)
