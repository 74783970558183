import { defineStore } from 'pinia'
import { useAuthStore } from './AuthStore'
import { computed, ref, watch } from 'vue'
import api from '@/util/api'
import { BN } from 'bn.js'
import { useExchangeStore } from './ExchangeStore'

const pollingTimeout = 3 * 1000

const CURRENCY_USABLE_BPX = 'bpx'
const CURRENCY_STORE_CREDIT = 'usd-store-credit'
const CURRENCY_PENDING_AUCTION_CREDIT = 'usd-auction-credit'

export interface MarketCurrency {
	id: string
	name: string | null
	slug: string | null
	balance: string | number
	available: string | number
	amount: number
	contract: string
	network: number
	token: number
	type: string
}

export const useNewWalletStore = defineStore('newWallet', () => {
	const authStore = useAuthStore()
	let walletMonInterval

	const balances = ref<MarketCurrency[]>([] as MarketCurrency[])
	const balanceModalVisible = ref(false)

	function init() {
		watch(
			() => authStore.user,
			(newUser, oldUser) => {
				// console.log('authstore user changed');
				if (newUser.id == (oldUser?.id ?? null)) {
					return
				}

				monitorBalances()
			},
		)

		authStore.checkLogin().then((loggedIn) => {
			if (loggedIn) {
				pollBalance()
			}
		})

		if (authStore.authenticated && !walletMonInterval) {
			pollBalance()
			monitorBalances()
		}

		return {
			// constants
			CURRENCY_PENDING_AUCTION_CREDIT,
			CURRENCY_STORE_CREDIT,
			CURRENCY_USABLE_BPX,

			// state
			balances,
			balanceModalVisible,

			// actions
			pollBalance,
			toggleBalanceModal,
			withdraw,

			// getters
			visibleBpx,
			withdrawableBpx,
			balancesByType,
		}
	}

	function monitorBalances() {
		clearInterval(walletMonInterval)
		clearBalances()

		if (authStore.user.id) {
			walletMonInterval = setInterval(pollBalance, pollingTimeout)
		}
	}

	function clearBalances() {
		balances.value = [] as MarketCurrency[]
	}

	async function pollBalance() {
		if (!authStore.authenticated) {
			clearBalances()
		}

		const newBalances = await api.getCurrencyBalances()
		balances.value = newBalances
	}

	function toggleBalanceModal(force?: boolean) {
		if (force === undefined) {
			force = !balanceModalVisible.value
		}

		// console.log('modal', force);

		balanceModalVisible.value = force
	}

	async function withdraw(asset: string, amount: string, wallet: string) {
		const response = await api.withdraw(asset, amount, wallet)

		return response
	}

	const visibleBpx = computed(() => {
		return balances.value
			.filter((c) => c.slug == 'bpx' || c.slug == 'vbpx')
			.reduce((t, c) => {
				return t.add(new BN(String(c.available)))
			}, new BN(0))
	})

	const withdrawableBpx = computed(() => {
		const bpx = balances.value.find((c) => c.slug == 'bpx')

		if (bpx) {
			return String(bpx.available)
		}

		return '0'
	})

	const balancesByType = computed(() => {
		let b = {}

		b[CURRENCY_USABLE_BPX] = balances.value
			.filter((b) => ['bpx', 'vbpx'].indexOf(b.slug) >= 0)
			.reduce((tot, bal) => {
				for (const k in bal) {
					const v = bal[k]
					if (typeof v !== 'number') {
						if (undefined == tot[k]) {
							tot[k] = v
						}

						continue
					}

					if (undefined == tot[k]) {
						tot[k] = 0
					}

					tot[k] += v
				}

				return tot
			}, {})
		b[CURRENCY_PENDING_AUCTION_CREDIT] = balances.value.find((b) => b.slug == 'usd-auction-credit') ?? null
		b[CURRENCY_STORE_CREDIT] = balances.value.find((b) => b.slug == 'usd-store-credit') ?? null

		return b
	})

	return init()
})
