import { computed, ref } from 'vue'
import { defineStore } from 'pinia'
import api from '@/util/api'
import { Asset } from '@/types/Asset'
import { useNewWalletStore } from './NewWalletStore'
import { useAuthStore } from './AuthStore'
import Web3 from 'web3/dist/web3.min.js'

const milesAssetId = `1:${import.meta.env.VITE_BPX_ADDRESS || '0xSomethingSomething'}:0`
const BULK_TRANFER_LIMIT = import.meta.env.VITE_BULK_TRANSFER_LIMIT || 50
const errorMsg = ref<null | string>(null)

export interface BasketItem {
	asset_id: string
	quantity: number | bigint
	asset: Asset
	isQuantityValid?: boolean
}

function isAssetId(assetId: string) {
	let regex = /^\d+:0x[0-9a-fA-F]{40}:\d+$/i
	return regex.test(assetId)
}

export const useBulkTransferStore = defineStore('bulkTransfer', () => {
	const nonce = ref<string | null>(null)
	const newWalletStore = useNewWalletStore()
	const recipient = ref<{ username: string; id: string } | null>(null)
	const basket = ref<BasketItem[]>([])
	const isBasketModeEnabled = ref(false)
	const isMilesTransferEnabled = ref(false)
	const isConfirmed = ref(false)
	const milesToTransfer = ref({
		asset_id: milesAssetId.toLowerCase(),
		qty: 0,
	})
	const require2fa = ref(false)
	const sid = ref<string | null>(null)

	const authStore = useAuthStore()

	const isMilesQtyValid = computed(() => {
		if (isNaN(milesToTransfer.value.qty)) {
			return false
		}
		const qty = humanReadableToBpx(milesToTransfer.value.qty.toString())
		const balance = newWalletStore.balances.reduce(function (bnBalance, bal) {
			if (bal.slug == 'bpx') {
				return bnBalance + BigInt(bal.available.toString())
			}
			return bnBalance
		}, BigInt(0))
		return qty > BigInt(0) && qty <= balance
	})

	function addItem(asset_id: string, quantity: number, asset: Asset | null = null) {
		if (isBasketModeEnabled.value === false) {
			isBasketModeEnabled.value = true
		}

		if (!isAssetId(asset_id)) {
			throw new Error('Invalid asset ID')
		}

		if (basket.value.length + 1 > BULK_TRANFER_LIMIT) {
			throw new Error(`Bulk transfer limit reached (limit: ${BULK_TRANFER_LIMIT})`)
		}

		const found = basket.value.find((item) => item.asset_id === asset_id)
		if (!found) {
			basket.value.push({
				asset_id,
				quantity,
				asset,
			})
		} else {
			throw new Error('Asset already in basket')
		}
	}

	function removeItem(asset_id: string) {
		const index = basket.value.findIndex((item) => item.asset_id === asset_id)
		if (index !== -1) {
			basket.value.splice(index, 1)
		} else {
			throw new Error('Asset not in basket')
		}

		if (basket.value.length < BULK_TRANFER_LIMIT) {
			errorMsg.value = null
		}
	}

	function updateItem(asset_id: string, quantity: number) {
		const found = basket.value.find((item) => item.asset_id === asset_id)
		if (found) {
			found.quantity = quantity
		} else {
			throw new Error('Could not update quantity. Asset not in basket')
		}
	}

	async function transfer() {
		const assets = basket.value.map((item) => {
			return {
				asset_id: item.asset_id,
				quantity: item.quantity,
			}
		})

		if (isMilesTransferEnabled.value) {
			assets.push({
				asset_id: milesToTransfer.value.asset_id,
				quantity: humanReadableToBpx(milesToTransfer.value.qty.toString()),
			})
		}

		if (nonce.value === null) {
			generateNonce(assets)
		}

		return await api.transferAssets(assets, recipient.value.username, null, nonce.value)
	}

	function resetBasket() {
		basket.value = []
		recipient.value = null
		isBasketModeEnabled.value = false
		isMilesTransferEnabled.value = false
		milesToTransfer.value.qty = 0
		isConfirmed.value = false
		require2fa.value = false
		errorMsg.value = null
		sid.value = null
	}

	function toggleBasketMode(enable?: boolean, reset?: boolean) {
		if (enable !== undefined) {
			isBasketModeEnabled.value = enable
		} else {
			isBasketModeEnabled.value = !isBasketModeEnabled.value
		}

		if (reset) {
			resetBasket()
		}
	}

	function toggleMilesTransfer(enable?: boolean) {
		if (enable !== undefined) {
			isMilesTransferEnabled.value = enable
		} else if (!isMilesTransferEnabled) {
			isMilesTransferEnabled.value = true
		} else {
			isMilesTransferEnabled.value = false
		}
	}

	const isValidBasketItemsQuantity = computed(() => {
		return basket.value.every((item) => item.isQuantityValid === true)
	})

	const isValidToTransfer = computed(() => {
		if (isNaN(milesToTransfer.value.qty)) {
			return false
		}
		return (
			recipient.value !== null &&
			isValidBasketItemsQuantity.value &&
			(isMilesTransferEnabled.value ? isMilesQtyValid.value : basket.value.length > 0)
		)
	})

	function humanReadableToBpx(humanValue: string): bigint {
		const parts = humanValue.split('.')
		const integralPart = BigInt(parts[0]) * BigInt(1e9)
		if (parts.length === 1) {
			return integralPart
		}
		// 9 decimal precision, pad with zeros to 9 digits (1e9)
		const truncated = parts[1].slice(0, 9).padEnd(9, '0')
		const decimalPart = BigInt(truncated)
		return integralPart + decimalPart
	}

	const numberOfCards = computed(() => {
		return basket.value.reduce((acc, item) => {
			// only count asset quantity, not miles
			if (item.asset_id !== milesAssetId) {
				acc += Number(item.quantity) || 0
			}
			return acc
		}, 0)
	})

	function generateNonce(assets: { asset_id: string; quantity: number | bigint }[]) {
		const assetIdsAndQty = assets
			.sort((a, b) => {
				if (a.asset_id < b.asset_id) {
					return -1
				}
				if (a.asset_id > b.asset_id) {
					return 1
				}
				return 0
			})
			.map((a) => `${a.asset_id}:${a.quantity}`)
			.join(',')

		const assetsHash = `${Web3.utils.keccak256(assetIdsAndQty)}`
		nonce.value = `${authStore.user.id}:${recipient.value.id}:${assetsHash}:${Date.now()}`
	}

	async function needs2fa(sidString?: string) {
		require2fa.value = true
		if (sid) {
			sid.value = sidString
		}
	}

	async function confirm2fa(code: number | string) {
		return api.confirm2fa(code, sid.value)
	}

	return {
		basket,
		addItem,
		removeItem,
		updateItem,
		resetBasket,
		toggleBasketMode,
		toggleMilesTransfer,
		isBasketModeEnabled,
		isMilesTransferEnabled,
		milesToTransfer,
		transfer,
		recipient,
		isValidToTransfer,
		numberOfCards,
		errorMsg,
		isMilesQtyValid,
		nonce,
		needs2fa,
		confirm2fa,
		sid,
	}
})
