<template>
	<header class="bpmp-layout__header">
		<div class="border-b-2 border-gray-300 flex space-x-4 mx-4 mt-4 text-center overflow-x-auto pb-1">
			<router-link
				:to="{ name: 'account' }"
				class="pb-1 px-4 text-sm md:text-lg font-medium text-gray-500"
				exact-active-class="-mb-0.5 border-b-2 border-gray-700 text-gray-900"
			>
				Your Collection
			</router-link>
			<router-link
				:to="{ name: 'account:favorites' }"
				class="pb-1 px-4 text-sm md:text-lg font-medium text-gray-500"
				exact-active-class="-mb-0.5 border-b-2 border-gray-500 text-gray-900"
			>
				Your Favorites
			</router-link>
			<router-link
				:to="{ name: 'account:auctions' }"
				class="pb-1 px-4 text-sm md:text-lg font-medium text-gray-500"
				exact-active-class="-mb-0.5 border-b-2 border-gray-500 text-gray-900"
			>
				Your Auctions
			</router-link>
			<router-link
				:to="{ name: 'account:activity' }"
				class="pb-1 px-4 text-sm md:text-lg font-medium text-gray-500"
				exact-active-class="-mb-0.5 border-b-2 border-gray-500 text-gray-900"
			>
				Activity
			</router-link>
			<router-link
				:to="{ name: 'account:wallet' }"
				class="pb-1 px-4 text-sm md:text-lg font-medium text-gray-500"
				exact-active-class="-mb-0.5 border-b-2 border-gray-500 text-gray-900"
			>
				Wallet
			</router-link>
			<router-link
				v-if="flagStore.checkFlag(Flags.OFFERS_CREATE)"
				:to="{ name: 'account:offers' }"
				class="pb-1 px-4 text-sm md:text-lg font-medium text-gray-500"
				exact-active-class="-mb-0.5 border-b-2 border-gray-500 text-gray-900"
			>
				Your Offers
			</router-link>
		</div>
	</header>
</template>
<script lang="ts" setup>
import { useFlagStore } from '@/stores/FlagStore'
import { Flags } from '@/types/Flags'

const flagStore = useFlagStore()
</script>
