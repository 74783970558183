import { defineStore } from 'pinia'
import Echo from 'laravel-echo'
import Pusher, { Channel, ChannelAuthorizationCallback, Options } from 'pusher-js'
import { ref } from 'vue'
// import { useNotificationStore } from './NotificationStore';
import axios from 'axios'

// set Pusher to window
window.Pusher = Pusher

// overriding Pusher's authorizer function to use axios and set withCredentials to true
// this allows us to send the cookies with the request via netlify functions
// ref: https://github.com/pusher/pusher-js/issues/471
const authorizer = (channel: Channel, options: Options) => {
	return {
		authorize: (socketId: string, callback: ChannelAuthorizationCallback) => {
			axios({
				method: 'GET',
				url: options.authEndpoint,
				params: {
					channel_name: channel.name,
					socket_id: socketId,
				},
				withCredentials: true,
			})
				.then((response) => {
					callback(null, response.data)
				})
				.catch((error) => {
					callback(new Error(`Error authenticating with server: ${error}`), {
						auth: '',
					})
				})
		},
	}
}

const API_ENDPOINT = import.meta.env.VITE_API_ENDPOINT
const BROADCAST_AUTH_URL = `${API_ENDPOINT}/api/broadcasting/auth`

export const useEchoChannelStore = defineStore('echoChannel', () => {
	// const notificationStore = useNotificationStore();
	const echo = new Echo({
		broadcaster: 'pusher',
		key: import.meta.env.VITE_PUSHER_APP_KEY,
		cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER,
		forceTLS: true,
		authorizer,
		auth: {
			headers: {
				Accept: 'application/json',
			},
		},
	})

	const activeChannels = ref<{ string?: Echo }>({})

	function init(userId?: string) {
		if (userId) {
			initWithPrivateChannels(userId)
		} else {
			initPublicChannels()
		}
	}

	function initPublicChannels() {
		console.log('PUBLIC CHANNELS')
		echo.channel('bids').listen('NewHighBidEvent', (bid) => {
			console.log('[ NewHighBidEvent ]', bid)
		})
	}

	async function initWithPrivateChannels(userId: string) {
		const privateChannel = `user.${userId}`

		echo.options = {
			...echo.options,
			authEndpoint: BROADCAST_AUTH_URL,
		}
		echo.disconnect()
		echo.connect()
		initPublicChannels()

		console.log('PRIVATE CHANNELS')
		echo.private(privateChannel)
			.listen('MaxBidReplaced', (bid) => {
				console.log('[ MaxBidReplaced ]', bid)
			})
			.listen('OutbidEvent', (bid) => {
				console.log('[ OutbidEvent ]', bid)
			})
	}

	function activateChannel(channel: string, event: string, cb: Function) {
		const storefrontChannel = echo.channel(channel).listen(event, cb)
		// activeChannels.value[channel] = storefrontChannel;
		console.log('ACTIVATE', channel, storefrontChannel)
	}

	function deactivateChannel(channel: string) {
		echo.leaveChannel(channel)
		// delete activeChannels.value[channel];
		console.log('DEACTIVATE', channel)
	}

	return {
		echo,
		init,
		activateChannel,
		deactivateChannel,
	}
})
