<template>
	<div class="flex justify-center items-center space-x-4">
		<a
			href="https://app.blokpax.com/sign-up"
			title="Sign up to get started!"
			class="p-3 uppercase text-sm font-medium"
			:class="props.isMainNav ? 'text-white ' : 'text-black'"
		>
			Sign up
		</a>

		<button
			class="bg-sky-800 hover:saturate-150 hover:scale-105 active:scale-105 active:hover:saturate-150 transition-all text-slate-100 text-sm uppercase font-medium py-3 px-12 rounded-full inline-block leading-none align-middle"
			:class="props.isMainNav ? 'hidden lg:block' : ''"
			@click="handleLoginClicked"
		>
			Log in
		</button>
	</div>
</template>
<script lang="ts" setup>
import { useAuthStore } from '@/stores/AuthStore'
import { useRoute } from 'vue-router'

const props = defineProps({
	isMainNav: {
		type: Boolean,
		required: false,
		default: true,
	},
})

const authStore = useAuthStore()
const route = useRoute()

async function handleLoginClicked() {
	authStore.redirectToLogin(route.path)
}
</script>
