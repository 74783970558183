<template>
	<transition name="fade" mode="out-in">
		<slot v-if="!state.connected" name="default" :connect="connect" :metamask="metamask"></slot>
		<slot v-else name="connected" :wallet="metamask.state.wallet" :metamask="metamask" />
	</transition>
</template>
<script lang="ts">
import { defineComponent, reactive, onMounted } from 'vue'
import metamask from '@/util/metamask'

export default defineComponent({
	setup(props, { emit: $emit }) {
		let ethInterval: any
		const state = reactive({
			connected: metamask.state.connected,
		})

		onMounted(() => {
			checkEthereum()
			ethInterval = setInterval(checkEthereum, 300)
		})

		function checkEthereum() {
			if (window.ethereum !== undefined) {
				clearInterval(ethInterval)
				attachEvents()
				metamask.init(window.ethereum)
			}
		}

		function attachEvents() {
			metamask.on('connected', async (wallet: string) => {
				state.connected = true
				$emit('connected', wallet)
			})
		}

		async function connect() {
			return metamask.connect()
		}

		return {
			state,
			metamask,
			connect,
		}
	},
})
</script>
