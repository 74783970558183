<template>
	<div>
		<slot v-for="(_, idx) in new Array(props.count).fill({})" :index="idx" />
	</div>
</template>
<script lang="ts">
import { defineComponent, PropType } from 'vue'

export default defineComponent({
	props: {
		count: {
			required: false,
			type: Number,
			default: () => 1,
		},
	},
	setup(props) {
		return {
			props,
		}
	},
})
</script>
