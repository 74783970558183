<template>
	<div>
		<div
			class="flex justify-between items-center hover:bg-gray-100 rounded-xl px-4 py-3 cursor-pointer"
			@click.prevent="toggleShow"
		>
			<p class="font-medium">{{ props.label }}</p>
			<p class="text-gray-500" v-show="!state.filtersVisible" v-cloak>
				<i class="fa-solid fa-chevron-down"></i>
			</p>
			<p class="text-gray-500" v-show="state.filtersVisible" v-cloak>
				<i class="fa-solid fa-chevron-up"></i>
			</p>
		</div>

		<div v-show="state.filtersVisible" v-cloak class="mt-2 ease-in-out duration-300">
			<filter-option
				v-for="(opt, k) in props.options"
				:key="`filter-opt-${opt.prop + ' ' + opt.val}`"
				@click.prevent="toggleOption(opt)"
				:label="opt.val"
				:count="opt.asset_count"
				:selected="toggledOptions.indexOf(opt.val) >= 0"
			/>
		</div>
	</div>
</template>
<script lang="ts">
import { useStorefrontStore } from '@/stores/StorefrontStore'
import { computed, defineComponent, onMounted, PropType, reactive, ref } from 'vue'
import FilterOption from '@/components/FilterOption.vue'
import { AssetMetaDataForFiltering } from '@/types/Storefront'

export default defineComponent({
	emits: ['filter'],
	components: { FilterOption },
	props: {
		label: {
			required: true,
			type: String,
		},
		options: {
			required: true,
			type: Object as PropType<AssetMetaDataForFiltering[]>,
		},
	},
	setup(props, { emit: $emit }) {
		const storefrontStore = useStorefrontStore()

		const toggledOptions = computed(() => {
			return storefrontStore.state.activeFilters[props.label] ?? []
		})

		const state = ref({
			filtersVisible: storefrontStore.isFilterActive(props.label),
		})

		function toggleShow() {
			state.value.filtersVisible = !state.value.filtersVisible
		}

		function toggleOption(opt: AssetMetaDataForFiltering) {
			storefrontStore.toggleFilter(props.label, opt.val)
			$emit('filter', props.label, storefrontStore.state.activeFilters[props.label])
		}

		return {
			props,
			state,
			toggleShow,
			toggleOption,
			toggledOptions,
			storefrontStore,
		}
	},
})
</script>
