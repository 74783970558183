class EventBus {
	constructor() {
		this.events = {}
	}

	on(eventName, fn) {
		if (undefined == this.events) {
			this.events = {}
		}

		if (undefined == this.events[eventName]) {
			this.events[eventName] = []
		}

		this.events[eventName].push(fn)
		// console.log(eventName, ' events: ', this.events[ eventName ]);

		return this
	}

	once(eventName, fn) {
		return this.on(eventName, (...data) => {
			fn(...data)
			this.off(eventName, fn)
		})
	}

	off(eventName, fn) {
		if (this.events[eventName]) {
			for (let i = 0; i < this.events[eventName].length; i++) {
				if (this.events[eventName][i] === fn) {
					this.events[eventName].splice(i, 1)
					return this
				}

				if (undefined === fn) {
					this.events[eventName].splice(i, 1)
					i -= 1
					continue
				}
			}
		}

		return this
	}

	emit(eventName, ...data) {
		// console.log('emitting event', eventName, data);
		if (this.events[eventName]) {
			this.events[eventName].forEach(function (fn) {
				fn(...data)
			})
		} else {
			// console.log('no listeners for event', eventName);
		}
	}
}

export default EventBus
