<template>
	<footer class="font-poppins bg-gray-900 text-center py-12 text-slate-300">
		<div class="max-w-screen-lg mx-auto">
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 120" class="max-w-[8rem] fill-[#495667] mx-auto">
				<path
					class="logo"
					d="M57.79 0a57.79 57.79 0 1 0 57.79 57.79A57.79 57.79 0 0 0 57.79 0ZM29.68 79.41a2.35 2.35 0 0 1 .49 1.42v8.45a.79.79 0 0 1-1.31.59 43.19 43.19 0 0 1 15.71-73.22.8.8 0 0 1 1 .76v23.12a.77.77 0 0 0 1.2.66 19.9 19.9 0 0 1 26.9 4.61.79.79 0 0 1-.34 1.2l-5.67 2.41a1.19 1.19 0 0 1-1.31-.28 12.18 12.18 0 0 0-20.69 7.46v1.39a1.16 1.16 0 0 1-.72 1.08L39 61.58a.79.79 0 0 1-1.09-.73V30a.79.79 0 0 0-1.25-.64 35.4 35.4 0 0 0-7 50.09ZM57.79 101a43.31 43.31 0 0 1-12.18-1.74 42.2 42.2 0 0 1-6.84-2.67 1.58 1.58 0 0 1-.88-1.42v-24a1.18 1.18 0 0 1 .72-1.09L41.33 69l4.28-1.82 2.31-1a1.21 1.21 0 0 1 1.31.27A12.17 12.17 0 0 0 69.91 59a13 13 0 0 0 0-1.36 1.2 1.2 0 0 1 .72-1.09l5.69-2.41a.78.78 0 0 1 1.09.59 19.88 19.88 0 0 1-30.6 19.65.78.78 0 0 0-1.2.66v15.23a1.18 1.18 0 0 0 .79 1.13 35.5 35.5 0 1 0 7.79-68.91.78.78 0 0 1-.87-.77v-6.18a.79.79 0 0 1 .73-.79 43.84 43.84 0 0 1 9 .15 43.21 43.21 0 0 1-5.26 86.1Z"
				/>
			</svg>

			<div class="text-balance max-w-[30ch] text-lg font-light mx-auto mt-6 text-slate-50">
				At Blokpax, our mission is to create thrilling digital and physical collectible adventures.
			</div>

			<div class="flex justify-center gap-4 mt-16 text-slate-950">
				<a
					href="https://www.instagram.com/blokpax/"
					rel="nofollow noreferrer"
					class="bg-white rounded-full size-12 grid place-items-center [&>svg]:size-8 [&:svg]:fill-current"
					title="Instagram"
				>
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
						<path
							d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
						/>
					</svg>
				</a>

				<a
					href="https://www.facebook.com/Blokpax/"
					rel="nofollow noreferrer"
					class="bg-white rounded-full size-12 grid place-items-center [&>svg]:size-8 [&:svg]:fill-current"
					title="Facebook"
				>
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
						<path
							d="M80 299.3V512H196V299.3h86.5l18-97.8H196V166.9c0-51.7 20.3-71.5 72.7-71.5c16.3 0 29.4 .4 37 1.2V7.9C291.4 4 256.4 0 236.2 0C129.3 0 80 50.5 80 159.4v42.1H14v97.8H80z"
						/>
					</svg>
				</a>

				<a
					href="https://www.youtube.com/@blokpax/"
					rel="nofollow noreferrer"
					class="bg-white rounded-full size-12 grid place-items-center [&>svg]:size-8 [&:svg]:fill-current"
					title="Youtube"
				>
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
						<path
							d="M549.7 124.1c-6.3-23.7-24.8-42.3-48.3-48.6C458.8 64 288 64 288 64S117.2 64 74.6 75.5c-23.5 6.3-42 24.9-48.3 48.6-11.4 42.9-11.4 132.3-11.4 132.3s0 89.4 11.4 132.3c6.3 23.7 24.8 41.5 48.3 47.8C117.2 448 288 448 288 448s170.8 0 213.4-11.5c23.5-6.3 42-24.2 48.3-47.8 11.4-42.9 11.4-132.3 11.4-132.3s0-89.4-11.4-132.3zm-317.5 213.5V175.2l142.7 81.2-142.7 81.2z"
						/>
					</svg>
				</a>

				<a
					href="https://www.x.com/blokpax/"
					rel="nofollow noreferrer"
					class="bg-white rounded-full size-12 grid place-items-center [&>svg]:size-8 [&:svg]:fill-current"
					title="X"
				>
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
						<path
							d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"
						/>
					</svg>
				</a>

				<a
					href="https://www.discord.gg/invite/blokpax/"
					rel="nofollow noreferrer"
					class="bg-white rounded-full size-12 grid place-items-center [&>svg]:size-8 [&:svg]:fill-current"
					title="Join us on Discord"
				>
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
						<path
							d="M524.5 69.8a1.5 1.5 0 0 0 -.8-.7A485.1 485.1 0 0 0 404.1 32a1.8 1.8 0 0 0 -1.9 .9 337.5 337.5 0 0 0 -14.9 30.6 447.8 447.8 0 0 0 -134.4 0 309.5 309.5 0 0 0 -15.1-30.6 1.9 1.9 0 0 0 -1.9-.9A483.7 483.7 0 0 0 116.1 69.1a1.7 1.7 0 0 0 -.8 .7C39.1 183.7 18.2 294.7 28.4 404.4a2 2 0 0 0 .8 1.4A487.7 487.7 0 0 0 176 479.9a1.9 1.9 0 0 0 2.1-.7A348.2 348.2 0 0 0 208.1 430.4a1.9 1.9 0 0 0 -1-2.6 321.2 321.2 0 0 1 -45.9-21.9 1.9 1.9 0 0 1 -.2-3.1c3.1-2.3 6.2-4.7 9.1-7.1a1.8 1.8 0 0 1 1.9-.3c96.2 43.9 200.4 43.9 295.5 0a1.8 1.8 0 0 1 1.9 .2c2.9 2.4 6 4.9 9.1 7.2a1.9 1.9 0 0 1 -.2 3.1 301.4 301.4 0 0 1 -45.9 21.8 1.9 1.9 0 0 0 -1 2.6 391.1 391.1 0 0 0 30 48.8 1.9 1.9 0 0 0 2.1 .7A486 486 0 0 0 610.7 405.7a1.9 1.9 0 0 0 .8-1.4C623.7 277.6 590.9 167.5 524.5 69.8zM222.5 337.6c-29 0-52.8-26.6-52.8-59.2S193.1 219.1 222.5 219.1c29.7 0 53.3 26.8 52.8 59.2C275.3 311 251.9 337.6 222.5 337.6zm195.4 0c-29 0-52.8-26.6-52.8-59.2S388.4 219.1 417.9 219.1c29.7 0 53.3 26.8 52.8 59.2C470.7 311 447.5 337.6 417.9 337.6z"
						/>
					</svg>
				</a>

				<a
					href="https://www.dextools.io/app/en/ether/pair-explorer/0x183b1a923800071eb88e86ee802bde31acfa85b7"
					rel="nofollow noreferrer"
					class="bg-white rounded-full size-12 grid place-items-center [&>svg]:size-8 [&:svg]:fill-current p-2"
					title="DEXTools USDC:BPX Pair"
					target="_blank"
				>
					<img src="/image/brand-dextools.png" alt="DEXTools" class="grayscale brightness-0" />
				</a>

				<a
					href="mailto:support-team@blokpax.com"
					class="bg-white rounded-full size-12 grid place-items-center [&>svg]:size-8 [&:svg]:fill-current"
					title="Contact Support"
				>
					<span class="text-2xl"><fa icon="fa-regular fa-envelope -fa-mailbox-flag-up" /></span>
				</a>
			</div>

			<div class="flex justify-center gap-2 mt-6 mx-auto text-slate-300 text-sm">
				<a
					href="/terms"
					target="_blank"
					rel="noreferrer nofollow"
					class="block underline-offset-2 hover:underline"
				>
					Terms
				</a>

				<div class="border-l border-slate-300"></div>

				<a
					href="/privacy"
					target="_blank"
					rel="noreferrer nofollow"
					class="block underline-offset-2 hover:underline"
				>
					Privacy
				</a>

				<div class="border-l border-slate-300"></div>

				<a
					href="/cookie-policy"
					class="block underline-offset-2 hover:underline"
					rel="noreferrer nofollow"
					target="_blank"
				>
					Cookie Policy
				</a>
			</div>

			<div class="text-sm text-center mt-2">&copy; 2024 Blokpax</div>
		</div>
	</footer>
</template>
