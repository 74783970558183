<template>
	<account-layout>
		<div class="relative px-4 mt-4">
			<AccountDrips :data-loader="loadActivity" />
		</div>
	</account-layout>
</template>
<script lang="ts" setup>
import AccountLayout from '@/layouts/AccountLayout.vue'
import AccountDrips from '@/components/Drips/AccountDrips.vue'
import { AccountDripIndexResponse } from '@/types/Drip'
import { useAccountStore } from '@/stores/AccountStore'

const accountStore = useAccountStore()

async function loadActivity(): Promise<AccountDripIndexResponse> {
	return (await accountStore.loadDrips()) as Promise<AccountDripIndexResponse>
}
</script>
