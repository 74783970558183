import { defineStore } from 'pinia'
import api from '@/util/api'
import {
	AssetFilter,
	NewOffer,
	Offer,
	Storefront,
	StorefrontActiveFilters,
	StorefrontAssetResponse,
	StorefrontMetadata,
} from '@/types/Storefront'
import { Asset, Trait } from '@/types/Asset'
import { Ref, computed, ref } from 'vue'
import EventBus from '@/util/eventBus'
import { Flag } from '@/types/Flags'
import { useFlagStore } from './FlagStore'

interface StoreFrontStore {
	storefront: Storefront | null
	metadata: StorefrontMetadata
	assets: Asset[]
	activeFilters: StorefrontActiveFilters
}

export const useStorefrontStore = defineStore('storefront', () => {
	const eventEmitter = new EventBus()
	const flagStore = useFlagStore()

	const state: Ref<StoreFrontStore> = ref({
		storefront: null,
		metadata: {},
		accountActivityData: {},
		assets: [],
		activeFilters: {},
		activityData: {},
		currentActivityData: {},
		currentAsset: {},
		currentTab: '',
	})

	function loadStore(storeSlug: string) {
		if (storeSlug === state.value.storefront?.slug) {
			return loadStoreExisting(storeSlug)
		} else {
			return loadStoreNew(storeSlug)
		}
	}

	function loadStoreNew(storeSlug: string) {
		resetStore()

		const promises = [
			api.loadStorefront(storeSlug).then((s: Storefront) => (state.value.storefront = s)),
			api.loadStorefrontMetadata(storeSlug).then((m) => (state.value.metadata = m)),
		]

		return Promise.all(promises).then(() => {
			return state.value.storefront
		})
	}

	function loadStoreExisting(storeSlug: string) {
		api.loadStorefrontMetadata(storeSlug).then((m) => (state.value.metadata = m))
	}

	function resetStore() {
		state.value.metadata = {}
		state.value.storefront = null
		state.value.assets = []
		state.value.activeFilters = {}
	}

	function clearFilters() {
		state.value.activeFilters = {}

		eventEmitter.emit('filtersUpdated', state.value.activeFilters)
	}

	function removeFilter(filter: string, value: string) {
		if (undefined === state.value.activeFilters[filter]) {
			return
		}

		state.value.activeFilters[filter].splice(state.value.activeFilters[filter].indexOf(value), 1)

		eventEmitter.emit('filtersUpdated', state.value.activeFilters)
	}

	function setFilters(filter: string, selected: string[]) {
		state.value.activeFilters[filter] = selected
		eventEmitter.emit('filtersUpdated', state.value.activeFilters)
	}

	function isFilterActive(filter: string) {
		return filter in state.value.activeFilters && state.value.activeFilters[filter].length
	}

	function toggleFilter(filter: string, value: string, enabled: boolean | null = null) {
		const currentIndex =
			state.value.activeFilters[filter] !== undefined ? state.value.activeFilters[filter].indexOf(value) : -1

		const filterState = enabled === null ? !(currentIndex >= 0) : enabled

		if (filterState && currentIndex === -1) {
			if (undefined == state.value.activeFilters[filter]) {
				state.value.activeFilters[filter] = []
			}

			state.value.activeFilters[filter].push(value)
		} else {
			state.value.activeFilters[filter].splice(state.value.activeFilters[filter].indexOf(value), 1)
		}

		eventEmitter.emit('filtersUpdated', state.value.activeFilters)
	}

	async function loadStoreAssets(
		slug: string,
		pgNumber: number = 0,
		perPage: number = 100,
		filters: AssetFilter,
	): Promise<StorefrontAssetResponse> {
		return await api.loadStorefrontAssets(slug, pgNumber, perPage, filters)
	}

	async function loadActivity(
		slug: string,
		page: number,
		filterParams: AssetFilter,
	): Promise<{ data: Object; meta: Object }> {
		return api.loadStorefrontActivity(slug, page, null, filterParams)
	}

	function on(event, handler) {
		return eventEmitter.on(event, handler)
	}

	function once(event, handler) {
		return eventEmitter.once(event, handler)
	}

	function off(event, handler) {
		return eventEmitter.off(event, handler)
	}

	async function getHeroLeaderboardData(season: string, sport: string) {
		return await api.getColorPopLeaderboardData(season, sport)
	}

	function hasFeature(feature: Flag, def: boolean = false): boolean {
		return flagStore.check(state.value.storefront?.config?.features ?? [], feature)
	}

	const numberOfActiveFilters = computed(() =>
		Object.keys(state.value.activeFilters).reduce((total, key) => {
			total += state.value.activeFilters[key].length
			return total
		}, 0),
	)

	async function getStorefrontOffers(): Promise<Offer[]> {
		return api.loadOffers(state.value.storefront.id)
	}

	async function createStorefrontOffer(offer: NewOffer): Promise<{ offer: Offer; success: boolean }> {
		return api.createOffer(state.value.storefront.id, offer)
	}

	async function cancelStorefrontOffer(offer: Offer): Promise<{ success: boolean; message: string }> {
		return api.cancelOffer(offer.storefront_id, offer.id)
	}

	async function acceptStorefrontOffer(offer: Offer): Promise<{ success: boolean; message: string }> {
		return api.acceptOffer(offer.storefront_id, offer)
	}

	return {
		state,
		loadStore,
		clearFilters,
		removeFilter,
		toggleFilter,
		setFilters,
		loadStoreAssets,
		isFilterActive,
		loadActivity,
		on,
		once,
		off,
		getHeroLeaderboardData,
		hasFeature,
		numberOfActiveFilters,
		getStorefrontOffers,
		createStorefrontOffer,
		cancelStorefrontOffer,
		acceptStorefrontOffer,
	}
})
