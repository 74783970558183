<template>
	<modal>
		<template v-if="!authStore.authenticated" #default>
			<ModalLogin @close="close" />
		</template>
		<template v-else #default>
			<div class="bg-white rounded-xl drop-shadow-md w-full max-h-[80vh] flex flex-col">
				<div v-if="!state.txComplete">
					<div class="p-6 border-b flex justify-between">
						<p class="text-2xl font-bold">Make an Offer</p>
						<button @click.stop="close" class="text-gray-500 absolute top-4 right-4 text-xl">
							<i class="fas fa-close"></i>
						</button>
					</div>

					<div class="p-6 pb-0">
						<div v-if="isOfferForAsset" class="p-4 border bg-slate-100 rounded-lg flex items-center">
							<div class="mt-1 mr-4 flex h-16 w-16 shrink-0 border rounded-lg">
								<img :src="$cdnify(props.asset.image, 128)" :alt="props.asset.name" class="mx-auto" />
							</div>
							<div>
								<p class="font-semibold">{{ props.asset.name }}</p>
								<p class="text-sm">{{ props.asset.storefront.name }}</p>
							</div>
						</div>
						<div v-else class="p-4 border bg-slate-100 rounded-lg flex items-center">
							<div class="mt-1 mr-4 flex h-16 w-16 shrink-0 border rounded-lg">
								<img
									:src="storeStore.state.storefront.pfp_image"
									:alt="storeStore.state.storefront.name"
									class="rounded-lg"
								/>
							</div>
							<div>
								<p class="font-semibold">{{ storeStore.state.storefront.name }}</p>
								<p class="text-sm">{{ storeStore.state.storefront.details.description }}</p>
							</div>
						</div>
					</div>

					<section class="p-6 grow flex flex-col bg-white">
						<div class="flex space-x-6 mb-4">
							<div class="w-1/2 relative">
								<label for="offerAmount" class="block text-md font-medium text-gray-700">
									Your Offer
								</label>
								<div class="mt-1 relative rounded-md shadow-sm">
									<input
										id="offerAmount"
										type="text"
										class="block w-full pr-10 sm:text-md border-gray-300 rounded-md disabled:bg-slate-50 disabled:opacity-50"
										placeholder="Amount"
										v-model="offerState.price"
										:disabled="state.txInProgress"
									/>
									<div class="absolute inset-y-0 right-1 flex items-center">
										<label for="price" class="sr-only">{{ $token }}</label>
										<span class="text-black sm:text-md p-2 font-bold bg-white">{{ $token }}</span>
									</div>
								</div>
								<p v-if="isNaN(offerState.price)" class="text-red-500 text-xs mt-1">
									Please enter a valid amount.
								</p>
							</div>
							<div class="w-1/2 relative">
								<label for="offerQty" class="block text-md font-medium text-gray-700"> Quantity </label>
								<div
									class="mt-1 relative rounded-md shadow-sm flex items-center text-md border-gray-800"
								>
									<button
										@click="subQuantity"
										class="absolute px-3 inset-y-0 left-1 bg-white text-gray-400 enabled:hover:text-gray-800 my-0.5 disabled:bg-slate-50 disabled:opacity-50"
										:disabled="offerState.quantity <= 0 || state.txInProgress"
									>
										<i class="fas fa-minus"></i>
									</button>
									<input
										id="offerQty"
										type="text"
										class="block w-full pl-10 pr-10 sm:text-md text-center border-gray-300 rounded-md disabled:bg-slate-50 disabled:opacity-50"
										placeholder="Quantity"
										v-model="offerState.quantity"
										:disabled="state.txInProgress"
									/>
									<button
										@click="addQuantity"
										class="absolute px-3 inset-y-0 right-1 bg-white text-gray-400 enabled:hover:text-gray-800 my-0.5 disabled:bg-slate-50 disabled:opacity-50"
										:disabled="state.txInProgress"
									>
										<i class="fas fa-plus"></i>
									</button>
								</div>
								<div class="flex justify-between">
									<p v-if="isNaN(offerState.quantity)" class="text-red-500 text-xs mt-1">
										Please enter a valid quantity.
									</p>
									<p v-if="isOfferForAsset" class="text-xs mt-1 ml-auto">
										Available: {{ props?.tokenCount }}
									</p>
								</div>
							</div>
						</div>

						<!-- Duration -->
						<div class="mb-4">
							<h2 class="block text-md font-medium text-gray-700">
								<label for="duration">Duration</label>
							</h2>
							<div class="mt-1">
								<div class="mt-1 md:grid md:grid-cols-2 gap-4 space-y-4 md:space-y-0">
									<Listbox as="div" v-model="state.duration" @update:modelValue="updateDuration">
										<div class="relative">
											<ListboxButton
												id="duration"
												class="relative w-full cursor-default px-4 py-3 pl-3 pr-10 text-left text-gray-900 rounded-md bg-white shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-sky-600 sm:text-sm sm:leading-6"
											>
												<span class="block min-w-[150px]">
													{{ state.duration?.value }}
												</span>
												<span
													class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
												>
													<ChevronUpDownIcon
														class="h-5 w-5 text-gray-400"
														aria-hidden="true"
													/>
												</span>
											</ListboxButton>
											<ListboxOptions
												class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white px-2 py-3 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
											>
												<ListboxOption
													as="template"
													v-for="duration in durations"
													:key="duration.value"
													:value="duration"
													v-slot="{ active, selected }"
												>
													<li
														:class="[
															active ? 'bg-sky-600 text-white' : 'text-gray-900',
															'relative cursor-default select-none py-2 pl-3 pr-9 rounded',
														]"
													>
														<span
															:class="[
																selected ? 'font-semibold' : 'font-normal',
																'block truncate',
															]"
														>
															{{ duration.value }}
														</span>
														<span
															v-if="selected"
															:class="[
																active ? 'text-white' : 'text-sky-600',
																'absolute inset-y-0 right-0 flex items-center pr-4',
															]"
														>
															<CheckIcon class="h-5 w-5" aria-hidden="true" />
														</span>
													</li>
												</ListboxOption>
											</ListboxOptions>
										</div>
									</Listbox>
									<div class="relative flex items-stretch">
										<vue-date-picker
											ref="datepicker"
											:model-value="state.expires_at"
											@update:model-value="handleDateChange"
											@update:model-timezone-value="(v) => (state.expires_at_utc = v)"
											emit-timezone="UTC"
											:min-date="new Date(new Date().valueOf() + 86400 * 1000)"
											:max-date="new Date(new Date().valueOf() + 86400 * 30 * 6 * 1000)"
											:start-date="new Date(new Date().valueOf() + 86400 * 1000)"
											:prevent-min-max-navigation="true"
											:time-picker-inline="true"
											:is-24="false"
											:minutes-increment="15"
											:no-hours-overlay="true"
											:no-minutes-overlay="true"
											:format="'MM/dd/yyyy @ h:mm aa'"
											:start-time="{
												hours: new Date().getHours(),
												minutes: Math.floor(new Date().getMinutes() / 15) * 15,
											}"
											:filters="{
												times: {
													minutes: Array.from(Array(60).keys()).filter(
														(i) => [0, 15, 30, 45].indexOf(i) == -1,
													),
													seconds: Array.from(Array(60).keys()),
												},
											}"
										/>
									</div>
								</div>
							</div>
							<span
								class="flex mt-4 text-red-500 text-sm italic font-medium"
								v-if="!!state.errors.duration"
								>{{ state.errors.duration }}</span
							>
						</div>

						<!-- Trait -->
						<div v-if="!isOfferForAsset" class="mt-4 grow flex flex-col relative overflow-visible border-t">
							<label
								@click="state.showTraits = !state.showTraits"
								class="text-md font-medium text-gray-700 p-4 px-0 pb-0 flex justify-between items-center cursor-pointer"
							>
								<div class="flex flex-col">
									<p>Trait</p>
									<p class="text-sm text-gray-400" :class="!state.showTraits ? '' : 'opacity-0'">
										Click to select a trait
									</p>
								</div>

								<div class="ml-auto">
									<div class="flex space-x-2">
										<div v-if="offerState.trait !== null" class="flex flex-col text-right">
											<p class="block font-bold">{{ offerState.trait.prop }}</p>
											<p class="block text-sm text-gray-400">{{ offerState.trait.val }}</p>
										</div>
										<div v-else class="flex flex-col text-right">
											<p class="block font-bold">No Trait</p>
											<p class="block text-sm text-gray-400">Any in collection</p>
										</div>
										<div
											@click.stop="selectTrait(null)"
											v-show="offerState.trait !== null"
											class="text-gray-400 hover:text-red-600"
										>
											<i class="fa fa-trash"></i>
										</div>
									</div>
								</div>
							</label>
							<div
								v-show="state.showTraits"
								class="mt-1 relative rounded-md shadow-sm flex flex-col grow"
								ref="traitFilter"
							>
								<div class="grow relative">
									<div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
										<svg
											class="w-4 h-4 text-gray-500 dark:text-gray-400"
											aria-hidden="true"
											xmlns="http://www.w3.org/2000/svg"
											fill="none"
											viewBox="0 0 20 20"
										>
											<path
												stroke="currentColor"
												stroke-linecap="round"
												stroke-linejoin="round"
												stroke-width="2"
												d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
											/>
										</svg>
									</div>
									<input
										type="search"
										class="block w-full sm:text-md border-gray-300 rounded-md pl-10 pr-4"
										placeholder="Search for traits"
										@input="handleTraitSearchInput"
										:value="
											offerState.trait !== null
												? offerState.trait.prop + ': ' + offerState.trait.val
												: state.searchTraits
										"
										@focus="state.showDropdown = true"
									/>
								</div>
								<div
									v-if="state.showDropdown"
									class="z-50 mt-2 w-full bg-white border border-gray-300 rounded-md shadow-lg h-64 absolute top-10 overflow-auto"
								>
									<div v-for="trait of traits" class="w-full relative">
										<div class="font-bold p-2 bg-slate-200 sticky top-0">
											{{ trait[0] }}
										</div>
										<button
											v-for="t of trait[1]"
											class="block p-2 hover:bg-slate-50 w-full text-left"
											@click="selectTrait(t)"
											v-show="
												t.prop.toLowerCase().includes(state.searchTraits.toLowerCase()) ||
												t.val.toLowerCase().includes(state.searchTraits.toLowerCase())
											"
										>
											{{ t.prop }}: {{ t.val }}
										</button>
									</div>
								</div>
							</div>
						</div>
						<div class="flex flex-col mt-4 border-t pt-4">
							<p class="ml-auto font-bold text-xl mb-1">Offer Summary</p>
							<div class="rounded-lg flex flex-col items-end sm:flex-row sm:justify-end sm:items-center">
								<p
									v-if="offerState.trait"
									class="bg-gray-200 rounded py-1 px-2 text-xs inline-block my-2"
								>
									<strong>{{ offerState.trait.prop + ':' }}</strong>
									{{ offerState.trait.val }}
								</p>
								<div class="ml-4 shrink-0">
									<p class="text-lg">
										{{
											isOfferValid
												? $format(
														offerState.price *
															(offerState.quantity >= 0 ? offerState.quantity : 0),
												  )
												: '--'
										}}
										{{ $token }}
									</p>
								</div>
							</div>
						</div>
						<div v-if="state.txError" class="mt-6 bg-red-100 rounded-xl px-4 py-4 text-red-600 space-y-2">
							<p class="font text-base">
								<span
									class="inline-flex justify-center items-center px-2 bg-red-600 text-xs text-white aspect-square rounded-full leading-none"
								>
									<i class="fa fa-exclamation"></i>
								</span>
								Transaction Error
							</p>
							<p class="text-base">
								{{ state.txError }}
							</p>
						</div>
					</section>
				</div>

				<div v-else>
					<div class="p-6 border-b">
						<p class="text-2xl font-bold">Offer Made</p>
					</div>
					<section class="p-6 flex flex-col items-center">
						<p class="text-xl font-semibold">Your offer has been made!</p>
						<p class="text-gray-500 mt-2">You will be notified when a seller has accepted your offer.</p>
					</section>
				</div>

				<div class="bg-gray-100 text-right space-x-6 pt-6 pb-4 px-6 mt-auto rounded-b-xl">
					<button v-if="!state.txComplete" class="text-gray-500" @click.stop="close">Cancel</button>

					<button
						v-if="!state.txComplete"
						@click.stop="makeOffer"
						class="btn-primary-lg text-white space-x-1"
						:disabled="!isOfferValid || state.txInProgress"
					>
						<span v-if="!state.txInProgress">
							<i class="fa-solid fa-paper-plane" />
							Make Offer
						</span>
						<span v-else>
							<i class="fa-sharp fa-solid fa-spinner-third fa-spin"></i>
							Sending Offer
						</span>
					</button>

					<button v-if="state.txComplete" @click="complete" class="btn-primary-lg">Done</button>
				</div>
			</div>
		</template>
	</modal>
</template>
<script lang="ts" setup>
import { computed, ref, ComputedRef, onMounted, reactive } from 'vue'
import { Asset } from '@/types/Asset'
import { useAuthStore } from '@/stores/AuthStore'
import { useStorefrontStore } from '@/stores/StorefrontStore'
import LoginButton from '@/components/LoginButton.vue'
import { Trait } from '@/types/Asset'
import { onClickOutside } from '@vueuse/core'
import { NewOffer, Offer, StorefrontMetadata } from '@/types/Storefront'
import { AxiosError } from 'axios'
import ModalLogin from '@/components/ModalLogin.vue'

import Toggle from '@vueform/toggle'
import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from '@headlessui/vue'
import { DateTime, Duration } from 'ts-luxon'
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import { ChevronUpDownIcon, CheckCircleIcon, ArrowLeftIcon, CheckIcon } from '@heroicons/vue/20/solid'

interface OfferDuration {
	name: string
	value: string
	date: DateTime
	formattedDate: string
}

const durations: OfferDuration[] = [
	{
		name: '1h',
		value: '1 hour',
		date: DateTime.now()
			.plus(Duration.fromObject({ hours: 1 }))
			.set({ minutes: Math.floor(new Date().getMinutes() / 15) * 15 }),
		formattedDate: DateTime.now()
			.setZone('UTC')
			.plus(Duration.fromObject({ hours: 1 }))
			.set({ minutes: Math.floor(new Date().getMinutes() / 15) * 15 })
			.toSQL({ includeOffset: true }),
	},
	{
		name: '6h',
		value: '6 hours',
		date: DateTime.now()
			.plus(Duration.fromObject({ hours: 6 }))
			.set({ minutes: Math.floor(new Date().getMinutes() / 15) * 15 }),
		formattedDate: DateTime.now()
			.setZone('UTC')
			.plus(Duration.fromObject({ hours: 6 }))
			.set({ minutes: Math.floor(new Date().getMinutes() / 15) * 15 })
			.toSQL({ includeOffset: true }),
	},
	{
		name: '1d',
		value: '1 day',
		date: DateTime.now()
			.plus(Duration.fromObject({ days: 1 }))
			.set({ minutes: Math.floor(new Date().getMinutes() / 15) * 15 }),
		formattedDate: DateTime.now()
			.setZone('UTC')
			.plus(Duration.fromObject({ days: 1 }))
			.set({ minutes: Math.floor(new Date().getMinutes() / 15) * 15 })
			.toSQL({ includeOffset: true }),
	},
	{
		name: '3d',
		value: '3 days',
		date: DateTime.now()
			.plus(Duration.fromObject({ days: 3 }))
			.set({ minutes: Math.floor(new Date().getMinutes() / 15) * 15 }),
		formattedDate: DateTime.now()
			.setZone('UTC')
			.plus(Duration.fromObject({ days: 3 }))
			.set({ minutes: Math.floor(new Date().getMinutes() / 15) * 15 })
			.toSQL({ includeOffset: true }),
	},
	{
		name: '7d',
		value: '1 week',
		date: DateTime.now()
			.plus(Duration.fromObject({ days: 7 }))
			.set({ minutes: Math.floor(new Date().getMinutes() / 15) * 15 }),
		formattedDate: DateTime.now()
			.setZone('UTC')
			.plus(Duration.fromObject({ days: 7 }))
			.set({ minutes: Math.floor(new Date().getMinutes() / 15) * 15 })
			.toSQL({ includeOffset: true }),
	},
	{
		name: '1m',
		value: '1 month',
		date: DateTime.now()
			.plus(Duration.fromObject({ months: 1 }))
			.set({ minutes: Math.floor(new Date().getMinutes() / 15) * 15 }),
		formattedDate: DateTime.now()
			.setZone('UTC')
			.plus(Duration.fromObject({ months: 1 }))
			.set({ minutes: Math.floor(new Date().getMinutes() / 15) * 15 })
			.toSQL({ includeOffset: true }),
	},
	{
		name: '3m',
		value: '3 months',
		date: DateTime.now()
			.plus(Duration.fromObject({ months: 3 }))
			.set({ minutes: Math.floor(new Date().getMinutes() / 15) * 15 }),
		formattedDate: DateTime.now()
			.setZone('UTC')
			.plus(Duration.fromObject({ months: 3 }))
			.set({ minutes: Math.floor(new Date().getMinutes() / 15) * 15 })
			.toSQL({ includeOffset: true }),
	},
	{
		name: '6m',
		value: '6 months',
		date: DateTime.now()
			.plus(Duration.fromObject({ months: 6 }))
			.set({ minutes: Math.floor(new Date().getMinutes() / 15) * 15 }),
		formattedDate: DateTime.now()
			.setZone('UTC')
			.plus(Duration.fromObject({ months: 6 }))
			.set({ minutes: Math.floor(new Date().getMinutes() / 15) * 15 })
			.toSQL({ includeOffset: false }),
	},
]

const datepicker = ref(null)
const traitFilter = ref(null)
onClickOutside(traitFilter, closeTraitFilter)

const authStore = useAuthStore()
const storeStore = useStorefrontStore()

const props = defineProps<{
	asset?: Asset
	tokenCount?: number
}>()

const emit = defineEmits<{
	(event: 'close'): void
	(event: 'complete', offer: Offer): void
}>()

function close() {
	emit('close')
}

const state = reactive<{
	searchTraits: string
	traits: StorefrontMetadata
	showTraits: boolean
	showDropdown: boolean
	txInProgress: boolean
	txComplete: boolean
	txError: null | any
	tx: null | Offer
	errors: any
	duration: OfferDuration
	expires_at: DateTime
	expires_at_utc: DateTime
}>({
	searchTraits: '',
	traits: storeStore.state.metadata,
	showTraits: false,
	showDropdown: false,
	txInProgress: false,
	txComplete: false,
	txError: null,
	tx: null,
	errors: {} as any,
	duration: durations[4],
	expires_at: durations[4].date,
	expires_at_utc: durations[4].date.setZone('UTC'),
})

const offerState = reactive<{
	price: string
	quantity: number
	trait: null | Trait
	offer_for_asset_id: null | string
}>({
	price: '',
	quantity: 1,
	trait: null,
	offer_for_asset_id: null,
})

onMounted(() => {
	if (props.asset) {
		offerState.offer_for_asset_id = props.asset.asset_identifier
	} else {
		console.log('no asset - offer by traits instead')
	}
})

const isOfferForAsset = computed(() => {
	return offerState.offer_for_asset_id !== null && props.asset !== undefined
})

const traits: ComputedRef<[key: string, Trait[]][]> = computed(() => {
	const allTraits = Object.entries(storeStore.state.metadata)
	return allTraits
})

function addQuantity() {
	if (isNaN(offerState.quantity)) {
		offerState.quantity = 1
	}
	offerState.quantity = offerState.quantity + 1
}

function subQuantity() {
	if (isNaN(offerState.quantity)) {
		offerState.quantity = 0
	} else if (offerState.quantity <= 0) {
		offerState.quantity = 0
	} else {
		offerState.quantity = offerState.quantity - 1
	}
}

function selectTrait(trait: Trait) {
	offerState.trait = trait
	state.showDropdown = false
	state.showTraits = false
}

function closeTraitFilter() {
	state.showDropdown = false
}

function handleTraitSearchInput(e: InputEvent) {
	const target = e.target as HTMLInputElement
	state.searchTraits = target.value
	offerState.trait = null
}

const isOfferValid = computed(() => {
	return Number(offerState.price) > 0 && Number(offerState.quantity) > 0
})

async function makeOffer() {
	let expiresAt = state.expires_at.setZone('UTC')
	const theOffer: NewOffer = {
		storefront_id: storeStore.state.storefront.id,
		offer_bpx_per_token: humanReadableToBpx(offerState.price).toString(),
		total_offer_token_count: Number(offerState.quantity),
		expires_at: expiresAt.toSQL({ includeOffset: true }),
	}

	theOffer.offer_for_trait_prop = offerState.trait ? offerState.trait.prop : null
	theOffer.offer_for_trait_val = offerState.trait ? offerState.trait.val : null
	theOffer.offer_for_asset = offerState.offer_for_asset_id

	state.txInProgress = true

	try {
		const res = await storeStore.createStorefrontOffer(theOffer)
		state.txInProgress = false

		if (res?.success) {
			console.log('cool', res)
			state.tx = res.offer
		} else {
			console.log('not cool', res)
			state.txError = res
		}
		state.txComplete = true
	} catch (e) {
		const err = e as AxiosError<Error>
		state.txError = err.response?.data.message
		state.txInProgress = false
	}
}

function complete() {
	emit('complete', state.tx)
}

function humanReadableToBpx(humanValue: string): bigint {
	const parts = humanValue.split('.')
	const integralPart = BigInt(parts[0]) * BigInt(1e9)
	if (parts.length === 1) {
		return integralPart
	}
	// 9 decimal precision, pad with zeros to 9 digits (1e9)
	const truncated = parts[1].slice(0, 9).padEnd(9, '0')
	const decimalPart = BigInt(truncated)
	return integralPart + decimalPart
}

// Convert Bipixies (no decimals) to BPX (9 decimal places)
function toFloat(value: bigint): number {
	const integralPart = value / BigInt(1e9)
	const decimalPart = Number(value % BigInt(1e9)) / 1e9
	return Number(integralPart) + decimalPart
}

function updateDuration(value) {
	state.expires_at = DateTime.fromISO(value.date)
	datepicker.value.parseModel(value.date)
}

function handleDateChange(dateFromVueDatePicker) {
	state.expires_at = DateTime.fromJSDate(dateFromVueDatePicker)
}
</script>
