<style scoped>
.bar {
	background: linear-gradient(
		to right,
		#e5e7eb 0% calc(var(--claimed) * 1%),
		#60a5fa calc(var(--claimed) * 1%) calc((var(--claimed) + var(--available)) * 1%),
		#f9fafb calc((var(--claimed) + var(--available)) * 1%) 100%
	);
}

.pie {
	aspect-ratio: 1;
	display: inline-grid;
	place-content: center;
	width: 80%;
	height: 80%;
	border-radius: 100%;
	position: relative;
	background: conic-gradient(
		/* slate-400 in twailwind */ #94a3b8 0% calc(var(--claimed) * 1%),
		/* sky-600 in twailwind */ #0284c7 calc(var(--claimed) * 1.005%)
			calc((var(--claimed) * 1%) + (var(--available) * 1%)),
		/* slate-200 in twailwind */ #e2e8f0 calc((var(--claimed) + var(--available)) * 1.005%) 100%
	);
}

.pie::after {
	position: absolute;
	content: '';
	width: 60%;
	height: 60%;
	left: 40%;
	top: 40%;
	margin-left: -20%;
	margin-top: -20%;
	border-radius: 100%;
	background: white;
}
</style>
<template>
	<div class="bg-white rounded-xl shadow px-6 py-6 flex flex-col justify-start">
		<slot name="title" />
		<div class="grid grid-cols-2 gap-2">
			<div class="order-0">
				<p class="text-gray-500 tracking-tighter text-lg">Miles Daily</p>
				<p class="text-4xl tracking-tighter">
					{{ $format(currencyToDecimal(totals.daily_emission, { showDecimals: false })) }}
				</p>
			</div>
			<div class="order-2">
				<p class="text-gray-500 tracking-tighter text-lg">Claimable Miles</p>
				<p class="text-4xl tracking-tighter">
					{{ $format(currencyToDecimal(totals.current_value, { showDecimals: false })) }}
				</p>
			</div>
			<div class="order-4">
				<p class="text-gray-500 tracking-tighter text-lg">Claimable Drips</p>
				<p class="text-4xl tracking-tighter">
					{{ $format(totals.claimable_count) }}
				</p>
			</div>

			<div class="order-1">
				<p class="text-gray-500 tracking-tighter text-lg">Remaining Drip</p>
				<p class="text-4xl tracking-tighter">
					{{ $format(currencyToDecimal(totals.remaining_emission, { showDecimals: false })) }}
				</p>
			</div>

			<div class="row-span-3 order-3">
				<div class="mt-4">
					<div class="text-center relative">
						<div
							class="pie relative group max-w-44"
							:style="`
								--claimed: ${percentage(totals.withdrawn_value, totals.total_emission)};
								--available: ${percentage(totals.current_value, totals.total_emission)};
							`"
						>
							<div
								class="hidden group-hover:block z-10 absolute min-w-max right-1/4 top-1/2 bg-white shadow-lg shadow-gray-400 rounded-lg p-3 text-xs text-left text-gray-500 space-y-1"
							>
								<div class="flex justify-start items-center space-x-1">
									<p class="inline-block w-3 rounded aspect-square bg-sky-600 shadow"></p>
									<p class="font-medium">Claimable Today</p>
									<p class="flex-grow text-right">
										{{ $format(currencyToDecimal(totals.current_value, { showDecimals: false })) }}
										{{ $token }}
									</p>
								</div>
								<div class="flex justify-start items-center space-x-1">
									<p class="inline-block w-3 rounded aspect-square bg-slate-400 shadow"></p>
									<p class="font-medium">Total Claimed:</p>
									<p class="flex-grow text-right">
										{{
											$format(currencyToDecimal(totals.withdrawn_value, { showDecimals: false }))
										}}
										{{ $token }}
									</p>
								</div>
								<div class="flex justify-start items-center space-x-1">
									<p
										class="inline-block w-3 rounded aspect-square bg-slate-200 shadow shadow-gray-100"
									></p>
									<p class="font-medium">Amount Remaining:</p>
									<p class="flex-grow text-right">
										{{
											$format(
												currencyToDecimal(totals.remaining_emission, { showDecimals: false }),
											)
										}}
										{{ $token }}
									</p>
								</div>
								<div class="flex justify-start items-center space-x-1">
									<p class="inline-block w-3 rounded aspect-square bg-transparent"></p>
									<p class="font-medium">Total Amount:</p>
									<p class="flex-grow text-right">
										{{ $format(currencyToDecimal(totals.total_emission, { showDecimals: false })) }}
										{{ $token }}
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<slot name="footer">
			<div class="mt-6">
				<button
					class="btn-light-lg block w-full"
					:disabled="totals.claimable_count == 0"
					@click="$emit('claim')"
				>
					Claim All
				</button>
				<p v-if="totals.claimable_count > 0" class="text-xs text-gray-500 pt-2 italic px-4 text-center">
					Claim all available miles from {{ $format(totals.claimable_count) }} active, non-listed,
					<pluralize :amount="totals.claimable_count" singular="drip" plural="drips" />.
				</p>
			</div>
		</slot>
	</div>
</template>
<script lang="ts" setup>
import { AccountDripIndexResponse } from '@/types/Drip'
import { currencyToDecimal } from '@/util/currencyFormat'
const props = defineProps<{
	totals: AccountDripIndexResponse
}>()

const emits = defineEmits<{
	claim: void
}>()

function percentage(num: string, den: string): number {
	const n = Number(BigInt(num) / BigInt(1e9))
	const d = Number(BigInt(den) / BigInt(1e9))

	if (d === 0) {
		return 0
	}

	return Math.floor((n / d) * 1000000) / 10000
}
</script>
