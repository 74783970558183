<template>
	<div class="p-4 pb-6">
		<slot name="default-label" :id="id">
			<label class="block text-base text-gray-900" :for="id">
				<slot name="default">Amount</slot>
			</label>
		</slot>
		<div
			class="transition-colors relative flex rounded-lg border border-gray-400 pr-3 pl-0.5 py-0.5 items-center"
			:class="[
				props.readonly || $attrs.disabled
					? 'bg-gray-200/50 text-gray-400 select-none'
					: !valid
					? 'border border-red-500'
					: 'border border-gray-300',
			]"
		>
			<input
				placeholder="0"
				class="flex-grow bg-transparent outline-none ring-0 border-0 focus:ring-0 disabled:border-slate-400 disabled:cursor-not-allowed disabled:select-none"
				:class="[props.readonly || $attrs.disabled ? 'select-none' : '']"
				type="tel"
				v-model="value"
				@input="$emit('update:modelValue', $event.target.value)"
				:id="id"
				:disabled="props.readonly || $attrs.disabled"
				:readonly="props.readonly || $attrs.disabled"
			/>
			<span class="text-base" :class="[props.readonly || $attrs.disabled ? 'text-gray-400' : 'text-gray-500']">{{
				props.currency
			}}</span>
			<div v-if="props.readonly || $attrs.disabled" class="inset-0 absolute"></div>
		</div>
		<div class="mt-2 flex items-start justify-between space-x-2 text-gray-500">
			<div class="gap-y-0.5">
				<template
					v-if="
						(props.balance !== null && props.available === null) ||
						(props.available !== null && props.available != props.balance)
					"
				>
					<p>
						<span>Balance:</span>
						<span>&nbsp;{{ $format(toFloat(props.balance)) }}</span>
						<span>&nbsp;{{ props.currency }}</span>
					</p>
				</template>
				<template v-if="props.available !== null">
					<p>
						<span>Available:</span>
						<span>&nbsp;{{ $format(toFloat(props.available)) }}</span>
						<span>&nbsp;{{ props.currency }}</span>
					</p>
				</template>
			</div>
			<div v-if="props.available !== null">
				<button
					class="border border-gray-200 rounded-full text-blue-400 bg-white px-4 py-0.5 enabled:hover:bg-blue-400 enabled:hover:text-white enabled:hover:border-blue-400 disabled:bg-gray-200 disabled:text-gray-400 disabled:border-gray-200"
					:disabled="props.readonly || $attrs.disabled"
					@click="setMaxValue(props.available)"
				>
					Max
				</button>
			</div>
		</div>
	</div>
</template>
<script lang="ts" setup>
import { computed, getCurrentInstance, ref, watch } from 'vue'
import Currency from '@/types/Currency'

const props = withDefaults(
	defineProps<{
		modelValue?: number | string | null
		balance?: number | string | null
		available?: number | string | null
		readonly?: boolean
		currency: string
		valid?: boolean
	}>(),
	{
		value: '0',
		balance: null,
		available: null,
		readonly: false,
		valid: true,
	},
)

const $emit = defineEmits(['update:modelValue'])

const value = ref<string | Number>(props.value ?? '')

const random = ref(Math.random())
const id = computed(() => {
	return `amount-${props.currency}-${random.value}`
})
const valid = ref(props.valid)

watch(
	() => props.modelValue,
	(newValue) => {
		value.value = newValue !== null ? String(newValue) : ''
	},
)

watch(
	() => props.valid,
	(nv) => {
		valid.value = nv
	},
)

function toFloat(value: any): number {
	return new Currency(value).toDecimal()
}

function setMaxValue(max: number | string) {
	value.value = toFloat(max)
	$emit('update:modelValue', value.value)
}
</script>
