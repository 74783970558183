import { defineStore } from 'pinia'
import api from '@/util/api'
import { computed, ref } from 'vue'
import EventBus from '@/util/eventBus'
import { identifyContact } from '@/util/omnisend'

const SESSION_TIMEOUT = 120 * 60
const lsKey = 'bpxid-at'
let AUTH: AuthToken
let eventEmitter: EventBus

export class AuthToken {
	public data: AuthTokenData

	constructor(token: AuthTokenData) {
		this.data = token
	}

	persist(): void {
		window.localStorage.setItem(lsKey, this.toString())
	}

	valid(): boolean {
		const user = api.whoami()

		if (user) {
			return true
		}

		return false
	}

	toString(): string {
		return JSON.stringify(this.data)
	}

	base64_encode(): string {
		return window.btoa(this.toString())
	}

	static clear(wallet?: string): void {
		if (wallet) {
			window.localStorage.removeItem(wallet)
		}

		window.localStorage.removeItem(lsKey)
	}

	static fromString(token: string): AuthToken {
		return new AuthToken(JSON.parse(token))
	}

	static load(): AuthToken | null {
		const t = window.localStorage.getItem(lsKey)

		if (t) {
			return AuthToken.fromString(t)
		}

		return null
	}
}

interface AuthTokenData {
	accessToken: string
}

interface UserType {
	id: string
	name: string
	username: string
	email: string
	email_verified_at: Date | null
	metadata: UserMetadata | null
	accounts: null | string[]
	wallets: null | string[]
	flags: UserFlags | null
	favorites: {
		assets: string[]
		storefronts: string[]
	}
}

interface UserMetadata {
	accounts: string[]
	created_at: string
	deleted_at: string
	dob: string
	email: string
	email_verified_at: string
	first_name: string
	full_name: string
	id: string
	last_name: string
	middle_name: string
	name_prefix: string
	name_suffix: string
	sort_name: string
	updated_at: string
	username: string
	wallets: string[]
}

interface UserFlags {
	identity_verified: boolean
	require_identity_verification: boolean
	can_withdraw: boolean
	market: string[]
}

class User {
	user: UserType

	constructor(usr: UserType) {
		this.user = usr as UserType
	}

	get id(): string {
		return this.user.id
	}

	get name(): string {
		return this.user.name
	}

	get email(): string {
		return this.user.email
	}

	get verified(): boolean {
		return !(this.user.email_verified_at == null)
	}
}

export const useAuthStore = defineStore('auth', () => {
	eventEmitter = new EventBus()
	const user = ref<UserType | null>(null)
	const token = ref<AuthToken | null>(null)

	function init() {
		checkLogin()

		return {
			// state
			user,
			token,

			// actions
			getLoginUrl,
			redirectToLogin,
			logout,
			authenticate,
			login,
			checkLogin,
			getToken,

			/* event methods */
			onEvent: (name, fn) => {
				return eventEmitter.on(name, fn)
			},

			offEvent: (name, fn) => {
				return eventEmitter.off(name, fn)
			},

			onOneEvent: (name, fn) => {
				return eventEmitter.once(name, fn)
			},

			// getters
			authenticated,
			accountID,
		}
	}

	function doLogin(newUser: UserType) {
		user.value = newUser
		eventEmitter.emit('login', user)
	}

	function clearAuthState() {
		user.value = null
		eventEmitter.emit('logout')
	}

	async function getLoginUrl(redirectTo: string): Promise<string> {
		return await api.getLoginUrl(redirectTo)
	}

	async function authenticate(code: string, state: string): Promise<string> {
		const authResponse = await api.authenticate(code, state)

		if (authResponse.status != 'ok') {
			// couldnt authenticate the user, wat happened??
			return
		}

		doLogin(authResponse.user)
		return authResponse.redirect_to
	}

	async function checkLogin(): Promise<null | UserType> {
		const response = await api.whoami()

		if (!response) {
			clearAuthState()
			return null
		}

		return response
	}

	async function login(): Promise<boolean> {
		const response = await checkLogin()

		if (response !== null) {
			doLogin(response)
			identifyContact(response.email)
			return true
		}

		return false
	}

	async function logout() {
		window.top.location = api.getLogoutUrl()
	}

	async function redirectToLogin(nextPath: string) {
		window.top.location = api.getLoginUrl(nextPath)
	}

	const authenticated = computed(() => {
		return !!user.value
	})

	const accountID = computed(() => {
		if (!authenticated.value) {
			return null
		}

		if (user.value.metadata?.accounts !== undefined) {
			return user.value.metadata.accounts[0]
		}

		if (user.value.accounts !== undefined) {
			return user.value.accounts[0]
		}
	})

	function getToken(): AuthToken | null {
		if (login()) {
			return token.value
		}

		return null
	}

	return init()
})
