<template>
	<div class="p-6">
		<feedback-modal @close="clearFeedback" v-if="null !== state.feedback">
			<template #title>
				<span class="text-slate-900 font-poppins">
					<span v-if="state.feedback.success">
						<span
							class="inline-flex justify-center items-center text-green-600 bg-green-100 aspect-square px-3 rounded-full"
							><i class="fa-solid fa-check"></i
						></span>
						<span class="ml-3"> Congratulations! </span>
					</span>
					<span v-else>
						<span
							class="inline-flex justify-center items-center text-red-600 bg-red-100 aspect-square px-3 rounded-full"
							><i class="fa-regular fa-circle-exclamation"></i
						></span>
						<span class="ml-3"> Something went wrong! </span>
					</span>
				</span>
			</template>
			<template #body>
				<div class="text-xl my-4">
					<p>{{ state.feedback.message }}</p>
				</div>
			</template>
			<template #buttons="{ close }">
				<button class="btn-primary-lg mt-2" @click.stop="close">Dismiss</button>
			</template>
		</feedback-modal>

		<div class="max-w-screen-lg mx-auto">
			<h1 class="text-2xl font-semibold border-b-2 border-gray-300 pb-1">Add Blokpax Miles</h1>
		</div>

		<section class="max-w-screen-lg mx-auto pt-6 md:grid md:grid-cols-[3fr,2fr] md:gap-6">
			<div>
				<img src="/image/blokpax-miles-card.webp" class="w-full rounded-lg" />

				<div class="hidden md:block">
					<h2 class="text-3xl font-bold tracking-tight mt-3">
						Get Access to Amazing Benefits and Rewards with Blokpax Miles!
					</h2>
					<p class="mt-2">
						Use Blokpax Miles throughout the Blokpax Marketplace to score exclusive rewards and add real,
						physical, graded cards to your collection. Blokpax Miles can be used to purchase cards, access
						exclusive content, and more!
					</p>
				</div>
			</div>

			<div v-if="options.length > 0" class="">
				<h1 class="text-lg my-2 font-medium text-gray-800">Select a bundle</h1>
				<div class="space-y-2">
					<article
						v-for="(opt, i) in options"
						:key="i"
						class="flex justify-start items-stretch bg-gray-50 cursor-pointer hover:bg-gray-100"
						@click="selectedPrice = opt.price"
					>
						<div
							class="flex-grow-0 flex-shrink-0 border-t border-l border-b w-3 rounded-l-lg"
							:class="[
								opt.price == selectedPrice
									? 'bg-sky-600 border-sky-600'
									: 'bg-transparent border-gray-200',
							]"
						></div>
						<div
							class="relative flex-grow flex-shrink-0 p-3 border-t border-b border-r border-gray-200 rounded-r-lg"
							:class="[
								opt.price == selectedPrice
									? 'bg-sky-300/20 border-sky-600'
									: 'bg-transparent border-gray-200',
							]"
						>
							<p class="text-lg font-medium tracking-tighter">
								{{ $format(opt.amount) }}
								<span v-if="opt.bonus > 0">(+{{ $format(opt.bonus) }} Bonus)</span>
								{{ $token }}
							</p>
							<p class="text-2xl font-bold tracking-tighter mt-1">
								<span>${{ $format(opt.price) }}</span>
								<span class="font-normal text-sm text-gray-500 tracking-tight">
									for {{ $format(opt.amount + opt.bonus) }} total {{ $token }}
								</span>
							</p>
							<div
								v-if="opt.mostPopular || opt.bestValue"
								class="z-10 absolute shadow-md text-sm lg:text-base -top-2 md:top-0 -right-4 bg-red-600 text-white rounded-lg py-1 px-3 lg:px-4 rotate-6"
							>
								<span v-if="opt.mostPopular">Most Popular!</span>
								<span v-if="opt.bestValue">Best Value!</span>
							</div>
						</div>
					</article>
				</div>

				<button
					@click="buy"
					class="w-full shadow-md bg-sky-600 enabled:hover:bg-sky-800 disabled:bg-gray-400 disabled:text-gray-200 text-white py-3 rounded-lg text-2xl font-bold tracking-tight mt-4"
				>
					Buy Now
				</button>
			</div>
			<div v-else>
				<p class="text-gray-500">Loading packages...</p>
			</div>

			<div class="md:hidden mt-6">
				<h2 class="text-3xl font-bold tracking-tight mt-3">
					Get Access to Amazing Benefits and Rewards with Blokpax Miles!
				</h2>
				<p class="mt-2">
					Use Blokpax Miles throughout the Blokpax Marketplace to score exclusive rewards and add real,
					physical, graded cards to your collection. Blokpax Miles can be used to purchase cards, access
					exclusive content, and more!
				</p>
			</div>
		</section>
	</div>
</template>
<script lang="ts" setup>
import { useMarketStore } from '@/stores/MarketStore'
import { reactive, defineComponent, onMounted, PropType, ref } from 'vue'
import FeedbackModal from '@/components/modals/FeedbackModal.vue'
import { useRoute, useRouter } from 'vue-router'

const marketStore = useMarketStore()
const route = useRoute()
const router = useRouter()

const state = reactive({
	feedback: null as null | { success: boolean; message: string },
})

const options = ref<
	Array<{
		price: number
		amount: number
		bonus: number | null
		mostPopular: boolean
		bestValue: boolean
	}>
>([])

const selectedPrice = ref<number>(249)

onMounted(async () => {
	if (route.query.success !== undefined) {
		state.feedback = {
			success: true,
			message: String(route.query.success),
		}
	} else if (route.query.error !== undefined) {
		state.feedback = {
			success: false,
			message: String(route.query.error),
		}
	}

	options.value = await marketStore.getMilesPackages()
})

function clearFeedback() {
	router.replace({
		path: route.fullPath,
	})

	state.feedback = null
}

async function buy() {
	const amount = selectedPrice.value
	const response = await marketStore.initiateStoreCreditPurchase(amount)

	if (response.success) {
		window.top.location = response.redirect
		return
	}

	alert(response.message)
}
</script>
