import BidTooLow from '@/components/Errors/BidTooLow.vue'
import AuctionClosed from '@/components/Errors/AuctionClosed.vue'
import NotHighBidderVue from '@/components/Errors/NotHighBidder.vue'
import UnexpectedError from '@/components/Errors/UnexpectedError.vue'

export const codes = {
	NOT_HIGH_BIDDER: 9001,
}

export default {
	HttpError(reason: string, response: any) {
		let err = new Error(reason)
		err.response = response

		return err
	},
}

export function wasDeclined(err: Error) {
	return err.code && err.code === 4001
}

export function wasNotDeclined(err: Error) {
	return !wasDeclined(err)
}

export function isCustomError(err: Error) {
	return err.message.includes(`"code": -32603`) || Object.values(codes).indexOf(err.code) !== -1
}

export function buildErrorComponent(err: Error) {
	if (err.message.includes('BidTooLow')) {
		return BidTooLow
	}

	if (err.message.includes('AuctionClosed')) {
		return AuctionClosed
	}

	switch (err.code) {
		case codes.NOT_HIGH_BIDDER:
			return NotHighBidderVue
	}

	return UnexpectedError
}

export function make(code: number, message: string | undefined): Error {
	const err = new Error(message)
	err.code = code

	return err
}
