<template>
	<account-login v-if="!authStore.authenticated" />
	<section v-else class="bpmp-layout">
		<RouterView />
	</section>
</template>
<script lang="ts" setup>
import { useAuthStore } from '@/stores/AuthStore'
import AccountLogin from '@/pages/Account/AccountLogin.vue'

const authStore = useAuthStore()
</script>
