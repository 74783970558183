import { defineStore } from 'pinia'
import metamask from '@/util/metamask'
import IERC20 from '@/abi/IERC20.json'
import Currency from '@/types/Currency'
import { AUCTION_ADDRESS } from '@/util/auction'
import sleep from '@/util/sleep'
const ERC20TransferTopic = '0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef'
import Web3 from 'web3/dist/web3.min.js'
import { AlchemyApi } from '@/util/AlchemyApi'

let walletMonitor = null

interface Subscription {
	chain: number
	currency: string
	interval: number | null
}

export const BPX_ADDRESS = import.meta.env.VITE_BPX_ADDRESS

let theContract
const w3 = new Web3(import.meta.env.VITE_RO_PROVIDER)

export const useWalletStore = defineStore('wallet', {
	state: () => ({
		initialized: false,
		subscription: {} as Subscription,
		currentChain: null as string | number | null,
		wallet: null as string | null,
		balance: 0 as number,

		active: false,
	}),

	actions: {
		init(force: boolean | undefined) {
			if (!this.initialized || force) {
				metamask.on('connected', async () => {
					this.updateChain(await metamask.getCurrentChain())
					this.wallet = metamask.state.wallet
					this.monitor()
					this.updateBalance()
				})
				metamask.on('accountChanged', (wallet) => {
					this.wallet = wallet
					this.monitor()
					this.updateBalance()
				})

				metamask.on('chainChanged', (chain) => {
					this.updateChain(chain)
					this.monitor()
					this.updateBalance()
				})

				metamask.on('disconnected', () => {
					this.clearSubscription()
				})
			}
		},

		monitorCurrency(address: string, chain: number) {
			this.subscription = {
				currency: address,
				chain: chain,
				interval: null,
			}

			theContract = new w3.eth.Contract(IERC20, address)

			if (metamask.state.connected) {
				this.monitor()
			}
		},

		monitor(force = false) {
			if (!this.subscription.currency) {
				return
			}

			if (this.subscription.interval && !force) {
				return
			}

			this.subscription.interval = setInterval(this.updateBalance, 4000)
		},

		async updateBalance() {
			if (!this.wallet) {
				return
			}

			if (this.currentChain != this.subscription.chain) {
				return
			}

			if (this.active) {
				return
			}

			this.active = true

			let tries = 0
			do {
				try {
					let balance = await this.fetchBalance(this.subscription.currency, this.wallet)

					this.balance = balance
					break
				} catch (e) {
					// error fetching balance
					tries++
					await sleep(1)
				}
			} while (tries < 4)

			this.active = false
		},

		updateChain(chain: string | number) {
			this.currentChain = chain
		},

		clearSubscription() {
			if (this.subscription.interval) {
				clearInterval(this.subscription.interval)
			}
		},

		async getWalletOwnership(contracts: string[]) {
			if (!metamask.state.wallet) {
				return []
			} else {
				const alc = new AlchemyApi()
				let tokens = []
				for (let i = 0; i < contracts.length; i++) {
					// ex: "0x4a39cBcA3F2EBaE1053575b4b2199AA5ba16B08c:137"
					const contractAndNetwork = contracts[i].split(':')

					const ownedTokens = await alc
						.getOwnedTokens(contractAndNetwork[1], metamask.state.wallet, contractAndNetwork[0])
						.catch((error) => {
							console.log(error)
							return []
						})
					for (let t = 0; t < ownedTokens.length; t++) {
						ownedTokens[t].network = contractAndNetwork[1]
					}
					tokens[i] = ownedTokens
				}
				return tokens
			}
		},

		async fetchBalance(contract: string, wallet: string): Promise<number> {
			if (!theContract) {
				return 0
			}

			try {
				const b = await theContract.methods.balanceOf(wallet).call()
				return b
			} catch (e) {
				console.error(e)
			}

			return 0
		},
	},

	getters: {
		currentBalance(): Currency {
			if (!this.balance) return new Currency(0)

			return new Currency(this.balance)
		},

		targetChain(): number {
			return this.subscription.chain
		},
	},
})
