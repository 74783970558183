<template>
	<div>
		<fade-transition>
			<div v-if="state == SwapState.COMPLETE">
				<p class="text-green-600 text-lg">Swap completed successfully!</p>
				<div class="grid grid-cols-2 gap-2 mt-4">
					<div>
						<span v-if="swapCurrencies.from == CURRENCY_TYPE_BPX">From Wallet</span>
						<span v-else>To Wallet</span>
					</div>
					<div>
						<wallet-address
							:address="swapCurrencies.from == CURRENCY_TYPE_BPX ? swapResult.from : userWallet"
							:length="8"
						/>
					</div>
					<div>Swapped from</div>
					<div>{{ $format(fromAmount) }} {{ swapCurrencies.from }}</div>
					<div>Swapped to</div>
					<div>
						{{ $format(swapToAmount) }}
						{{ swapCurrencies.to }}
					</div>
					<div>Transaction</div>
					<div>
						<a
							:href="`https://etherscan.io/tx/${swapResult.transactionHash}`"
							target="_blank"
							class="underline text-blue-600"
						>
							<wallet-address :address="swapResult.transactionHash" :length="8" :lookup="false" />
						</a>
					</div>
				</div>
				<div class="text-right mt-6">
					<button
						class="text-lg px-6 py-2 bg-blue-400 text-white hover:bg-blue-600 rounded-xl"
						@click="close"
					>
						Close
					</button>
				</div>
			</div>
			<HeadlessDeposit
				v-else
				@deposit:init="onDepositInit"
				@deposit:error="onDepositError"
				@deposit:completed="onDepositCompleted"
				@withdraw:init="onWithdrawInit"
				@withdraw:error="onWithdrawError"
				@withdraw:completed="onWithdrawCompleted"
			>
				<template #default="{ currency, wallet, swap }">
					<fade-transition mode="out-in">
						<template v-if="swapCurrencies.from === CURRENCY_TYPE_BPX">
							<div>
								<div class="flex justify-between">
									<label class="text-base text-gray-600">From wallet</label>
									<p class="text-right font-mono">
										<wallet-address :address="wallet" :length="8" />
									</p>
								</div>
								<error v-if="userWallet != wallet.toUpperCase()">
									<span
										><strong>Notice!</strong> This wallet is not linked to your account. Deposit
										transactions will <em>not</em> be credited to your account if you send from this
										address.
									</span>
								</error>
							</div>
						</template>
						<template v-else>
							<div>
								<div class="flex justify-between">
									<label class="text-base text-gray-600">To wallet</label>
									<p class="text-right">
										<span class="font-mono"
											><wallet-address :address="userWallet" :length="8"
										/></span>
									</p>
								</div>
								<error v-if="userWallet != wallet.toUpperCase()">
									<span>
										<strong> Notice! </strong> your swapped {{ $token }} will be sent to the wallet
										<em>connected to your account</em>.
									</span>
								</error>
							</div>
						</template>
					</fade-transition>

					<fade-transition>
						<CurrencyInput
							:key="swapCurrencies.from"
							class="bg-white rounded-lg mt-4 border-2 border-gray-200"
							:balance="currency[swapCurrencies.from].balance"
							:available="currency[swapCurrencies.from].available"
							:currency="currency[swapCurrencies.from].symbol"
							v-model="fromAmount"
							:modelValue="fromAmount"
							@input="updateSwapValue($event.target.value, currency)"
							:valid="valid"
							:readonly="state == SwapState.INPROG"
							>Swap {{ swapCurrencies.from }}</CurrencyInput
						>
					</fade-transition>

					<div class="flex justify-center items-center -my-4">
						<button
							title="Swap currencies"
							class="group bg-white enabled:hover:bg-blue-400 enabled:hover:border-blue-400 rounded-full w-12 h-12 p-3 border border-gray-200 shadow active:rotate-90 transition-all duration-400 ease-in-out disabled:bg-gray-200 disabled:border-gray-200 disabled:text-gray-400"
							@click="toggleDeposit"
							:disabled="state == SwapState.INPROG"
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 512 512"
								class="group-enabled:fill-blue-400 group-enabled:group-hover:fill-white group-disabled:fill-gray-400"
								:class="[false ? 'fill-gray-400 group-hover:fill-gray-400' : '']"
							>
								<path
									d="M142.9 142.9c62.2-62.2 162.7-62.5 225.3-1L327 183c-6.9 6.9-8.9 17.2-5.2 26.2s12.5 14.8 22.2 14.8H463.5c0 0 0 0 0 0H472c13.3 0 24-10.7 24-24V72c0-9.7-5.8-18.5-14.8-22.2s-19.3-1.7-26.2 5.2L413.4 96.6c-87.6-86.5-228.7-86.2-315.8 1C73.2 122 55.6 150.7 44.8 181.4c-5.9 16.7 2.9 34.9 19.5 40.8s34.9-2.9 40.8-19.5c7.7-21.8 20.2-42.3 37.8-59.8zM16 312v7.6 .7V440c0 9.7 5.8 18.5 14.8 22.2s19.3 1.7 26.2-5.2l41.6-41.6c87.6 86.5 228.7 86.2 315.8-1c24.4-24.4 42.1-53.1 52.9-83.7c5.9-16.7-2.9-34.9-19.5-40.8s-34.9 2.9-40.8 19.5c-7.7 21.8-20.2 42.3-37.8 59.8c-62.2 62.2-162.7 62.5-225.3 1L185 329c6.9-6.9 8.9-17.2 5.2-26.2s-12.5-14.8-22.2-14.8H48.4h-.7H40c-13.3 0-24 10.7-24 24z"
								/>
							</svg>
						</button>
					</div>

					<fade-transition>
						<CurrencyInput
							:key="swapCurrencies.to"
							class="rounded-lg bg-gradient-to-br from-white to-gray-50 border-2 border-gray-200"
							:balance="currency[swapCurrencies.to].balance"
							:currency="currency[swapCurrencies.to].symbol"
							:modelValue="swapToAmount"
							:readonly="true"
							>For {{ swapCurrencies.to }}</CurrencyInput
						>
					</fade-transition>

					<div class="mt-4">
						<error v-if="valid && swapToAmount > 0 && !swappable">
							You don't have enough {{ swapCurrencies.from }} available to complete this swap.
						</error>
						<error v-if="state == SwapState.ERROR">{{ errMsg }}</error>

						<div v-if="state == SwapState.PENDING || state == SwapState.ERROR">
							<button
								class="block w-full border rounded-full py-2 mt-2 text-lg enabled:bg-blue-400 enabled:text-white enabled:border-blue-400 enabled:shadow disabled:bg-gray-300 disabled:text-gray-500 disabled:border-gray-300 enabled:hover:bg-blue-600 --enabled:hover:text-blue-400"
								:disabled="false == swappable"
								@click="executeSwap(swap)"
							>
								Swap
							</button>
						</div>
						<div
							v-if="state == SwapState.INPROG"
							class="text-center italic text-gray-500 flex justify-center items-center leading-none gap-x-2"
						>
							Waiting for confirmation
							<span class="fa-pulse fa-spin"><i class="fa-solid fa-spinner" /></span>
						</div>
						<div
							v-if="state === SwapState.COMPLETE"
							class="bg-green-200 py-4 text-green-600 mt-2 text-center rounded-xl"
						>
							Swap Complete
						</div>
					</div>
				</template>
			</HeadlessDeposit>
		</fade-transition>
	</div>
</template>
<script lang="ts" setup>
import HeadlessDeposit from '@/components/Headless/Deposit.vue'
import CurrencyInput from '@/components/widgets/CurrencyInput.vue'
import WalletAddress from '@/components/WalletAddress.vue'
import FadeTransition from '@/components/widgets/FadeTransition.vue'
import SuccessDialog from '@/components/Swap/SuccessDialog.vue'
import Error from '@/components/Swap/Error.vue'
import { CURRENCY_TYPE_BPX, CURRENCY_TYPE_MILES } from '@/types/Currency'
import { useAuthStore } from '@/stores/AuthStore'
import { defineExpose, computed, ref } from 'vue'
import Currency from '@/types/Currency'
import { useChainWalletStore } from '@/stores/ChainWalletStore'

const authStore = useAuthStore()
const chainWalletStore = useChainWalletStore()

const props = withDefaults(
	defineProps<{
		marketBpx?: number
	}>(),
	{
		marketBpx: 0,
	},
)

const emit = defineEmits(['cancel', 'close'])

const swapCurrencies = ref({
	from: CURRENCY_TYPE_BPX,
	to: CURRENCY_TYPE_MILES,
})

const fromAmount = ref('')
const swapToAmount = ref('')
const valid = ref(true)
const swappable = ref(false)

const userWallet = computed(() => {
	return String(authStore.user.wallets[0] ?? null).toUpperCase()
})

const chainWallet = computed(() => {
	return chainWalletStore.state.wallet
})

function reset() {
	fromAmount.value = ''
	swapToAmount.value = ''
	state.value = SwapState.PENDING
}

function close() {
	reset()
	emit('cancel')
}

function isSwappable(val, currencyBalance) {
	if (!valid.value) {
		return false
	}

	let v = String(val).replace(/[^0-9.]/g, '')
	if (Number(v) <= 0) {
		return false
	}

	let c = new Currency(currencyBalance[swapCurrencies.value.from].available)
	if (Number(v) > c.toDecimal()) {
		return false
	}

	return true
}

function isValid(val) {
	if (isNaN(Number(val))) {
		if (String(val).length == 0) {
			return true
		}

		if (String(val).match(/[^0-9,. ]/)) {
			console.log('invalid -- not a number')
			return false
		}

		let testValue = String(val).replace(/[^0-9,. ]/g, '')
		if (isNaN(Number(testValue))) {
			console.error('invalid -- not a number')
			return false
		}

		return true
	}

	if (Number(val) < 0) {
		console.error('invalid -- less than 0')
		return false
	}

	if (!String(val).match(/^(4,?)?(\d{1,3}[, ]?){0,3}([ .]\d{0,9})?$/gm)) {
		console.error('invalid -- doesnt match regex')
		return false
	}

	return true
}

function toggleDeposit() {
	const temp = swapCurrencies.value.from
	swapCurrencies.value.from = swapCurrencies.value.to
	swapCurrencies.value.to = temp
	swapToAmount.value = ''
	updateSwapValue('', false)
}

function updateSwapValue(amt, currency?: any) {
	valid.value = isValid(amt)
	if (currency) {
		swappable.value = isSwappable(amt, currency)
	} else {
		swappable.value = false
	}

	if (valid.value) {
		swapToAmount.value = amt
	}
}

async function executeSwap(swapFn: Function) {
	try {
		swapResult.value = null
		let result = await swapFn(
			{
				symbol: swapCurrencies.value.from,
				amount: String(fromAmount.value),
			},
			{
				symbol: swapCurrencies.value.to,
				amount: String(swapToAmount.value),
			},
			userWallet.value,
		)

		if (result) {
			swapResult.value = result
			state.value = SwapState.COMPLETE
		}
	} catch (error) {
		state.value = SwapState.ERROR
		console.log('error', error)
	}
}

enum SwapType {
	DEPOSIT = 'deposit',
	WITHDRAW = 'withdraw',
}

enum SwapState {
	PENDING = 'pending',
	INPROG = 'in-progress',
	ERROR = 'error',
	COMPLETE = 'complete',
}
const state = ref<SwapState>(SwapState.PENDING)
const errMsg = ref<boolean | string>(false)
const swapResult = ref(null)

function init(type: SwapType) {
	errMsg.value = false
	state.value = SwapState.INPROG
}

function complete(type: SwapType, result) {
	if (result) {
		swapResult.value = result
		state.value = SwapState.COMPLETE
	} else {
		state.value = SwapState.ERROR
		errMsg.value = 'Transaction rejected'
	}
}

function err(e: Error, type: SwapType) {
	state.value = SwapState.ERROR
	if (e.message.includes('reverted')) {
		console.error('reverted!')
		errMsg.value = 'Failed to create authorization transaction'
	} else {
		errMsg.value = e.message
	}
}

const onDepositInit = () => init(SwapType.DEPOSIT)
const onDepositError = (e: Error) => err(e, SwapType.DEPOSIT)
const onDepositCompleted = (res) => complete(SwapType.DEPOSIT, res)

const onWithdrawInit = () => init(SwapType.WITHDRAW)
const onWithdrawError = (e: Error) => err(e, SwapType.WITHDRAW)
const onWithdrawCompleted = (res) => complete(SwapType.WITHDRAW, res)

defineExpose({
	close,
	reset,
})
</script>
