import { useDripStore } from '@/stores/DripStore'
import { waitLoad } from '@/util/sleep'

export default function useDrips() {
	const dripStore = useDripStore()

	async function load(assetId: string) {
		return await dripStore.load(assetId)
	}

	async function claim(assetId: string) {
		return await dripStore.claim(assetId)
	}

	return {
		load,
		claim,
	}
}
