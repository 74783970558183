<template>
	<div class="flex">
		<login-button v-if="!authStore.authenticated" />
		<auth-menu v-else :user="authStore.user" :balance="bpxBalance" />
	</div>
</template>

<script lang="ts" setup>
import { useAuthStore } from '@/stores/AuthStore'
import { useNewWalletStore as useWalletStore } from '@/stores/NewWalletStore'
import { onMounted, computed } from 'vue'

import LoginButton from './LoginButton.vue'
// import NavAccountMenu from './NavAccountMenu.vue'
import AuthMenu from '@/components/Auth/Menu.vue'

import { useEchoChannelStore } from '@/stores/EchoChannelStore'
import { useUserFavoriteStore } from '@/stores/UserFavoriteStore'
import Currency from '@/types/Currency'
import BN from 'bn.js'

const authStore = useAuthStore()
const echoChannelStore = useEchoChannelStore()
const userFav = useUserFavoriteStore()
const walletStore = useWalletStore()

onMounted(async () => {
	if (await authStore.login()) {
		echoChannelStore.init(authStore.user.id)
		userFav.state = authStore.user.favorites
	} else {
		echoChannelStore.init()
	}
})

const bpxBalance = computed(() => {
	let b = walletStore.balances.reduce(function (total: BN, b) {
		if (['bpx', 'vbpx'].includes(b.slug.toLowerCase())) {
			return total.add(new BN(b.available.toString()))
		}

		return total
	}, new BN('0'))

	return String(
		new Currency(b.toString()).toDecimal({
			showDecimals: false,
		}),
	)
})
</script>
