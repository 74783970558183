<style lang="postcss">
.carousel-enter-active,
.carousel-leave-active {
	transition: all 0.5s ease;
}

.carousel-enter-from {
	@apply opacity-0;
}

.carousel-leave-to {
	@apply opacity-0;
}
</style>
<template>
	<div class="mt-4 -mx-6 md:mx-0 overflow-x-hidden relative md:rounded-xl overflow-hidden bg-slate-800">
		<button
			class="absolute inset-0 right-auto z-20 pl-4 pr-8 bg-gradient-to-r from-slate-950/30 to-transparent text-white/90 hover:text-white transition-colors"
			@click="handlePrevSlide"
		>
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="h-8 md:h-16 fill-current">
				<path
					d="M47 239c-9.4 9.4-9.4 24.6 0 33.9L207 433c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9L97.9 256 241 113c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0L47 239z"
				/>
			</svg>
		</button>
		<button
			class="absolute inset-0 left-auto z-20 pr-4 pl-8 bg-gradient-to-l from-slate-950/30 to-transparent text-white/90 hover:text-white transition-colors"
			@click="handleNextSlide"
		>
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="h-8 md:h-16 fill-current">
				<path
					d="M273 239c9.4 9.4 9.4 24.6 0 33.9L113 433c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l143-143L79 113c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0L273 239z"
				/>
			</svg>
		</button>

		<div class="hidden md:flex absolute inset-6 top-auto z-20 text-white gap-2 justify-center">
			<button v-for="rip in props.slides" @click="handleSelectSlide(rip.key)">
				<svg
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 512 512"
					v-if="rip.key == currentSlide.key"
					class="w-2 fill-current"
				>
					<path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512z" />
				</svg>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 512 512"
					v-if="rip.key != currentSlide.key"
					class="w-2 fill-current"
				>
					<path
						d="M464 256A208 208 0 1 0 48 256a208 208 0 1 0 416 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256z"
					/>
				</svg>
			</button>
		</div>

		<div class="relative aspect-square md:aspect-video">
			<transition name="carousel">
				<div
					:key="currentSlide.key"
					class="absolute top-0 w-full h-full bg-cover bg-bottom md:bg-center md:rounded-xl overflow-hidden"
					:style="{ 'background-image': 'url(' + currentSlide.image + ')' }"
				>
					<div
						class="absolute inset-0 top-auto px-12 pt-16 pb-6 md:px-16 md:pb-16 md:pt-24 bg-gradient-to-t from-slate-950 via-slate-950/75 to-transparent"
					>
						<div>
							<div
								class="text-base md:text-lg/relaxed xl:text-2xl/relaxed text-white font-semibold tracking-wide empty:hidden uppercase"
							>
								{{ currentSlide.subtitle }}
							</div>
							<div
								class="text-3xl md:text-5xl/tight xl:text-6xl/tight text-white font-semibold tracking-wide font-poppins"
							>
								{{ currentSlide.title }}
							</div>
						</div>
						<div class="pt-6">
							<a
								:href="currentSlide.link"
								class="inline-block bg-white text-black text-base sm:text-lg font-semibold rounded-full w-full md:w-auto text-center md:text-left py-2 md:px-8 md:py-4 shadow hover:bg-red-700 hover:text-white hover:scale-105 transition-all"
							>
								{{ currentSlide.cta }}
							</a>
						</div>
					</div>
				</div>
			</transition>
		</div>
	</div>
</template>
<script setup lang="ts">
import { ref, computed, onMounted, onUnmounted, unref } from 'vue'

export interface Slide {
	key: number
	title: string
	subtitle: string
	image: string
	cta: string
	link: string
}

const props = defineProps({
	slides: {
		type: Array<Slide>,
		required: true,
	},
	interval: {
		type: Number,
		required: true,
		default: 5000,
	},
})

const selectedSlide = ref(0)

function handleNextSlide() {
	turnOffInterval()
	nextSlide()
	turnOnInterval()
}

const nextSlide = () => {
	selectedSlide.value = (selectedSlide.value + 1) % props.slides.length
}

function handlePrevSlide() {
	turnOffInterval()
	prevSlide()
	turnOnInterval()
}

const prevSlide = () => {
	if (selectedSlide.value - 1 < 0) {
		selectedSlide.value = selectedSlide.value + props.slides.length - 1
		return
	}

	selectedSlide.value--
}

function handleSelectSlide(slide: number) {
	turnOffInterval()
	selectSlide(slide)
	turnOnInterval()
}

function handleMouseOver() {
	turnOffInterval()
}

function handleMouseOut() {
	turnOnInterval()
}

const selectSlide = (slide: number) => {
	selectedSlide.value = slide
}

const currentSlide = computed(() => {
	return props.slides[selectedSlide.value]
})

const interval = ref(null)

const turnOnInterval = () => {
	if (interval.value !== null) {
		return
	}

	interval.value = window.setInterval(() => {
		handleNextSlide()
	}, props.interval)
}

const turnOffInterval = () => {
	if (interval.value === null) {
		return
	}

	window.clearInterval(interval.value)
	interval.value = null
}

onMounted(turnOnInterval)
onUnmounted(turnOffInterval)
</script>
