<template>
	<section class="">
		<div class="grid grid-cols-1 gap-6">
			<div class="rounded-lg border border-gray-200 bg-gray-50">
				<h2 class="text-lg font-medium leading-6 text-gray-900 inline-flex px-6 py-3 pt-4 items-center">
					<slot name="header">
						<ListBulletIcon class="stroke-2 mr-2 h-6 w-6 text-gray-900" />
						Offers
					</slot>
				</h2>
				<div class="flex flex-col text-sm text-gray-500">
					<div class="inline-block min-w-full align-middle">
						<div class="overflow-y-scroll h-80 max-h-80 min-h-[12rem] border-t border-gray-200 space-y-2">
							<div v-if="props.asset.offers.length == 0" class="text-center mt-6">
								<svg
									class="mx-auto h-16 w-16 text-gray-400"
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
									aria-hidden="true"
								>
									<RectangleStackIcon class="stroke-1" />
								</svg>
								<h3 class="mt-2 text-sm font-normal text-gray-900">No Offers yet</h3>
							</div>
							<div v-else>
								<div>
									<div
										class="sticky top-0 left-0 right-0 bg-gray-100 shadow grid grid-cols-[3fr,3fr,3fr,2fr] font-medium text-gray-900 pl-6 px-2 py-2 border-b border-gray-200 items-start space-x-1 z-10"
									>
										<p class="">Offer Amount</p>
										<p class="">Total Volume</p>
										<p class="">Available</p>
										<p class="">&nbsp;</p>
									</div>
									<article
										v-for="offer of props.asset.offers"
										class="grid grid-cols-[3fr,3fr,3fr,2fr] items-center pl-6 p-2 border-b border-gray-200 space-x-1"
									>
										<p class="flex-grow py-2">
											{{
												$format(
													humanReadablePrice(BigInt(offer.offer_bpx_per_token).toString()),
												)
											}}
											{{ $token }}
										</p>
										<p class="flex-grow py-2">
											{{
												$format(
													humanReadablePrice(
														(
															BigInt(offer.offer_bpx_per_token) *
															BigInt(
																offer.total_offer_token_count - offer.tokens_fulfilled,
															)
														).toString(),
													),
												)
											}}
											{{ $token }}
										</p>
										<div class="flex-shrink py-2">
											<div>
												{{ offer.total_offer_token_count - offer.tokens_fulfilled }}
											</div>

											<span v-if="offer.expires_at" class="text-xs">
												until
												<!-- some day -->
												<template
													v-if="
														DateTime.fromISO(offer.expires_at).minus({ hours: 24 }) >
														DateTime.now()
													"
												>
													{{ DateTime.fromISO(offer.expires_at).toFormat('D') }}
												</template>

												<!-- tomorrow -->
												<template
													v-else-if="
														DateTime.fromISO(offer.expires_at).day ===
														DateTime.now().plus({ day: 1 }).day
													"
												>
													{{ DateTime.fromISO(offer.expires_at).toFormat('t') }} tomorrow
												</template>

												<!-- today -->
												<template v-else>
													{{ DateTime.fromISO(offer.expires_at).toFormat('t') }} today
												</template>
											</span>
										</div>
										<div class="text-right self-stretch">
											<button
												v-if="
													authStore.authenticated &&
													offer.created_by_user_id === authStore.user.id
												"
												@click="handleOfferCancel(offer)"
												class="btn shadow-none text-gray-400 h-full hover:text-slate-800 hover:bg-gray-200 hover:shadow space-x-2 whitespace-nowrap group"
											>
												<span><i class="fas fa-trash group-hover:text-red-600"></i></span>
												<span>Cancel</span>
											</button>
											<button
												v-else-if="flagStore.checkFlag(Flags.OFFERS_ACCEPT) && isAssetOwner"
												@click="handleOfferAccept(offer)"
												class="btn-primary h-full bg-sky-600 enabled:hover:bg-sky-700"
											>
												Accept
											</button>
										</div>
									</article>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<cancel-offer-modal
			v-if="state.isCancelling"
			:offer="state.offer"
			:asset="props.asset"
			@close="handleModal(false)"
			@reload="handleModal(true)"
		/>
		<accept-offer-modal
			v-if="state.isAccepting"
			:offer="state.offer"
			:asset="props.asset"
			@close="handleModal(false)"
			@reload="handleModal(true)"
		/>
	</section>
</template>
<script lang="ts" setup>
import { ComputedRef, computed, reactive } from 'vue'
import { ListBulletIcon, RectangleStackIcon } from '@heroicons/vue/24/outline'
import { Asset } from '@/types/Asset'
import { useAuthStore } from '@/stores/AuthStore'
import { humanReadablePrice } from '@/util/currencyFormat'
import { Offer } from '@/types/Storefront'
import CancelOfferModal from './modals/CancelOfferModal.vue'
import AcceptOfferModal from './modals/AcceptOfferModal.vue'
import { useFlagStore } from '@/stores/FlagStore'
import { Flags } from '@/types/Flags'
import { DateTime } from 'ts-luxon'

const flagStore = useFlagStore()
const authStore = useAuthStore()

const state = reactive({
	isCancelling: false,
	isAccepting: false,
	offer: null as Offer | null,
})

const emit = defineEmits<{
	(e: 'reload-asset'): void
}>()

const props = defineProps<{
	asset: Asset
	isAssetOwner: Boolean
}>()

// Convert Bipixies (no decimals) to BPX (9 decimal places)
function toFloat(value: bigint): number {
	const integralPart = value / BigInt(1e9)
	const decimalPart = Number(value % BigInt(1e9)) / 1e9
	return Number(integralPart) + decimalPart
}

function handleOfferAccept(offer: Offer) {
	state.offer = offer
	state.isAccepting = true
}

function handleOfferCancel(offer: Offer) {
	state.offer = offer
	state.isCancelling = true
}

async function handleModal(shouldReload: boolean) {
	if (state.isCancelling) {
		state.isCancelling = false
	}

	if (state.isAccepting) {
		state.isAccepting = false
	}

	state.offer = null

	if (shouldReload) {
		emit('reload-asset')
	}
}
</script>
