import { library } from '@fortawesome/fontawesome-svg-core'

import {
	faArrowUpArrowDown,
	faAt,
	faBackwardFast,
	faBadgeCheck,
	faBarsFilter,
	faBurst,
	faCandy,
	faCardsBlank,
	faCaretLeft,
	faCaretRight,
	faCheck,
	faCheckCircle,
	faChevronDown,
	faChevronUp,
	faCircleXmark,
	faClose,
	faExclamation,
	faExclamationCircle,
	faEye,
	faForwardFast,
	faGavel,
	faHeart,
	faInfo,
	faInfoCircle,
	faMagnifyingGlass,
	faMinus,
	faMobileScreenButton,
	faMoneyBill1Wave,
	faPaperPlane,
	faPencil,
	faPlus,
	faRectangleVerticalHistory,
	faShareNodes,
	faSignIn,
	faSignOut,
	faSpinner,
	faSpinnerScale,
	faSpinnerThird,
	faTimer,
	faTimes,
	faTimesCircle,
	faTrash,
	faTrophy,
	faTriangleExclamation,
	faTruckFast,
	faTurnDownLeft,
	faUser,
	faWallet,
	faMoneyBillTransfer,
	faArrowTurnLeft,
	faPencil,
} from '@awesome.me/kit-428607b2fd/icons/classic/solid'

library.add(
	faArrowUpArrowDown,
	faAt,
	faBackwardFast,
	faBadgeCheck,
	faBarsFilter,
	faBurst,
	faCandy,
	faCardsBlank,
	faCaretLeft,
	faCaretRight,
	faCheck,
	faCheckCircle,
	faChevronDown,
	faChevronUp,
	faCircleXmark,
	faClose,
	faExclamation,
	faExclamationCircle,
	faEye,
	faForwardFast,
	faGavel,
	faHeart,
	faInfo,
	faInfoCircle,
	faMagnifyingGlass,
	faMinus,
	faMobileScreenButton,
	faMoneyBill1Wave,
	faPaperPlane,
	faPencil,
	faPlus,
	faRectangleVerticalHistory,
	faShareNodes,
	faSignIn,
	faSignOut,
	faSpinner,
	faSpinnerScale,
	faSpinnerThird,
	faTimer,
	faTimes,
	faTimesCircle,
	faTrash,
	faTrophy,
	faTriangleExclamation,
	faTruckFast,
	faTurnDownLeft,
	faUser,
	faWallet,
	faMoneyBillTransfer,
	faArrowTurnLeft,
	faPencil,
)
