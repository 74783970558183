<template>
	<p>An unexpected error occurred</p>
</template>
<script lang="ts">
import { defineComponent, PropType } from 'vue'

export default defineComponent({
	props: {
		error: {
			type: Object as PropType<Error>,
			required: false,
			default: () => new Error(),
		},
	},
	setup(props) {
		return {
			props,
		}
	},
})
</script>
