<template>
	<section class="">
		<div class="grid grid-cols-1 gap-6">
			<div class="rounded-lg border border-gray-200 bg-gray-50">
				<div class="flex justify-between px-6 py-3 pt-4 items-center">
					<h2 class="text-lg font-medium leading-6 text-gray-900 inline-flex items-center">
						<span class="text-gray-900 text-lg mr-2"><i class="fa-regular fa-gavel"></i></span>
						Auctions
					</h2>
				</div>
				<div class="flex flex-col text-sm text-gray-500">
					<div class="inline-block min-w-full align-middle">
						<div
							class="overflow-y-scroll h-80 max-h-80 border-t border-gray-200 space-y-2"
							:class="{ 'min-h-[12rem]': props.auctions.length > 0 }"
						>
							<div v-if="props.auctions.length == 0" class="text-center mt-6">
								<i class="fa-fw fa-2x fa-regular fa-gavel"></i>
								<h3 class="mt-2 text-sm font-normal text-gray-900">No auctions yet</h3>
							</div>
							<div v-else>
								<div
									class="sticky top-0 left-0 right-0 bg-gray-100 shadow mb-2 grid grid-cols-[2fr,2fr,2fr,2fr,1fr] font-medium text-gray-900 pl-6 px-2 py-2 border-b border-gray-200 items-start space-x-1"
								>
									<p class="">Current Bid</p>
									<p>Bids</p>
									<p class="">Qty.</p>
									<!-- <p class="">Time Remaining</p> -->
									<p class="">Seller</p>
									<!-- <p class="">&nbsp;</p> -->
								</div>
								<div
									v-for="auction in props.auctions"
									:key="`auction-${auction.id}`"
									class="grid grid-cols-[2fr,2fr,2fr,2fr,1fr] pl-6 px-2 py-2 border-b border-gray-200 items-start"
								>
									<div
										v-if="auction.viewer_is_high_bidder"
										class="col-span-5 bg-blue-100 text-blue-900 px-2 py-1 rounded mb-2"
									>
										<p>You're the high bidder!</p>
									</div>
									<div
										v-else-if="auction.viewer_has_bid"
										class="col-span-5 bg-red-200 text-red-900 px-2 py-1 rounded mb-2"
									>
										<p>You've been outbid!</p>
									</div>
									<p class="flex-grow row-span-2">
										<span class="whitespace-nowrap text-gray-600">
											{{
												$format(
													humanReadablePrice(
														Math.max(auction.high_bid_amount, auction.price),
													),
												)
											}}
											{{ $token }}
										</span>
									</p>
									<p>
										{{ $format(auction.bids?.length ?? 0) }}
										<pluralize :amount="auction.bids?.length ?? 0" singular="bid" plural="bids" />
									</p>
									<p class="flex-shrink">{{ auction.quantity }}</p>
									<p class="flex-grow overflow-hidden">
										{{ auction.owner.username }}
									</p>
									<p class="row-span-2">
										<button
											v-if="!isAuthenticated || auction.seller_user_id != userID"
											class="btn-primary h-full whitespace-nowrap"
											@click="$emit('buy', auction)"
										>
											Bid
										</button>
									</p>
									<div v-if="auction.viewer_has_bid" class="col-span-4 font-semibold">
										<p>
											Your max bid:
											{{ $format(humanReadablePrice(String(auction.viewer_max_bid.bid_amount))) }}
											{{ $token }}
										</p>
									</div>
									<p class="col-span-5 text-gray-600 italic py-1 rounded my-1">
										<span v-if="DateTime.now() < auction.expires_at">
											Extended bidding begins on
											{{
												DateTime.fromISO(auction.expires_at).toFormat(
													'M/dd/yyyy \@ h:mm a ZZZZ',
												)
											}}
										</span>
										<span v-else-if="DateTime.now() < auction.extended_bidding_ends_at">
											Extended bidding ends on
											{{
												DateTime.fromISO(auction.extended_bidding_ends_at).toFormat(
													'M/dd/yyyy \@ h:mm a ZZZZ',
												)
											}}
										</span>
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>
<script lang="ts" setup>
import { computed } from 'vue'
import ToUsd from '@/components/ToUsd.vue'
import { Listing } from '@/types/Asset'
import auction from '@/util/auction'
import { humanReadablePrice } from '@/util/currencyFormat'
import { DateTime } from 'ts-luxon'
import { useAuthStore } from '@/stores/AuthStore'

const authStore = useAuthStore()

const props = defineProps<{
	auctions: Listing[]
}>()

const isAuthenticated = computed(() => {
	return authStore.authenticated
})

const userID = computed(() => {
	if (!isAuthenticated.value) {
		return null
	}

	return authStore.user?.id ?? null
})
</script>
