import { Auction } from '@/types/Auction'
import { DateTime } from 'ts-luxon'

export const Sorts = {
	END_DATE_DESC: 'END_DATE_DESC',
	END_DATE: 'END_DATE',
	BID_VALUE: 'BID_VALUE',
	BID_VALUE_DESC: 'BID_VALUE_DESC',
	BID_ACTIVITY: 'BID_ACTIVITY',
	BID_ACTIVITY_DESC: 'BID_ACTIVITY_DESC',
	BID_COUNT: 'BID_COUNT',
	BID_COUNT_DESC: 'BID_COUNT_DESC',
	BIDDERS: 'BIDDERS',
	BIDDERS_DESC: 'BIDDERS_DESC',
}

export const SortLabels = {
	END_DATE_DESC: 'Time left: ending soonest',
	END_DATE: 'Time left: newly listed',
	BID_VALUE: 'Current bid: lowest',
	BID_VALUE_DESC: 'Current bid: highest',
	BID_ACTIVITY: 'Bid activity: oldest bid',
	BID_ACTIVITY_DESC: 'Bid activity: newest bid',
	BID_COUNT: 'Bid count: least bids',
	BID_COUNT_DESC: 'Bid count: most bids',
	BIDDERS: 'Bidders: fewest bidders',
	BIDDERS_DESC: 'Bidders: most bidders',
}

type Comparable = number | string | DateTime
type Reverse = boolean
type Comparator = [Comparable, Comparable, Reverse]

export const useAuctionSort = function () {
	function getSortComparisons(sort: string, auctions: Auction[2]): Comparator[] {
		switch (sort) {
			case Sorts.BID_ACTIVITY:
			case Sorts.BID_ACTIVITY_DESC:
				return [
					[...auctions.map((a) => (a.bids.length ? 1 : 0)), true],
					[...auctions.map((a) => (a.bids.length ? a.bids[0].bid_at : 0)), Sorts.BID_ACTIVITY_DESC == sort],
					[...auctions.map((a) => a.end_at), true],
				]

			case Sorts.BID_VALUE:
			case Sorts.BID_VALUE_DESC:
				return [
					[...auctions.map((a) => a.high_bid), Sorts.BID_VALUE_DESC == sort],
					[...auctions.map((a) => a.end_at), true],
				]

			case Sorts.BID_COUNT:
			case Sorts.BID_COUNT_DESC:
				return [
					[...auctions.map((a) => a.bids.length), Sorts.BID_COUNT_DESC == sort],
					[...auctions.map((a) => a.high_bid), true],
					[...auctions.map((a) => a.end_at), true],
				]

			case Sorts.BIDDERS:
			case Sorts.BIDDERS_DESC:
				return [
					[
						...auctions.map((a) => Array.from(new Set(a.bids.map((b) => b.bidder))).length),
						Sorts.BIDDERS_DESC == sort,
					],
					[...auctions.map((a) => a.high_bid), true],
					[...auctions.map((a) => a.end_at), true],
				]

			case Sorts.END_DATE:
			case Sorts.END_DATE_DESC:
			default:
				return [
					[...auctions.map((a) => a.end_at), Sorts.END_DATE_DESC == sort],
					[...auctions.map((a) => a.high_bid), true],
				]
		}
	}

	function getSortFunction(sort: string): Function {
		return (left, right) => {
			for (const compare of getSortComparisons(sort, [left, right])) {
				const [a, b, reverse] = compare
				if (a == b) continue

				return (reverse ? -1 : 1) * (a - b)
			}
		}
	}

	return getSortFunction
}
