<template>
	<transition
		enter-active-class="transition ease-in duration-150"
		enter-from-class="opacity-0 translate-y-1"
		enter-to-class="opacity-100 translate-y-0"
		leave-active-class="transition ease-in duration-150"
		leave-from-class="opacity-100 translate-y-0"
		leave-to-class="opacity-0 translate-y-1"
		mode="out-in"
		v-bind="$attrs"
	>
		<slot />
	</transition>
</template>
<script lang="ts" setup></script>
