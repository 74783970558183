import { defineStore } from 'pinia'
import api from '@/util/api'
import { useAuthStore } from './AuthStore'
import { Ref, computed, ref } from 'vue'
import { Drip, DripClaimResponse } from '@/types/Drip'

export const useDripStore = defineStore('drip', () => {
	const authStore = useAuthStore()

	const drips = ref<{
		[assetId: string]: Drip | null
	}>({})

	const intervals: {
		[assetId: string]: ReturnType<typeof setInterval>
	} = {}

	async function load(assetId: string): Promise<Drip | null> {
		drips.value[assetId] = await api.loadDrip(assetId)
		return drips.value[assetId]
	}

	async function claim(assetId: string): Promise<DripClaimResponse> {
		return await api.claimDrip(assetId)
	}

	function watch(assetId: string): void {
		if (intervals[assetId] !== undefined) {
			clearInterval(intervals[assetId])
		}

		intervals[assetId] = setInterval(() => load(assetId), 1000 * 60 * 5) // refresh drip every 5 minutes
	}

	function unwatch(assetId: string): void {
		if (intervals[assetId] !== undefined) {
			clearInterval(intervals[assetId])
		}
	}

	return {
		// state
		drips,

		// actions
		load,
		claim,
		watch,
		unwatch,
	}
})
