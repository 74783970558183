<template>
	<Popover class="relative">
		<PopoverButton class="flex pl- h-full md:mx-8 text-base overflow-hidden items-center bg-gray-50 rounded-lg">
			<div class="flex items-center rounded-full bg-gray-50 text-gray-900 tracking-tight text-lg">
				<div class="hidden sm:flex items-center mr-2 pl-4">
					<CreditCardIcon class="w-6 h-6 text-gray-600" />
					<p class="ml-1">
						{{ $format(props.balance) + ' ' + $token }}
					</p>
				</div>

				<div
					class="flex items-center rounded-lg hover:bg-gray-200 text-gray-900 sm:px-2 sm:shadow-lg sm:shadow-gray-400"
				>
					<p class="hidden sm:block ml-2">@{{ props.user.username }}</p>

					<Avatar
						:username="props.user.username"
						class="aspect-square m-1 mx-1 sm:mx-2 max-w-10 rounded-full bg-gray-50 border-2"
					/>
				</div>
			</div>
		</PopoverButton>

		<transition
			enter-active-class="transition ease-out duration-200"
			enter-from-class="opacity-0 translate-y-1"
			enter-to-class="opacity-100 translate-y-0"
			leave-active-class="transition ease-in duration-150"
			leave-from-class="opacity-100 translate-y-0"
			leave-to-class="opacity-0 translate-y-1"
		>
			<PopoverPanel
				class="absolute left-auto right-0 sm:right-8 z-10 mt-2 flex max-w-[calc(100dvw-1rem)] w-screen sm:max-w-screen-xs max-h-[calc(100dvh-100px)] pt-0.5"
				v-slot="{ close }"
			>
				<div
					class="w-full flex-auto rounded-lg bg-gray-100 text-sm shadow-lg ring-1 ring-gray-900/5 leading-snug text-gray-900 pb-4 pr-2"
				>
					<PopoverButton
						class="absolute z-10 top-4 right-4 p-3 rounded-full flex justify-center items-center leading-none bg-gray-100 hover:bg-gray-200 transition-colors duration-300 ease-in-out w-12 h-12 sm:w-10 sm:h-10 aspect-square"
					>
						<span><i class="fa-solid fa-times fa-xl leading-none" /></span>
					</PopoverButton>

					<div class="slim overflow-y-auto h-full pr-2">
						<div class="text-center">
							<Avatar
								:username="props.user.username"
								size="256"
								class="w-1/4 bg-white mx-auto aspect-square object-cover rounded-full my-2 mt-8 border-2 border-boba-sand"
							>
								<div
									class="absolute right-0 bottom-0 bg-white rounded-full w-6 h-6 border border-gray-300 flex justify-center items-center"
								>
									<a href="https://id.blokpax.com/profile" title="Edit account avatar image">
										<span class="text-xs text-gray-500 hover:text-gray-900">
											<i class="fa-solid fa-pencil" />
										</span>
									</a>
								</div>
							</Avatar>
							<p class="text-boba-brown text-xl text-center">Hi, {{ props.user.username }}!</p>

							<div class="flex text-left w-full justify-start items-end flex-grow gap-x-1 pl-4 mt-6">
								<div>
									<p class="text-gray-600 text-left">Your Miles Balance</p>
									<div class="flex items-center gap-x-1">
										<div>
											<CreditCardIcon class="w-6 h-6 text-gray-600" />
										</div>
										<p class="tracking-tight --flex items-center gap-x-1 text-xl">
											{{ $format(props.balance) }}
											{{ $token }}
										</p>
									</div>
								</div>
								<wallet-connected>
									<template #connected>
										<div class="text-right flex-grow">
											<button
												class="px-4 py-3 shadow rounded-lg text-gray-500 bg-white gap-x-2 cursor-pointer space-x-2 hover:bg-gray-300 hover:text-gray-900 hover:shadow-none"
												@click="showSwapDialog"
											>
												<span><i class="fa-solid fa-money-bill-transfer" /></span>
												<span>Swap</span>
											</button>
										</div>
									</template>
								</wallet-connected>
							</div>
						</div>

						<div class="mt-6 ml-4 mb-4 relative">
							<fade-transition mode="out-in">
								<MenuItems @close="close" />
							</fade-transition>
						</div>

						<fade-transition mode="out-in">
							<Dialog
								ref="swapDialog"
								class="backdrop:bg-black/80 p-6 max-w-screen-xs mx-4 sm:mx-auto"
								@cancel="closeSwapDialog()"
							>
								<button
									class="absolute top-4 right-4 p-3 rounded-full flex justify-center items-center leading-none hover:bg-gray-200 transition-colors duration-300 ease-in-out w-12 h-12 sm:w-10 sm:h-10 aspect-square"
									@click="closeSwapDialog()"
								>
									<span><i class="fa-solid fa-times fa-xl leading-none" /></span>
								</button>
								<h1 class="text-2xl">Swap Miles</h1>
								<p class="mt-4 text-balance">
									Exchange your BPX for Reward Miles. Reward Miles and BPX exchange 1-for-1, so you
									get 1 Reward Mile for every BPX you exchange.
								</p>
								<Swap ref="swapComponent" class="mt-4" @cancel="closeSwapDialog()" />
							</Dialog>
						</fade-transition>

						<div class="ml-4 flex justify-center mt-6 gap-x-2 text-xs text-gray-500">
							<p><a href="/privacy" target="_blank" class="hover:text-gray-900">Privacy Policy</a></p>
							<p>&middot;</p>
							<p><a href="/terms" target="_blank" class="hover:text-gray-900">Terms of Service</a></p>
						</div>
					</div>
				</div>
			</PopoverPanel>
		</transition>
	</Popover>
</template>
<script lang="ts" setup>
import Dialog from '@/components/modals/Dialog.vue'
import Avatar from '@/components/Auth/Avatar.vue'
import MenuItems from '@/components/Auth/MenuItems.vue'
import Swap from '@/components/Auth/Swap.vue'
import FadeTransition from '@/components/widgets/FadeTransition.vue'
import WalletConnected from '@/components/WalletConnected.vue'
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
import { CreditCardIcon } from '@heroicons/vue/24/outline'
import { ref } from 'vue'

const props = defineProps<{
	user: any
	balance: string
}>()

const swapDialog = ref()
const swapComponent = ref()

function showSwapDialog() {
	swapDialog.value.showDialog()
}

function closeSwapDialog() {
	swapComponent.value.reset()
	swapDialog.value.closeDialog()
}
</script>
