<template>
	<div class="flex flex-col group relative bg-white shadow rounded-xl cursor-pointer">
		<div class="group relative">
			<p
				v-if="asset.balance && (asset.balance ?? 0) > 1"
				class="flex justify-center items-center absolute top-4 right-4 px-2 py-2 leading-none text-sm bg-black/80 rounded-lg text-white shadow-md shadow-gray-400 z-10"
			>
				<i class="fa-regular fa-layer-group mr-1 p-0 m-0 leading-none"></i>
				{{ asset.balance ?? 0 }}
			</p>
			<div class="rounded-t-xl overflow-hidden shadow">
				<img
					:src="$cdnify(asset.rawMetadata.image, 1024)"
					@load="state.loaded = true"
					loading="lazy"
					class="w-full ease-in-out duration-300 hover:scale-110"
				/>
			</div>
		</div>
		<div class="flex-grow flex flex-col py-4 px-3">
			<p
				class="flex-grow font-medium md:text-sm overflow-hidden whitespace-nowrap text-ellipsis"
				:title="asset.rawMetadata.name"
			>
				<slot name="name" :asset-name="asset.rawMetadata.name">
					{{ asset.rawMetadata.name }}
				</slot>
			</p>
			<p class="block text-gray-500 text-sm whitespace-nowrap overflow-hidden text-ellipsis">
				{{ asset.contract.name.split('Home Team Heroes: ').pop() }}
			</p>
		</div>
		<div
			v-if="asset.balance && asset.balance > 0"
			class="hidden group-hover:flex justify-left items-stretch absolute bottom-0 left-0 right-0 rounded-b-xl overflow-hidden text-white shadow-t-md"
		>
			<button
				div
				class="block bg-sky-800 h-full flex-grow text-center px-4 py-2 hover:bg-sky-700"
				@click="state.showTransferAssetModal = true"
			>
				Deposit
			</button>
			<button class="hidden border-l border-sky-100 flex-shrink h-full bg-sky-800 hover:bg-sky-700 px-4 py-2">
				...
			</button>
		</div>
		<deposit-asset-modal
			v-if="state.showTransferAssetModal"
			:asset="asset"
			:max-transferrable="asset.balance"
			@close="state.showTransferAssetModal = false"
			@complete="handleAssetTransferred"
		/>
	</div>
</template>

<script lang="ts" setup>
import { Asset } from '@/types/Asset'
import { defineEmits, computed, defineComponent, PropType, reactive, ref } from 'vue'
import LoadingPlaceholder from './LoadingPlaceholder.vue'
import { useMarketStore } from '@/stores/MarketStore'
import { ShoppingBagIcon } from '@heroicons/vue/24/outline'
import DepositAssetModal from '@/components/modals/DepositAssetModal.vue'
import { humanReadablePrice } from '@/util/currencyFormat'
import ToUsd from './ToUsd.vue'
import { OwnedToken } from '@/types/Asset'

const emit = defineEmits(['asset-transferred'])

const state = reactive({
	loaded: false,
	showTransferAssetModal: false,
})

function handleAssetTransferred(asset) {
	state.showTransferAssetModal = false
	emit('asset-transferred', asset)
}
const props = defineProps<{
	asset
}>()
</script>
