export function omnisend(name: string, payload: object) {
	if (!window.omnisend) {
		console.debug('omnisend not present, skipping')
		return
	}

	const envelope = ['track', name, payload]

	console.debug('sending omnisend event', { envelope })

	window.omnisend.push(envelope)
}

export function identifyContact(email: string | null = null, phone: string | null = null) {
	if (!window.omnisend || window.omnisend.identifyContact == undefined) {
		console.debug('omnisend not present, skipping')
		return
	}

	if (email || phone) {
		console.debug('identifying omnisend contact')
		window.omnisend.identifyContact({
			email,
			phone,
		})
	}
}
