<template>
	<p>This auction is closed.</p>
</template>
<script lang="ts">
import { defineComponent, PropType } from 'vue'

export default defineComponent({
	setup(props) {
		return {}
	},
})
</script>
