import Bugsnag from '@bugsnag/js'
import metamask from '@/util/metamask'
import IERC20 from '@/abi/IERC20.json'
import { wasNotDeclined } from '@/util/Errors'
import IGatewayModule from '@/abi/IGatewayModule.json'

export class TransactionDeclined extends Error {
	constructor() {
		super('User declined transaction')
	}
}

export class TransactionFailed extends Error {
	constructor() {
		super('Transaction failed unexpectedly')
	}
}

export interface AuthedTransaction {
	network_id: number | string
	safe: string
	gateway: string
	destination: string
	transaction: string
	expiration: string
	identifier: string
	counter: string
	salt: string
	signature: string
}

async function requestChain(chainId): Promise<boolean> {
	return await metamask.requestChain(chainId).then(async (res) => {
		return res === true
	})
}

class Transaction {
	network: number
	contract: string

	constructor(network: number, contract: string) {
		this.network = network
		this.contract = contract
	}

	async assertChain() {
		if (metamask.state.chain !== this.network) {
			let swap = await requestChain(this.network)

			if (false == swap) {
				throw new Error('Failed to switch to the correct chain to execute transaction.')
			}
		}
	}
}

class Deposit extends Transaction {
	async execute(to: string, amt: BigInt): Promise<any> {
		await this.assertChain()

		const tx = await this.buildTx(to, amt)

		let gasEstimate
		try {
			gasEstimate = await tx.estimateGas({
				from: metamask.state.wallet,
				gas: 500_000,
			})
		} catch (e) {
			console.error('failed to estimate gas', { error: e, metamask: metamask.state })
			throw new Error('Failed to deposit, gas estimation failed.')
		}

		gasEstimate = Math.ceil(gasEstimate * 1.3)

		let response = new Promise(async (resolve, reject) => {
			let confirmed = false
			await tx
				.send({
					from: metamask.state.wallet,
					gas: gasEstimate,
					maxPriorityFeePerGas: null,
					maxFeePerGas: null,
				})
				.on('receipt', async (r) => {
					// receipt fires when receipt is available
					// not when tx is confirmed
					// resolve(r)
				})
				.on('confirmation', (c, receipt) => {
					if (c == 1) {
						confirmed = true
						console.log('receipt', receipt)
						resolve(receipt)
					}
				})
				.catch((err: Error) => {
					if (wasNotDeclined(err)) {
						Bugsnag.notify(err)
						reject(new TransactionFailed())
						return
					}

					reject(new TransactionDeclined())
				})
		})

		return await response
	}

	async buildTx(to: string, amt: BigInt): Promise<any> {
		let contract = await metamask.loadContract(IERC20, this.contract)
		const tx = await contract.methods.transfer(to, amt.toString())

		return tx
	}
}

class Withdraw extends Transaction {
	async execute(authed: AuthedTransaction): Promise<any> {
		await this.assertChain()

		const tx = await this.buildTx(authed)

		let gasEstimate: any
		try {
			gasEstimate = await tx.estimateGas({
				from: metamask.state.wallet,
				gas: 500_000,
			})
		} catch (e) {
			console.error('failed to estimate gas', { error: e, metamask: metamask.state })
			throw new Error('Failed to deposit, gas estimation failed.')
		}

		gasEstimate = Math.ceil(gasEstimate * 1.3)

		let response = new Promise(async (resolve, reject) => {
			let confirmed = false
			await tx
				.send({
					from: metamask.state.wallet,
					gas: gasEstimate,
					maxPriorityFeePerGas: null,
					maxFeePerGas: null,
				})
				.on('receipt', async (r) => {
					// receipt fires when receipt is available
					// not when the tx is confirmed
					// resolve(r)
				})
				.on('confirmation', (c, receipt) => {
					if (c == 1) {
						confirmed = true
						console.log('receipt', receipt)
						resolve(receipt)
					}
				})
				.catch((err: Error) => {
					if (wasNotDeclined(err)) {
						Bugsnag.notify(err)
						reject(new TransactionFailed())
						return
					}

					reject(new TransactionDeclined())
				})
		})

		return await response
	}

	async buildTx(authed: AuthedTransaction): Promise<any> {
		let contract = await metamask.loadContract(IGatewayModule, this.contract)

		const tx = await contract.methods.execute(
			authed.safe,
			authed.destination,
			authed.transaction,
			false,
			authed.expiration,
			authed.identifier,
			authed.counter,
			authed.salt,
			authed.signature,
		)

		return tx
	}
}

async function deposit(network: number, contract: string, to: string, amt: BigInt): Promise<any> {
	let deposit = new Deposit(network, contract)
	let response = await deposit.execute(to, amt)

	return response
}

async function withdraw(authed: AuthedTransaction): Promise<any> {
	let withdraw = new Withdraw(parseInt(String(authed.network_id)), authed.gateway)
	let response = await withdraw.execute(authed)

	return response
}

export default {
	deposit,
	withdraw,
	errors: {
		TransactionDeclined,
		TransactionFailed,
	},
}
