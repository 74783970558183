import { ref } from 'vue'
import { defineStore } from 'pinia'
import { useAuthStore } from './AuthStore'
import api from '@/util/api'

function isAssetId(assetId: string) {
	let regex = /^\d+:0x[0-9a-fA-F]{40}:\d+$/i
	return regex.test(assetId)
}

export const useUserFavoriteStore = defineStore('userFavorite', () => {
	const authStore = useAuthStore()
	const state = ref({
		assets: [] as string[],
		storefronts: [] as string[],
	})

	function isAssetFavorited(assetId: string): boolean {
		return state.value.assets.includes(assetId)
	}

	async function favoriteAsset(isFavoriting: boolean, assetId: string) {
		if (isFavoriting) {
			const res = await api.createUserFavorite(assetId)
			if (res.status === 201) {
				state.value.assets.push(assetId)
			}
		} else {
			const res = await api.deleteUserFavorite(assetId)
			if (res.status === 204) {
				state.value.assets = state.value.assets.filter((fav) => fav !== assetId)
			}
		}
	}

	return {
		isAssetFavorited,
		favoriteAsset,
		state,
	}
})
