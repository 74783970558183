<template>
	<div class="text-center text-slate-500 my-6 mx-6">
		<p class="text-xl leading-normal">
			You must agree to the BPX Collective
			<a href="https://blokpax.com/terms-of-service" class="href-primary" target="_blank">Terms of Use</a>
			before continuing.
		</p>
		<p>
			<router-link to="/" class="btn-primary-lg inline-block my-6"> Back to Home </router-link>
		</p>
	</div>
</template>
<script lang="ts">
import { defineComponent, PropType } from 'vue'

export default defineComponent({
	setup(props) {
		return {}
	},
})
</script>
