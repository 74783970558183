<template>
	<section class="">
		<div class="grid grid-cols-1 gap-6">
			<div class="rounded-lg border border-gray-200 bg-gray-50">
				<h2 class="text-lg font-medium leading-6 text-gray-900 inline-flex px-6 py-3 pt-4 items-center">
					<slot name="header">
						<TagIcon class="stroke-2 mr-2 h-6 w-6 text-gray-900" />
						Listings
					</slot>
				</h2>
				<div class="flex flex-col text-sm text-gray-500">
					<div class="inline-block min-w-full align-middle">
						<div class="overflow-y-scroll h-80 max-h-80 min-h-[12rem] border-t border-gray-200 space-y-2">
							<div v-if="props.listings.length == 0" class="text-center mt-6">
								<svg
									class="mx-auto h-16 w-16 text-gray-400"
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
									aria-hidden="true"
								>
									<WalletIcon class="stroke-1" />
								</svg>
								<h3 class="mt-2 text-sm font-normal text-gray-900">No listings yet</h3>
							</div>
							<div v-else>
								<div
									class="sticky top-0 left-0 right-0 bg-gray-100 shadow mb-2 grid grid-cols-[3fr,1fr,3fr,3fr,2fr] font-medium text-gray-900 pl-6 px-2 py-2 border-b border-gray-200 items-start space-x-1"
								>
									<p class="">Price</p>
									<p class="">Qty.</p>
									<p class="">Expiration</p>
									<p class="">Seller</p>
									<p class="">&nbsp;</p>
								</div>
								<div
									class="grid grid-cols-[3fr,1fr,3fr,3fr,2fr] pl-6 px-2 py-2 border-b border-gray-200 items-start space-x-1"
									v-for="listing in props.listings"
									:key="`listing-${listing.id}`"
								>
									<p class="flex-grow">
										<span class="whitespace-nowrap text-gray-600"
											>{{ $format(humanReadablePrice(listing.price * listing.quantity)) }}
											{{ $token }}</span
										>
									</p>
									<p class="flex-shrink">
										{{ listing.quantity ?? 1 }}
									</p>
									<p class="">
										{{ DateTime.fromISO(listing.expires_at).toFormat('D') }}
										<br />
										{{ DateTime.fromISO(listing.expires_at).toFormat('ttt') }}
									</p>
									<p class="flex-grow overflow-hidden">
										<span class="whitespace-nowrap text-overflow-none text-ellipsis">{{
											listing?.owner?.username ?? listing.seller_blokpax_id
										}}</span>
									</p>
									<div class="text-right self-stretch">
										<p
											v-if="userID && listing.owner && userID == listing.owner.id"
											class="text-right h-full"
										>
											<router-link
												custom
												v-slot="{ navigate }"
												:to="{
													name: 'edit-asset-listing',
													params: {
														slug: props.storefront.slug,
														assetId: props.asset.id,
														listingId: listing.id,
													},
												}"
											>
												<button
													@click="navigate"
													class="text-gray-400 h-full px-4 py-1 rounded-lg hover:text-slate-800 hover:bg-gray-200 hover:shadow space-x-2"
												>
													<span><i class="fa fa-pencil"></i></span>
													<span>Edit</span>
												</button>
											</router-link>
										</p>
										<p v-else-if="userID" class="h-full">
											<button
												class="btn-primary h-full whitespace-nowrap"
												@click="$emit('buy', listing)"
											>
												Buy Now
											</button>
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>
<script lang="ts">
import { defineComponent, PropType, computed } from 'vue'
import { TagIcon, WalletIcon } from '@heroicons/vue/24/outline'
import { Asset, Listing } from '@/types/Asset'
import { Storefront } from '@/types/Storefront'
import { DateTime } from 'ts-luxon'
import { useExchangeStore } from '@/stores/ExchangeStore'
import { useAuthStore } from '@/stores/AuthStore'
import ToUsd from '@/components/ToUsd.vue'
import Currency from '@/types/Currency'
import { humanReadablePrice } from '@/util/currencyFormat'

export default defineComponent({
	components: { TagIcon, WalletIcon, ToUsd },
	emits: ['buy'],
	props: {
		asset: {
			required: true,
			type: Object as PropType<Asset>,
		},
		listings: {
			required: true,
			type: [] as PropType<Listing[]>,
		},
		storefront: {
			required: true,
			type: Object as PropType<Storefront>,
		},
	},
	setup(props) {
		const exchStore = useExchangeStore()
		const authStore = useAuthStore()

		const userID = computed(() => {
			return authStore.user?.id ?? null
		})

		return {
			Currency,

			props,
			exchStore,
			authStore,
			DateTime,
			userID,

			humanReadablePrice,
		}
	},
})
</script>
