<template>
	<modal>
		<template #default="handlers">
			<div class="bg-white rounded-xl pt-4 overflow-hidden drop-shadow-md w-full">
				<h1 class="text-2xl font-semibold text-left font-poppins px-6">Claim Active Drips</h1>
				<div class="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
					<button
						@click.stop="handlers.close()"
						type="button"
						class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2"
					>
						<svg
							class="h-6 w-6"
							fill="none"
							viewBox="0 0 24 24"
							stroke-width="1.5"
							stroke="currentColor"
							aria-hidden="true"
						>
							<path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
						</svg>
					</button>
				</div>
				<div>
					<div>
						<p
							v-if="claimStatus && claimStatus.status == 'error'"
							class="mt-4 px-6 py-2 text-red-900 text-sm tracking-tight text-center bg-red-200"
						>
							An error occurred while trying to claim your drips. Please try again.
							<br />
							Contact
							<a
								class="text-blue-700 underline"
								target="_blank"
								:href="`mailto:help@blokpax.com?subject=Batch+Claim+Issue+[${claimStatus.claim_id}]`"
								>help@blokpax.com</a
							>
							if this error persists.
						</p>
						<p class="px-6 py-4" v-if="status == 'confirm'">
							Are you sure you want to claim all your active drips?
						</p>
						<p class="px-6 py-4" v-else-if="status == 'pending'">Claiming drips...</p>
						<div
							v-if="status == 'confirm' || status == 'pending'"
							class="px-6 py-4 flex items-center space-x-4 text-center"
						>
							<div>
								<p class="text-xl tracking-tighter">
									{{ $format(currencyToDecimal(props.totals.current_value)) }}
								</p>
								<p class="text-gray-500 tracking-tight text-sm">Claimable Miles</p>
							</div>
							<div>
								<p class="text-xl tracking-tighter">
									{{ $format(props.totals.claimable_count) }}
								</p>
								<p class="text-gray-500 tracking-tight text-sm">Claimable Drips</p>
							</div>
						</div>
						<div v-else-if="status == 'complete'" class="px-6 py-4">
							<p v-if="claimStatus.status == 'complete'" class="text-green-600 tracking-tight text-xl">
								Your drips have been claimed successfully!
							</p>
							<p v-else>{{ claimStatus.message }}</p>
							<div
								v-if="claimStatus.status == 'complete'"
								class="mt-4 flex items-center space-x-4 text-center"
							>
								<div>
									<p class="text-xl tracking-tighter">
										{{ $format(currencyToDecimal(claimStatus.claimed_miles)) }}
									</p>
									<p class="text-gray-500 tracking-tight text-sm">Claimed Miles</p>
								</div>
								<div>
									<p class="text-xl tracking-tighter">
										{{ $format(claimStatus.claims.length) }}
									</p>
									<p class="text-gray-500 tracking-tight text-sm">Claimed Drips</p>
								</div>
							</div>
						</div>
					</div>
					<div class="mt-4 border-t border-gray-200 bg-gray-100 shadow-sm">
						<div class="flex justify-end items-center border-t border-gray-200 px-4 py-4 sm:px-6 space-x-3">
							<button
								@click="handlers.close()"
								v-if="status == 'confirm' || status == 'pending'"
								:disabled="status == 'complete'"
								class="text-gray-500 px-3 py-2"
							>
								<span v-if="status == 'confirm'">Cancel</span>
								<span v-if="status == 'pending'">Close</span>
							</button>
							<button
								v-if="status == 'confirm' && props.totals.claimable_count > 0"
								@click="executeClaim"
								type="button"
								class="btn-light-lg"
							>
								Claim All
							</button>
							<button v-if="status == 'pending'" disabled type="button" class="btn-light-lg">
								Claiming
								<i class="fa-sharp fa-solid fa-spinner-scale fa-spin"></i>
							</button>
							<button
								v-if="
									(status == 'complete' && claimStatus.status == 'complete') ||
									(status == 'confirm' && props.totals.claimable_count == 0)
								"
								@click="handlers.close()"
								class="btn-light-lg"
							>
								Close
							</button>
						</div>
					</div>
				</div>
			</div>
		</template>
	</modal>
</template>
<script lang="ts" setup>
import { onMounted, onUnmounted, ref } from 'vue'
import Modal from '@/components/Modal.vue'
import { AccountDripIndexResponse, BatchClaimStatusResponse, BatchDripClaimNotification } from '@/types/Drip'
import { currencyToDecimal } from '@/util/currencyFormat'
import { useAccountStore } from '@/stores/AccountStore'
import { useEchoChannelStore } from '@/stores/EchoChannelStore'
import { useAuthStore } from '@/stores/AuthStore'
import Bugsnag from '@bugsnag/js'

const authStore = useAuthStore()
const accountStore = useAccountStore()
const echoStore = useEchoChannelStore()
const BatchClaimCompleteEvent = '.App\\Services\\Drip\\Events\\BatchClaimComplete'

const props = defineProps<{
	totals: AccountDripIndexResponse
}>()

const emit = defineEmits(['claimed', 'error'])

const status = ref<'confirm' | 'pending' | 'complete'>('confirm')
const claimStatus = ref<null | BatchClaimStatusResponse>(null)
const err = ref<string | null>(null)

onUnmounted(() => {
	killTimeoutForManualUpdate()
	echoStore.echo.private(`user.${authStore.user.id}`).stopListening(BatchClaimCompleteEvent)
})

let timer = null
function initTimeoutForManualUpdate(batchId: string) {
	timer = setInterval(() => {
		checkStatus(batchId)
	}, 10_000) // 10 second wait
}

function killTimeoutForManualUpdate() {
	clearInterval(timer)
}

async function checkStatus(batchId: string) {
	const result = await accountStore.batchStatus(batchId)

	if (result && result.status == 'complete') {
		claimStatus.value = result
		onClaimComplete(true)
	} else if (result && result.status == 'error') {
		claimStatus.value = result
		onClaimComplete(false)
	}
}

async function executeClaim() {
	status.value = 'pending'
	err.value = null
	claimStatus.value = null
	killTimeoutForManualUpdate()

	const claimResult = await accountStore.claimDrips(props.totals.drips.map((d) => d.asset_id))

	if (!claimResult.success) {
		status.value = 'confirm'
		err.value = 'Failed to execute claim.'
		return
	}

	claimStatus.value = { id: claimResult.batch_id } as BatchClaimStatusResponse

	listenForClaimEvent(claimResult.batch_id)
	initTimeoutForManualUpdate(claimResult.batch_id)
}

function listenForClaimEvent(batchId: string) {
	echoStore.echo
		.private(`user.${authStore.user.id}`)
		.listen(BatchClaimCompleteEvent, (ev: BatchDripClaimNotification) => {
			if (ev.id == batchId) {
				checkStatus(ev.id)
			}
		})
}

function onClaimComplete(success: boolean) {
	killTimeoutForManualUpdate()

	if (success) {
		status.value = 'complete'
		echoStore.echo.private(`user.${authStore.user.id}`).stopListening(BatchClaimCompleteEvent)
		emit('claimed')
	} else {
		status.value = 'confirm'
		Bugsnag.notify(new Error(`Batch claim failed: ${claimStatus.value.id} - ${claimStatus.value.message}`))
		console.error('Batch claim failed:', claimStatus.value.id, claimStatus.value.message)
		emit('error')
	}
}
</script>
