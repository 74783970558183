<template>
	<div class="gap-2 flex flex-wrap whitespace-nowrap">
		<div
			v-if="props.query.length"
			class="bg-gray-200/80 text-gray-900 rounded-lg px-4 py-3 flex justify-center items-center space-x-2"
		>
			<p class="leading-5 font-medium">{{ props.query }}</p>
			<button @click="$emit('clearQuery', props.query)"><i class="fa fa-fw leading-none fa-times" /></button>
		</div>
		<div
			v-for="filter in flatActiveFilters"
			:key="`${filter.key}:${filter.val}`"
			class="bg-gray-200/80 text-gray-900 rounded-lg px-4 py-3 flex justify-center items-center space-x-2"
		>
			<span class="leading-5 font-medium text-gray-600">{{ filter.key }}:</span>
			<span class="leading-5 font-medium">{{ filter.val }}</span>
			<button @click="$emit('removeFilter', filter.key, filter.val)">
				<i class="fa fa-fw leading-none fa-times" />
			</button>
		</div>
		<button
			@click="$emit('clearAllFilters')"
			v-if="flatActiveFilters.length > 1"
			class="bg-gray-200/80 hover:bg-gray-300/80 text-gray-900 rounded-lg px-4 py-3 flex justify-center items-center space-x-2"
		>
			<span class="leading-5 font-medium">Clear all {{ flatActiveFilters.length }}</span>
		</button>
		<slot name="default" />
	</div>
</template>
<script lang="ts">
import { defineComponent, PropType, computed } from 'vue'

export default defineComponent({
	emits: ['clearQuery', 'removeFilter', 'clearAllFilters'],
	props: {
		query: {
			required: false,
			type: [String, null],
			default: () => '',
		},
		filters: {
			required: false,
			type: Object,
			default: () => ({}),
		},
	},
	setup(props) {
		const flatActiveFilters = computed(() => {
			return Object.keys(props.filters).reduce((filters, key) => {
				const keyFilters = props.filters[key].map((val) => {
					return { key, val }
				})

				filters.push(...keyFilters)
				return filters
			}, [])
		})

		return {
			props,
			flatActiveFilters,
		}
	},
})
</script>
