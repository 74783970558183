import { defineStore } from 'pinia'
import api from '@/util/api'
import { Flags, Flag } from '@/types/Flags'
import { useAuthStore } from './AuthStore'

export const useFlagStore = defineStore('flagStore', () => {
	const authStore = useAuthStore()

	function setup() {
		return {
			// actions
			checkFlag,
			check,
		}
	}

	function checkFlag(flag: Flag): boolean {
		if (authStore.user?.flags?.market) {
			return check(authStore.user.flags.market, flag)
		}

		return check([], flag)
	}

	function check(flags: string[], feature: Flag): boolean {
		if (flags.length && flags.indexOf(feature.flag) !== -1) {
			return true
		}

		return feature.public() ?? false
	}

	return setup()
})
