<template>
	<p>Your bid succeeded, but you are no longer the high bidder!</p>
</template>
<script lang="ts">
import { defineComponent, PropType } from 'vue'

export default defineComponent({
	setup(props) {
		return {}
	},
})
</script>
