import BigNumber from 'bignumber.js'

export interface ExchangeRate {
	exchangeRate: string | number
	usd: string | number | null
	bpx: string | null
	bpx_dec: string | number | null
}

export interface Options {
	showDecimals?: boolean | null
	decimals?: number | null
	commify?: boolean | null
	pad?: boolean | null
}

export const CURRENCY_TYPE_BPX = 'BPX'
export const CURRENCY_TYPE_USD = 'USD'
export const CURRENCY_TYPE_MILES = 'Miles'

export default class Currency {
	protected val: BigNumber
	protected unit: BigNumber
	protected decimals: number = 9
	protected opts: Options | null

	constructor(val: BigNumber.Value, opts: Options = {}) {
		this.val = new BigNumber(val)

		this.opts = opts || {
			decimals: 9,
		}

		if (opts) {
			this.decimals = opts.decimals || 9
		}

		this.unit = new BigNumber(1).multipliedBy(new BigNumber(10).exponentiatedBy(this.decimals))
	}

	add(value: BigNumber.Value): Currency {
		return new Currency(this.val.plus(value), this.opts)
	}

	mul(value: BigNumber.Value): Currency {
		return new Currency(this.val.times(value), this.opts)
	}

	sub(value: BigNumber.Value): Currency {
		return new Currency(this.val.minus(value), this.opts)
	}

	toDecimal(opts: Options | null = null): string {
		const options =
			opts ||
			({
				showDecimals: true,
			} as Options)

		if (options.showDecimals) {
			return this.val.dividedBy(this.unit).toFixed(this.decimals, BigNumber.ROUND_FLOOR)
		}

		return this.val.dividedBy(this.unit).toFixed(0, BigNumber.ROUND_FLOOR)
	}

	static fromDecimal(num: number | string, decimals: number = 9): Currency {
		const unit = new BigNumber(10).pow(decimals)
		const val = new BigNumber(num).multipliedBy(unit)
		return new Currency(val)
	}

	toString() {
		return this.val.toString()
	}
}
