<template>
	<wallet-connected>
		<template #default="{ connect }">
			<div class="mt-8">
				<p class="text-balance text-gray-600">
					In order to swap Miles or BPX you will need to connect a blockchain compatible wallet. Eg. Metamask
					or Coinbase Wallet
				</p>

				<button
					class="py-2 px-4 my-4 block w-full text-xl enabled:hover:bg-blue-600 enabled:bg-blue-400 enabled:text-white text-center rounded-lg transition-all"
					@click="connect"
				>
					Connect Wallet
				</button>
			</div>
		</template>
		<template #connected="{ wallet }">
			<div>
				<slot name="default" :currency="currency" :swap="swap" :wallet="wallet" />
			</div>
		</template>
	</wallet-connected>
</template>
<script lang="ts" setup>
import { watch, onMounted, onUnmounted, ref, computed } from 'vue'
import exchange from '@/util/exchange'
import { BPX_ADDRESS, BPX_CHAIN } from '@/util/escrow'
import { useNewWalletStore as useWalletStore } from '@/stores/NewWalletStore'
import { useChainWalletStore } from '@/stores/ChainWalletStore'
import Bugsnag from '@bugsnag/js'

const walletStore = useWalletStore()
const chainWalletStore = useChainWalletStore()

const ENV = import.meta.env.VITE_ENV
const ON_CHAIN_CURRENCY_NETWORK = ENV == 'prod' || ENV == 'production' ? BPX_CHAIN : 137
const ON_CHAIN_CURRENCY_ADDRESS =
	ENV == 'prod' || ENV == 'production' ? BPX_ADDRESS : '0xA408Fb3F05cE459581a971fdAD21154c6097C45F'

const $emit = defineEmits([
	'deposit',
	'deposit:init',
	'deposit:error',
	'deposit:completed',

	'withdraw',
	'withdraw:init',
	'withdraw:error',
	'withdraw:completed',
])

const ETH_SAFE_ADDRESS = import.meta.env.VITE_ETH_SAFE_ADDRESS
const currency = computed(() => {
	return {
		BPX: {
			symbol: 'BPX',
			balance: bpxBalance.value,
			available: bpxBalance.value,
		},

		Miles: {
			symbol: 'Miles',
			available: walletStore.withdrawableBpx.toString(),
			balance: walletStore.visibleBpx.toString(),
		},
	}
})
let interval: any
const bpxBalance = ref('0')
const chainWallet = ref(null)

onMounted(() => {
	updateOnChainBalance().then(() => {
		monitorOnChainBalance()
	})
})

onUnmounted(() => {
	clearInterval(interval)
})

watch(
	() => chainWalletStore.wallet,
	() => {
		if (chainWalletStore.wallet.toUpperCase() != chainWallet.value?.toUpperCase()) {
			chainWallet.value = chainWalletStore.wallet
			updateOnChainBalance()
		}
	},
)

async function updateOnChainBalance() {
	return chainWalletStore
		.getBalance(chainWalletStore.wallet, ON_CHAIN_CURRENCY_NETWORK, ON_CHAIN_CURRENCY_ADDRESS)
		.then((balance) => {
			bpxBalance.value = balance.toString()
		})
}

async function monitorOnChainBalance() {
	interval = setTimeout(() => {
		updateOnChainBalance().then(() => {
			monitorOnChainBalance()
		})
	}, 10_000)
}

type SwapCurrency = {
	symbol: string
	amount: string
}

function cleanInput(input: string | number): number {
	const clean = input.toString().replace(/[^0-9.]/g, '')

	// if multiple decimal points, only keep the first
	const parts = clean.split('.')
	if (parts.length > 1) {
		return Number(parts[0] + '.' + parts.slice(1).join(''))
	}

	return Number(clean)
}

function toInt256(value: string): BigInt {
	const parts = cleanInput(value).toString().split('.')
	const integralPart = BigInt(parts[0]) * BigInt(1e9)
	const truncated = BigInt(parts[1] ? parts[1].slice(0, 9).padEnd(9, '0') : '0')
	const decimalPart = BigInt(truncated)
	return integralPart + decimalPart
}

async function swap(fromCurrency: SwapCurrency, toCurrency?: SwapCurrency, wallet?: string) {
	if (fromCurrency.symbol == 'BPX') {
		return doDeposit(toInt256(fromCurrency.amount))
	}

	if ([toCurrency, wallet].indexOf(undefined) > -1) {
		console.error('Invalid swap parameters')
		return
	}

	return doWithdrawal(wallet, toInt256(toCurrency.amount))
}

async function doDeposit(amount: BigInt) {
	$emit('deposit', 'init')
	$emit('deposit:init')

	try {
		let result = await exchange.deposit(
			ON_CHAIN_CURRENCY_NETWORK,
			ON_CHAIN_CURRENCY_ADDRESS,
			ETH_SAFE_ADDRESS,
			amount,
		)

		if (result) {
			$emit('deposit', 'completed', result)
			$emit('deposit:completed', result)
		}
	} catch (error) {
		if (false == error instanceof exchange.errors.TransactionDeclined) {
			console.log(error)
			// Bugsnag.notify(error)

			$emit('deposit', 'error', error)
			$emit('deposit:error', error)
			return
		}

		$emit('deposit', 'completed', false)
		$emit('deposit:completed', false)
		return
	}

	return
}

async function doWithdrawal(dest: string, amount: BigInt) {
	$emit('withdraw', 'init')
	$emit('withdraw:init')

	let authed
	try {
		authed = await walletStore.withdraw(`${BPX_CHAIN}:${BPX_ADDRESS}:0`, amount.toString(), dest)
	} catch (error) {
		console.log(error)
		Bugsnag.notify(error)

		$emit('withdraw', 'error', error)
		$emit('withdraw:error', error)
		return
	}

	try {
		let result = await exchange.withdraw(authed)

		if (result) {
			$emit('withdraw', 'completed', result)
			$emit('withdraw:completed', result)
		}
	} catch (error) {
		if (false == error instanceof exchange.errors.TransactionDeclined) {
			console.log(error)
			// Bugsnag.notify(error)

			$emit('withdraw', 'error', error)
			$emit('withdraw:error', error)
			return
		}

		$emit('withdraw', 'completed', false)
		$emit('withdraw:completed', false)
	}
}
</script>
