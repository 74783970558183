export function cdnURL(url: string): string {
	if (url.includes('https://s3.amazonaws.com/auction-assets.blokpax.com/')) {
		return url.replace(
			'https://s3.amazonaws.com/auction-assets.blokpax.com/',
			'https://auction-assets.blokpax.com/',
		)
	}

	return url
}
