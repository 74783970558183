<template>
	<fade-transition>
		<dialog ref="dialog" v-bind="$attrs" class="rounded-xl bg-white shadow">
			<slot name="default" :showDialog="showDialog" :closeDialog="closeDialog" />
		</dialog>
	</fade-transition>
</template>
<script lang="ts" setup>
import FadeTransition from '@/components/widgets/FadeTransition.vue'
import { ref } from 'vue'
const emit = defineEmits(['close'])
const dialog = ref()
const showDialog = () => dialog.value.showModal()
const closeDialog = () => {
	emit('close')
	dialog.value.close()
}
defineExpose({ showDialog, closeDialog })
</script>
