import { useRoute } from 'vue-router'
import api from '@/util/api'
import { Asset } from '@/types/Asset'
import { useAuthStore } from '@/stores/AuthStore'

export async function useAsset(slug?: string, assetId?: number, user?): Promise<Asset> {
	const route = useRoute()
	const authStore = useAuthStore()
	const id = assetId || (+route.params.assetId as number)
	const storefront = slug || (route.params.slug as string)

	await authStore.login()

	const asset = await api.loadAsset(storefront, id)

	return asset
}
