<template>
	<div class="border mt-4 py-4 bg-gray-100 p-4 rounded-lg">
		<div class="flex items-center justify-center space-x-4 mb-4">
			<i class="text-4xl fa-solid fa-mobile-screen-button"></i>
			<p>
				Enter the 6-digit code from
				<span v-if="props.isTotp">your authenticator app</span>
				<span v-else>the SMS message sent to the phone number associated with your account.</span>
			</p>
		</div>

		<div class="w-full flex justify-center space-x-2" @input="handleTotpInput">
			<template v-for="field in fields" :key="field">
				<input
					v-model="data[field - 1]"
					ref="firstInputEl"
					type="text"
					maxlength="1"
					class="border rounded p-0 w-9 h-9 md:w-12 md:h-12 text-center md:text-xl disabled:bg-slate-50 disabled:opacity-50"
					@paste="field === 1 && handlePaste($event)"
					:disabled="disabled"
				/>
			</template>
		</div>

		<p v-if="props.errorMsg" class="text-red-500 text-sm mt-2 text-center">{{ props.errorMsg }}</p>
	</div>
</template>

<script lang="ts" setup>
import { ref, watch, withDefaults } from 'vue'

const props = withDefaults(
	defineProps<{
		fields: number
		disabled: boolean
		isTotp: boolean
		errorMsg: string | null
	}>(),
	{
		fields: 6,
		disabled: false,
		isTotp: false,
		errorMsg: null,
	},
)

const data = ref<any[]>([])
const firstInputEl = ref(null)
const emit = defineEmits(['totp-value'])

watch(
	() => data,
	(newVal) => {
		if (newVal.value.join('') !== '' && newVal.value.length === props.fields && !newVal.value.includes('')) {
			emit('totp-value', newVal.value.join(''))
		} else {
			emit('totp-value', '')
		}
	},
	{ deep: true },
)

const handleTotpInput = (e) => {
	if (e.data && e.target.nextElementSibling) {
		e.target.nextElementSibling.focus()
	} else if (e.data == null && e.target.previousElementSibling) {
		e.target.previousElementSibling.focus()
	}
}

const handlePaste = (e) => {
	const pasteData = e.clipboardData.getData('text')
	let nextEl = firstInputEl.value[0].nextElementSibling
	for (let i = 1; i < pasteData.length; i++) {
		if (nextEl) {
			data.value[i] = pasteData[i]
			nextEl = nextEl.nextElementSibling
		}
	}
}

function clearInputs() {
	for (let i = 0; i < data.value.length; i++) {
		data.value[i] = ''
	}
}

defineExpose({
	clearInputs,
})
</script>
