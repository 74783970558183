import Currency, { Options } from '@/types/Currency'
import BigNumber from 'bignumber.js'

export default function currencyFormat(val: BigNumber.Value, forceDecimals: boolean = false): string {
	const num = new BigNumber(val)

	return new Intl.NumberFormat('en-US', {
		minimumIntegerDigits: 1,
		minimumFractionDigits: 2,
		style: 'decimal',
		trailingZeroDisplay: !forceDecimals ? 'stripIfInteger' : 'auto',
	}).format(num.toNumber())
}

export function humanReadablePrice(amt: string | number) {
	return new Currency(String(amt)).toDecimal()
}

export function currencyToDecimal(amt: BigNumber.Value, opts: Options | null = null): string {
	const c = new Currency(amt)
	return c.toDecimal(opts)
}
