import { defineStore } from 'pinia'
import algoliasearch from 'algoliasearch'

const ALGOLIA_APP_ID = import.meta.env.VITE_ALGOLIA_APP_ID
const ALGOLIA_SEARCH_KEY = import.meta.env.VITE_ALGOLIA_SEARCH_KEY

export const useUserProfileStore = defineStore('userProfile', () => {
	const searchClient = algoliasearch(ALGOLIA_APP_ID, ALGOLIA_SEARCH_KEY)

	const userIndex = searchClient.initIndex('user')

	return {
		searchClient,
		userIndex,
	}
})
