import { ethers } from 'ethers'
import { DateTime } from 'ts-luxon'
import Web3 from 'web3/dist/web3.min.js'

interface CachedAddress {
	name: string
	address: string
	expires: number
}

function hashItem(address: string) {
	return Web3.utils.keccak256(address.toLowerCase())
}

function readFromLocalStorage(address: string): CachedAddress | boolean {
	const hash = hashItem(address)
	const cachedItem = window.localStorage.getItem(hash)

	if (cachedItem) {
		try {
			const cached = JSON.parse(cachedItem)
			if (DateTime.fromSeconds(cached.expires) > DateTime.now()) {
				return cached
			}
		} catch (e) {
			// don't care
		}
	}

	return false
}

function writeToLocalStorage(toCache: CachedAddress) {
	const hash = hashItem(toCache.address)
	window.localStorage.setItem(hash, JSON.stringify(toCache))
}

export default function useEns(providerUrl) {
	const memoized = {} as any
	async function lookupEns(address: string): string | boolean {
		address = address.toLowerCase()
		try {
			if (undefined == memoized[address]) {
				const provider = new ethers.JsonRpcProvider(providerUrl)
				const name = await provider.lookupAddress(address)

				if (!name) {
					return false
				}

				memoized[address] = name
			}

			return memoized[address]
		} catch (e) {
			// no carey
			console.error(e)
		}

		return false
	}

	async function reverse(address: string): string | boolean {
		let addressInfo = readFromLocalStorage(address)

		if (!addressInfo) {
			const name = await lookupEns(address)

			if (name) {
				writeToLocalStorage({
					name,
					address,
					expires: Math.floor(DateTime.now().plus({ seconds: 3600 }).toSeconds()),
				})

				return name
			}

			return false
		}

		return addressInfo.name
	}

	return {
		reverse,
		resolved: memoized,
	}
}
