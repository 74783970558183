<template>
	<Disclosure as="div" v-slot="{ open }" :defaultOpen="props.open" class="border border-gray-200">
		<h2 class="text-lg font-medium text-gray-900">
			<DisclosureButton
				class="group relative flex w-full items-stretch justify-between p-2 text-left border-gray-200"
				:class="[!open ? '' : 'border-b']"
			>
				<span class="flex items-center text-gray-900">
					<Bars3BottomLeftIcon class="stroke-2 mt-1 mr-2 h-6 w-6" />
					{{ props.name }}
				</span>
				<span class="flex items-center text-gray-400 group-hover:text-gray-600">
					<ChevronDoubleDownIcon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
					<ChevronDoubleUpIcon v-else class="block h-6 w-6" aria-hidden="true" />
				</span>
			</DisclosureButton>
		</h2>
		<DisclosurePanel as="div" class="prose prose-sm pb-6 p-4">
			<slot name="default" />
		</DisclosurePanel>
	</Disclosure>
</template>
<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import { Bars3BottomLeftIcon, ChevronDoubleDownIcon, ChevronDoubleUpIcon } from '@heroicons/vue/24/outline'
export default defineComponent({
	components: {
		Disclosure,
		DisclosureButton,
		DisclosurePanel,
		Bars3BottomLeftIcon,
		ChevronDoubleDownIcon,
		ChevronDoubleUpIcon,
	},
	props: {
		name: {
			required: true,
			type: String,
		},
		open: {
			required: false,
			type: Boolean,
			default: () => false,
		},
	},
	setup(props) {
		return {
			props,
		}
	},
})
</script>
