<template>
	<div>
		<div
			class="text-left rounded-lg group relative py-2 px-4 flex gap-x-4 border-yellow-500 bg-gradient-to-br from-yellow-100 to-yellow-500 hover:from-yellow-200 hover:to-yellow-600"
		>
			<div class="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg --bg-white">
				<img src="/image/blokpax-miles-card-trans.webp" class="" />
			</div>
			<div>
				<router-link
					:to="router.resolve({ name: 'buy-store-credit' })"
					class="font-semibold text-gray-900"
					@click="$emit('close')"
				>
					Get more miles!
					<span class="absolute inset-0" />
				</router-link>
				<p class="text-yellow-900">Buy more reward miles for use in the Blokpax ecosystem.</p>
			</div>
		</div>

		<div class="bg-white rounded-lg flex flex-col mt-4">
			<div
				v-for="item in resources"
				:key="item.name"
				class="group relative flex items-center gap-x-4 hover:bg-slate-200 px-4 py-4 first:rounded-t-lg last:rounded-b-lg"
			>
				<div
					class="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-white group-hover:bg-boba-white"
				>
					<component
						v-if="item.icon"
						:is="item.icon"
						class="h-6 w-6 text-gray-600 group-hover:text-boba-grey-dark"
						aria-hidden="true"
					/>
				</div>
				<div>
					<router-link
						v-if="!item.href.match(/^https?:/i)"
						:to="item.href"
						class="font-semibold text-gray-900"
						@click="$emit('close')"
					>
						{{ item.name }}
						<span class="absolute inset-0" />
					</router-link>
					<a v-else :href="item.href" class="font-semibold text-gray-900">
						{{ item.name }}
						<span class="absolute inset-0" />
					</a>
					<p v-if="item.description" class="text-gray-600 text-balance">
						{{ item.description }}
					</p>
				</div>
			</div>
		</div>

		<div class="bg-white rounded-lg flex flex-col mt-4">
			<div class="rounded-lg group relative flex items-center gap-x-4 hover:bg-slate-200 px-4 py-4 bg-white">
				<div
					class="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-white group-hover:bg-boba-white"
				>
					<CogIcon class="w-6 h-6 text-gray-600 group-hover:text-boba-grey-dark" />
				</div>
				<div>
					<a href="https://id.blokpax.com" class="font-semibold text-gray-900">
						Manage Your Account
						<span class="absolute inset-0" />
					</a>
					<p class="text-gray-600 text-balance">Manage your account settings and preferences.</p>
				</div>
			</div>
		</div>

		<div class="bg-white rounded-lg flex flex-col mt-4">
			<div class="rounded-lg group relative flex items-center gap-x-4 hover:bg-slate-200 px-4 py-2 bg-white">
				<div
					class="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-white group-hover:bg-boba-white"
				>
					<ArrowRightOnRectangleIcon class="w-6 h-6 text-gray-600 group-hover:text-boba-grey-dark" />
				</div>
				<div>
					<a href="/auth/logout" class="font-semibold text-gray-900">
						Sign out
						<span class="absolute inset-0" />
					</a>
				</div>
			</div>
		</div>
	</div>
</template>
<script setup lang="ts">
import { useRouter } from 'vue-router'
import {
	RectangleStackIcon,
	WalletIcon,
	EyeDropperIcon,
	ArrowsRightLeftIcon,
	ArrowRightOnRectangleIcon,
	CogIcon,
	CreditCardIcon,
} from '@heroicons/vue/24/outline'

const emit = defineEmits(['close'])

const router = useRouter()
const resources = [
	{
		name: 'My Packs',
		description: 'Rip your unopened packs',
		href: 'https://app.blokpax.com/',
		icon: RectangleStackIcon,
	},
	{
		name: 'My Collection',
		description: 'View your collection',
		href: router.resolve({ name: 'account' }).href,
		icon: WalletIcon,
	},
	{
		name: 'Drips',
		description: 'View and claim your Reward Miles drips',
		href: router.resolve({ name: 'account:drips' }).href,
		icon: EyeDropperIcon,
	},
	{
		name: 'Transfer Miles',
		description: 'Send miles from your account to another person',
		href: router.resolve({ name: 'bulk-transfer' }).href,
		icon: ArrowsRightLeftIcon,
	},
]
</script>
