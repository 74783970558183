<template>
	<div class="flex items-center py-3 space-x-3 overflow-hidden px-2">
		<div class="aspect-square w-[15%] max-w-[3rem] flex-grow-0 flex-shrink-0">
			<div class="rounded-lg overflow-hidden border border-gray-300 aspect-square">
				<img :src="props.storefront.pfp_image" class="w-full" />
			</div>
		</div>
		<div class="flex-grow overflow-hidden">
			<p class="overflow-hidden sm:whitespace-nowrap text-ellipsis" :title="props.storefront.name">
				{{ props.storefront.name }}
			</p>
		</div>
		<span
			v-if="props.storefront?.isMissing"
			class="inline-flex justify-center items-center px-3 bg-red-600 text-white text-xl aspect-square rounded-full leading-none"
		>
			<i class="fa fa-exclamation"></i>
		</span>
	</div>
</template>
<script lang="ts">
import { Storefront } from '@/types/Storefront'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
	props: {
		storefront: {
			required: true,
			type: Object as PropType<Storefront>,
		},
	},
	setup(props) {
		return {
			props,
		}
	},
})
</script>
