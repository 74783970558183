import { defineStore } from 'pinia'
import metamask from '@/util/metamask'
import IERC20 from '@/abi/IERC20.json'
import Currency from '@/types/Currency'
import Web3 from 'web3/dist/web3.min.js'
import { reactive, computed } from 'vue'

export const useChainWalletStore = defineStore('chainWalletStore', () => {
	const providers = {
		1: new Web3(import.meta.env.VITE_MAINNET_RO_PROVIDER),
		137: new Web3(import.meta.env.VITE_POLY_RO_PROVIDER),
	}

	const state = reactive({
		initialized: false,
		wallet: metamask.state.wallet,
		currentChain: null as string | number | null,
	})

	const wallet = computed(() => metamask.state.wallet)

	function init(force: boolean = false) {
		if (window.ethereum) {
			if (!state.initialized || force) {
				metamask.on('connected', async () => {
					updateChain(await metamask.getCurrentChain())
				})

				metamask.on('chainChanged', (chain) => {
					updateChain(chain)
				})
			}
		}

		return {
			state,
			wallet,
			getBalance,
		}
	}

	function updateChain(chain: string | number) {
		this.currentChain = chain
	}

	async function getBalance(wallet: string, network: number, contract?: string) {
		let erc20: any
		if (contract) {
			erc20 = new providers[network].eth.Contract(IERC20, contract)
		}

		if (!erc20) {
			return await providers[network].eth.getBalance(wallet)
		}

		return await erc20.methods.balanceOf(wallet).call()
	}

	return init()
})
