<template>
	<div class="bg-white rounded-xl drop-shadow-md w-full max-h-[80vh] flex flex-col">
		<div class="p-6 border-b flex justify-between">
			<div class="flex flex-col w-full h-full justify-center items-center">
				<h4 class="text-xl font-bold">You must be logged in to make an offer.</h4>
				<div class="p-4">
					<LoginButton :is-main-nav="false" />
				</div>
			</div>
		</div>
		<div class="bg-gray-100 text-right space-x-6 pt-6 pb-4 px-6 mt-auto rounded-b-xl">
			<button class="btn shadow-none hover:btn-primary text-gray-500" @click.stop="close">Close</button>
		</div>
	</div>
</template>

<script lang="ts" setup>
import LoginButton from '@/components/LoginButton.vue'

const emit = defineEmits<{
	(event: 'close'): void
}>()

function close() {
	emit('close')
}
</script>
