/*
	To Enable/Disable a feature for a specific storefront,
	use this json structure in the "config" column of the storefront table in database.
	```
	{
		"features":[
			"FEATURE_AUCTIONS",
			"FEATURE_OFFERS"
		]
	}
	```

	To enable/disable a feature for a specific user,
	use this json structure in the "flags" column of the blokpax_user table in database.
	```
	{
		"market":[
			"AUCTIONS_CREATE",
			"OFFERS_CREATE"
		]
	}
	```
*/

export class Flag {
	flag: string
	requireAuth: boolean

	constructor(flag: string, requireAuth: boolean = true) {
		this.flag = flag
		this.requireAuth = requireAuth
	}

	public(): boolean {
		return !this.requireAuth
	}
}

export const Flags = {
	// user flags
	AUCTIONS_CREATE: new Flag('AUCTIONS_CREATE', false),
	OFFERS_CREATE: new Flag('OFFERS_CREATE', true),
	OFFERS_ACCEPT: new Flag('OFFERS_ACCEPT', true),

	// storefront flags
	FEATURE_AUCTIONS: new Flag('FEATURE_AUCTIONS', false),
	FEATURE_BATCH_DRIP_CLAIM: new Flag('FEATURE_BATCH_DRIP_CLAIM', false),
	FEATURE_OFFERS: new Flag('FEATURE_OFFERS', true),
}
