import { defineStore } from 'pinia'
import { Auction } from '@/types/Auction'
import { Sorts } from '@/util/AuctionSort'

const versionString =
	import.meta.env.MODE === 'development'
		? import.meta.env.VITE_APP_VERSION + '-dev'
		: import.meta.env.VITE_APP_VERSION

const auctionSorts = new Map<Number, String>()

export const useAppStore = defineStore('app', {
	state: () => ({
		debug: import.meta.env.mode === 'development',
		version: versionString,
		initialized: false,
		tokenSymbol: '$BPX',
		overlay: false,
		mobileOnlyOverlay: false,
		modalStackCount: 0,

		DEFAULT_SORT: Sorts.BID_VALUE_DESC,
	}),

	actions: {
		setAuctionSort(groupId: number, sort: string) {
			auctionSorts.set(groupId, sort)
		},
	},

	getters: {
		auctionSort() {
			return (groupId: number): string => {
				// console.log('getting sort for group', groupId, auctionSorts.get(groupId))
				return auctionSorts.get(groupId) || this.DEFAULT_SORT
			}
		},
	},
})
