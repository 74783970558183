<template>
	<modal class="z-50">
		<template v-if="state.isWaitingOnMetamask" #default>
			<div class="bg-white rounded-xl overflow-hidden drop-shadow-md w-full">
				<div class="p-6">
					<div class="flex flex-col h-full justify-center items-center">
						<h4 class="text-xl font-bold">
							We need to switch networks for "{{ props.asset.rawMetadata.name }}"
						</h4>
						<p class="mb-4">Please follow the prompts in Metamask to switch to the correct network.</p>
						<i class="fa-2xl fa-sharp fa-solid fa-spinner-third fa-spin"></i>
					</div>
				</div>
			</div>
		</template>
		<template v-else #default>
			<div class="bg-white rounded-xl overflow-hidden drop-shadow-md w-full">
				<div class="p-6 grid md:grid-cols-[2fr,3fr] gap-6 items-start">
					<div>
						<img :src="props.asset.rawMetadata.image" class="rounded-md h-48 md:h-auto mx-auto" />
						<div class="font-poppins text-lg text-center mt-2">
							<p>{{ props.asset.rawMetadata.attributes[0].value }}</p>
						</div>
					</div>
					<section class="flex flex-col h-full">
						<div class="flex flex-col h-full">
							<template v-if="!state.success && !state.txInProgress">
								<h4 class="text-2xl font-medium font-poppins">Deposit</h4>
								<div class="mt-4 font-sm">
									<p>
										Deposit "{{ props.asset.rawMetadata.attributes[0].value }}" from
										<wallet-address :address="metamask.state.wallet" />
									</p>
								</div>

								<wallet-connected>
									<template #default>
										<div class="mt-12">
											<button
												class="w-full text-xl font-medium bg-slate-400 text-slate-600 enabled:bg-blue-600 enabled:text-slate-50 enabled:hover:saturate-150 p-4 text-center rounded-lg transition-all"
												@click="metamask.connect"
											>
												Connect Wallet
											</button>
										</div>
									</template>

									<template #connected>
										<div
											v-if="!isOnCorrectNetwork"
											class="mt-6 bg-red-200 rounded-xl px-4 py-4 text-black-600 space-y-2 border border-red-400"
										>
											<p class="font text-2xl">
												<span
													class="inline-flex justify-center items-center px-3 bg-red-600 text-white text-xl aspect-square rounded-full leading-none"
												>
													<i class="fa fa-exclamation"></i>
												</span>
												Wrong Network detected
											</p>
											<p class="text-base">
												Depositing is disabled if you are not on the correct network to prevent
												accidentally losing assets.
											</p>
											<button class="btn-primary-lg" @click="changeNetworks">
												Change networks now
											</button>
										</div>
										<div v-else>
											<div class="text-center bg-slate-200 text-slate-800 py-4 rounded-lg my-6">
												<div class="text-base font-bold">You are depositing to:</div>
												<div class="text-lg font-mono">
													<wallet-address :address="safeAddress" />
												</div>
											</div>
											<div class="mt-4">
												<div class="flex items-center justify-end space-x-2">
													<div class="flex-grow text-right">
														<p>Quantity:</p>
														<p class="text-sm text-gray-400">
															Available: {{ props.maxTransferrable }}
														</p>
													</div>
													<div class="w-1/2 sm:w-1/4">
														<input
															class="w-full rounded-xl border-gray-200"
															:class="[
																{
																	'border-red-400 ring-red-400 focus:border-red-400 focus:ring-red-400':
																		!formValid,
																},
															]"
															type="number"
															v-model="state.quantity"
															min="1"
															:max="props.maxTransferrable"
														/>
													</div>
												</div>
												<div
													v-if="state.quantity > props.maxTransferrable || state.quantity < 1"
													class="mt-6 bg-red-100 rounded-xl px-4 py-4 text-red-600 space-y-2"
												>
													<p class="font text-2xl">
														<span
															class="inline-flex justify-center items-center px-3 bg-red-600 text-white text-xl aspect-square rounded-full leading-none mr-2"
														>
															<i class="fa fa-exclamation"></i>
														</span>
														<span class="capitalize">Error</span>
													</p>
													<p class="text-base">
														{{
															state.quantity > props.maxTransferrable
																? 'Quantity cannot be more than available'
																: state.quantity < 1
																? 'Quantity must be 1 or more'
																: ''
														}}
													</p>
												</div>
											</div>
										</div>
									</template>
								</wallet-connected>
							</template>

							<template v-if="state.txInProgress">
								<div class="flex flex-col h-full justify-center items-center">
									<p class="mb-4">
										{{
											!state.isMetaMaskConfirmed
												? 'Please complete the transaction in MetaMask'
												: 'Deposit transaction pending...'
										}}
									</p>
									<i class="fa-2xl fa-sharp fa-solid fa-spinner-third fa-spin"></i>
								</div>
							</template>

							<div v-if="state.confirm && !state.success && !state.txInProgress">
								<div class="mt-6 bg-red-100 rounded-xl px-4 py-4 text-red-600 space-y-2">
									<p class="font text-2xl">
										<span
											class="inline-flex justify-center items-center px-3 bg-red-600 text-white text-xl aspect-square rounded-full leading-none"
										>
											<i class="fa fa-exclamation"></i>
										</span>
										Warning
									</p>
									<p class="text-base">
										This action <strong class="uppercase">cannot</strong> be un-done. Double check
										everything before pressing <em>Confirm</em> below.
									</p>
								</div>
							</div>

							<div v-if="state.confirm && state.success">
								<div class="mt-6 bg-green-100 rounded-xl px-4 py-4 text-green-600 space-y-2">
									<p class="font text-2xl">
										<span
											class="inline-flex justify-center items-center px-3 bg-green-600 text-white text-xl aspect-square rounded-full leading-none mr-2"
										>
											<i class="fa fa-exclamation"></i>
										</span>
										<span class="capitalize">Transaction</span> Complete
									</p>
									<p class="text-base">Your deposit is complete.</p>
								</div>
							</div>
						</div>
					</section>
				</div>
				<div class="mt-4 bg-gray-100 text-right space-x-6 pt-6 pb-4 px-6">
					<button v-if="!state.success && !state.txInProgress" class="text-gray-500" @click.stop="cancel">
						Cancel
					</button>

					<button
						v-if="!state.confirm"
						class="btn-primary-lg text-white space-x-1"
						:disabled="!formValid || !isOnCorrectNetwork"
						@click="send"
					>
						<i class="fa-solid fa-paper-plane" />
						Deposit
					</button>

					<button
						v-if="state.confirm && !state.success"
						class="btn-primary-lg text-white space-x-1"
						@click="confirmSend"
						:disabled="state.txInProgress"
					>
						<span v-if="!state.txInProgress">
							<i class="fa-solid fa-paper-plane" />
							Confirm
						</span>
						<span v-else>
							<i class="fa-sharp fa-solid fa-spinner-third fa-spin"></i>
							Sending
						</span>
					</button>

					<a
						v-if="state.confirm && state.success && state.tx !== null"
						:href="`https://polygonscan.com/tx/${state.tx?.transactionHash}`"
						target="_blank"
						class="btn-primary-lg text-white space-x-6 hover:bg-sky-600"
						>View Transaction</a
					>

					<button
						v-if="state.confirm && state.success"
						class="btn-primary-lg text-white space-x-6"
						@click="transferComplete"
					>
						Done
					</button>
				</div>
			</div>
		</template>
	</modal>
</template>
<script lang="ts" setup>
import { computed, reactive, onMounted, ComputedRef } from 'vue'
import { Asset, OwnedToken } from '@/types/Asset'

import Bugsnag from '@bugsnag/js'
import { wasNotDeclined } from '@/util/Errors'
import IERC721 from '@/abi/IERC721.json'
import IERC1155 from '@/abi/IERC1155.json'

import sleep from '@/util/sleep'
import metamask from '@/util/metamask'
import { ContractAbi } from 'web3-types'
import { Contract } from 'web3-eth-contract'

onMounted(async () => {
	if (metamask.state.chain !== +props.asset.network) {
		changeNetworks()
	}
})

async function changeNetworks() {
	state.isWaitingOnMetamask = true
	try {
		const res = await metamask.requestChain(+props.asset.network)
		state.isWaitingOnMetamask = false
		if (typeof res !== 'boolean') {
			throw Error(res)
		}
	} catch (err) {
		console.error(err)
	}
}

const props = withDefaults(
	defineProps<{
		asset: OwnedToken | Asset | any
		maxTransferrable: number
	}>(),
	{},
)

const emit = defineEmits<{
	(e: 'close'): void
	(e: 'complete', asset: Asset): void
}>()

interface SearchResult {
	username: string
	objectID: string
}

const state = reactive({
	quantity: 1,
	recipient: null as SearchResult | null,
	confirm: false,
	pin: null,
	skipConfirm: false,
	success: null as boolean | null,
	message: null as string | null,
	currentWithdrawalAmount: null,
	txInProgress: false,
	txComplete: false,
	txError: null,
	tx: null,
	isWaitingOnMetamask: false,
	isMetaMaskConfirmed: false,
})

const formValid = computed(() => {
	if (state.quantity < 1) return false
	if (state.quantity > props.maxTransferrable) return false

	return true
})

const safeAddress: ComputedRef<string> = computed(() => {
	const polySafe = import.meta.env.VITE_POLYGON_SAFE_ADDRESS
	const ethSafe = import.meta.env.VITE_ETH_SAFE_ADDRESS

	switch (+props.asset.network) {
		case 137:
			return polySafe
		case 1:
			return ethSafe
		default:
			return ''
	}
})

const isOnCorrectNetwork: ComputedRef<boolean> = computed(() => {
	return metamask.state.chain === +props.asset.network
})

function send() {
	state.confirm = true
}

async function depositAsset() {
	state.txError = null
	state.txInProgress = true
	state.txComplete = false

	const currentChain = await metamask.getCurrentChain()

	if (currentChain !== +props.asset.network) {
		try {
			const res = await metamask.requestChain(+props.asset.network)
			if (typeof res !== 'boolean') {
				throw Error(res)
			}
		} catch (err) {
			console.error(err)
		}
		state.txInProgress = false
		state.confirm = false
		return
	}

	let deposit: Contract<ContractAbi>

	const safeTransferArgs = [metamask.state.wallet, safeAddress.value, props.asset.tokenId]

	if (props.asset.tokenType == 'ERC721') {
		deposit = await metamask.loadContract(IERC721, props.asset.contract.address)
	} else if (props.asset.tokenType == 'ERC1155') {
		deposit = await metamask.loadContract(IERC1155, props.asset.contract.address)

		// ERC1555 "safeTransferFrom" method requires extra arguments for the quantity and data
		safeTransferArgs.push(state.quantity, [])
	}

	// @ts-ignore - this is a valid call, signature takes multiple arguments so spread is fine
	const tx = deposit.methods.safeTransferFrom(...safeTransferArgs)

	console.log(tx)

	let gasEstimate
	try {
		gasEstimate = await tx.estimateGas({
			from: metamask.state.wallet,
			gas: 500_000,
		})
	} catch (e) {
		console.error({ estimateGas: e, metamask: metamask.state })
		state.txInProgress = false
		state.txError = e.toString()
		return
	}

	gasEstimate = Math.ceil(gasEstimate * 1.3)
	const wait = sleep(1)

	const receipt = await tx
		.send({
			from: metamask.state.wallet,
			gas: gasEstimate,
			maxPriorityFeePerGas: null,
			maxFeePerGas: null,
		})
		.on('transactionHash', (hash) => {
			console.log(hash)
			state.isMetaMaskConfirmed = true
		})
		.on('receipt', async (r) => {
			await wait

			console.log({ receipt: r })

			state.txComplete = true
			state.txInProgress = false
			state.tx = r
			state.success = true
		})
		.catch((err: any) => {
			state.txComplete = false
			state.txInProgress = false
			state.success = false

			if (wasNotDeclined(err)) {
				state.txError = err
				console.error(err)
				Bugsnag.notify(err)
			} else {
				cancel()
			}
		})
}

async function confirmSend() {
	depositAsset()
}

function cancel() {
	state.quantity = 1
	state.recipient = null
	emit('close')
}

function transferComplete() {
	emit('complete', props.asset)
}
</script>
