<template>
	<section v-if="state.loading" class="flex justify-center items-center">
		<i class="text-5xl fa-regular fa-spinner-third fa-spin"></i>
	</section>
	<section ref="activityTable" v-else>
		<div class="flex justify-between items-baseline mx-4 md:mx-0">
			<h2 class="text-lg font-medium text-gray-900 inline-flex">
				<ArrowsUpDownIcon class="stroke-2 mr-2 h-6 w-6 text-gray-900 -rotate-180" />
				Item Activity
			</h2>
			<div
				v-if="state.pagination?.last_page && state.pagination?.last_page > 1"
				class="text-right text-gray-400 text-sm mx-4"
			>
				Viewing page {{ state.pagination?.current_page }} of {{ state.pagination?.last_page }}
			</div>
		</div>

		<div class="mt-2 divide-y divide-gray-200 border-t border-gray-200 md:border md:rounded-xl overflow-x-auto">
			<table class="min-w-full divide-y table-auto divide-gray-200">
				<thead class="bg-gray-200/60 hidden md:table-header-group">
					<tr>
						<th
							class="w-20 md:w-48 font-normal text-left text-gray-900 text-sm px-2 py3 table-auto"
							:class="[props.showAsset ? 'hidden md:table-cell' : '']"
						>
							Event
						</th>
						<th
							scope="col"
							class="md:w-48 px-2 pl-4 py-3 text-left table-auto text-sm font-normal text-gray-900"
						>
							Details
						</th>
						<th
							scope="col"
							class="hidden md:table-cell px-2 py-3 text-left text-sm table-auto w-20 font-normal text-gray-900"
						>
							Price
						</th>
						<th
							scope="col"
							class="hidden md:table-cell px-2 py-3 text-left text-sm table-auto w-28 font-normal text-gray-900"
						>
							Seller
						</th>

						<th
							scope="col"
							class="hidden md:table-cell px-2 pr-4 py-3 text-left text-sm font-normal w-28 text-gray-900"
						>
							Date
						</th>
					</tr>
				</thead>

				<tbody class="divide-y divide-gray-200 bg-white">
					<tr v-for="item in state.data">
						<td class="pl-6 md:px-6 text-gray-600" :class="[props.showAsset ? 'hidden md:table-cell' : '']">
							<div class="flex text-xs md:text-sm space-x-2 items-center">
								<Action :action="item.action" />
							</div>
						</td>

						<td class="whitespace-nowrap px-2 py-4 text-sm font-medium text-gray-900">
							<StorefrontActivityAsset
								:show-asset="props.showAsset"
								:asset="(item.asset as Asset)"
								:slug="getSlug(item.asset?.storefront_id)"
								:quantity="getItemQuantity(item)"
								:price="itemBPXPrice(item)"
								:username="itemUser(item)"
								:time="`${formatDistanceToNow(new Date(item.timestamp))} ago`"
							>
								<template #event v-if="props.showAsset">
									<div
										class="md:hidden flex text-xs md:text-sm space-x-2 items-center pb-2 text-gray-500"
									>
										<template v-if="item.activity_action == 'listing-created'">
											<ClipboardIcon class="w-6 h-6" />
											<div>Listing</div>
										</template>

										<template
											v-if="
												['listing-cancelled', 'listing-replaced'].indexOf(
													item.activity_action,
												) >= 0
											"
										>
											<TrashIcon class="w-6 h-6" />
											<div>Cancelled Listing</div>
										</template>

										<template v-if="'order-executed' == item.activity_action">
											<ShoppingCartIcon class="w-6 h-6" />
											<span
												v-if="
													!authStore.authenticated ||
													authStore.user.id != item.model.listing.seller_blokpax_user_id
												"
											>
												Purchase
											</span>
											<span v-else> Sale </span>
										</template>

										<template v-if="authStore.authenticated">
											<template
												v-if="
													'transfer' == item.activity_action &&
													item.model.recipient.id == authStore.user.id
												"
											>
												<InboxIcon class="w-6 h-6" />
												<div>Received</div>
											</template>
											<template
												v-else-if="
													'transfer' == item.activity_action &&
													item.model.sender.id == authStore.user.id
												"
											>
												<PaperAirplaneIcon class="w-6 h-6" />
												<div>Sent</div>
											</template>
										</template>
										<template v-else>
											<ArrowsRightLeftIcon class="w-6 h-6" />
											<div>Transfer</div>
										</template>
									</div>
								</template>
							</StorefrontActivityAsset>
						</td>

						<td class="hidden md:table-cell whitespace-nowrap px-2 py-3 ml-24 text-sm text-gray-500">
							<template v-if="itemBPXPrice(item)">
								<div class="">{{ $format(humanReadablePrice(itemBPXPrice(item))) }} {{ $token }}</div>
							</template>
						</td>
						<td class="hidden md:table-cell whitespace-nowrap px-2 pr-4 py-3 text-sm text-gray-500">
							@{{ itemUser(item) }}
						</td>

						<td class="hidden md:table-cell whitespace-nowrap px-2 pr-4 py-3 text-sm text-gray-500">
							<span :title="item.timestamp">
								{{ formatDistanceToNow(new Date(item.timestamp)) }} ago
							</span>
						</td>
					</tr>
				</tbody>
			</table>
		</div>

		<div class="mt-1">
			<div
				v-if="state.pagination?.last_page && state.pagination?.last_page > 1"
				class="text-right text-gray-400 text-sm mx-4"
			>
				Viewing page {{ state.pagination?.current_page }} of {{ state.pagination?.last_page }}
			</div>
		</div>

		<paginator
			v-if="state.pagination?.current_page && state.pagination?.last_page > 1"
			class="my-6"
			:showPages="5"
			:pages="state.pagination?.last_page"
			:current="state.pagination?.current_page"
			@selectPage="updatePage"
		/>
	</section>
</template>
<script lang="ts" setup>
import sleep from '@/util/sleep'
import { onMounted, reactive, ref } from 'vue'
import { formatDistanceToNow } from 'date-fns'

import { useMarketStore } from '@/stores/MarketStore'
import { useAuthStore } from '@/stores/AuthStore'

import { humanReadablePrice } from '@/util/currencyFormat'
import {
	PaperAirplaneIcon,
	InboxIcon,
	ShoppingCartIcon,
	ClipboardIcon,
	ArrowsUpDownIcon,
	TrashIcon,
	ArrowsRightLeftIcon,
} from '@heroicons/vue/24/outline'
import ToUsd from '@/components/ToUsd.vue'
import Paginator from '@/components/widgets/Paginator.vue'
import StorefrontActivityAsset from '@/components/StorefrontActivityAsset.vue'
import Action from '@/components/Activity/Action.vue'
import { Asset, AssetActivity } from '@/types/Asset'
import State from 'pusher-js/types/src/core/http/state'

const authStore = useAuthStore()
const marketStore = useMarketStore()

type ActivityPagination = {
	current_page: number
	from: number
	last_page: number
	links: {
		url: null | string
		label: string
		active: boolean
	}[]
	path: string
	per_page: number
	to: number
	total: number
}
type AssetActivityLoader = (page: number | null) => Promise<{
	data: AssetActivity[]
	meta: ActivityPagination
}>

const props = withDefaults(
	defineProps<{
		dataLoader: AssetActivityLoader
		showAsset?: boolean
	}>(),
	{
		showAsset: true,
	},
)

const state = reactive<{
	loading: boolean
	currentPage: number
	data: AssetActivity[]
	pagination: null | ActivityPagination
}>({
	loading: true,
	currentPage: 1,
	data: [],
	pagination: null,
})

const activityTable = ref(null)

onMounted(async () => {
	marketStore.load()
	await load()
})

async function load(page: number | null = null) {
	state.loading = true
	const { data, meta } = await props.dataLoader(page ?? state.currentPage)
	state.data = data
	state.pagination = meta
	state.loading = false
}

function updatePage(num: number) {
	const wait = sleep(0.75)
	state.loading = true
	load(num)
		.then(() => {
			state.currentPage = num
		})
		.then(() => {
			window.scrollTo({
				top: activityTable.value.offsetTop - 200,
				behavior: 'smooth',
			})
		})
		.then(wait)
		.then(() => (state.loading = false))
}

function getSlug(id: string) {
	return marketStore.byID[id]?.slug ?? ''
}

function itemBPXPrice(item) {
	if (item.total) {
		return item.total
	}

	if (item.amount) {
		return item.amount
	}

	return 0
	if (item.activity_action == 'listing-created') {
		return item.model.price * item.model.quantity
	} else if (item.activity_action == 'order-executed') {
		return item.model.listing.price * item.model.listing.quantity
	}

	return 0
}

function itemUser(item) {
	if (item.seller) {
		return item.seller.username
	}

	if (item.sender) {
		return item.sender.username
	}

	if (item.recipient) {
		return item.recipient.username
	}

	return ''

	if (['listing-created', 'listing-replaced', 'listing-cancelled'].indexOf(item.activity_action) >= 0) {
		return item.model.seller.username
	} else if (['transfer'].indexOf(item.activity_action) >= 0) {
		if (authStore.authenticated) {
			if (authStore.user.id == item.model.sender.id) {
				return item.model.recipient.username
			} else {
				return item.model.sender.username
			}
		}
	} else if (['order-executed'].indexOf(item.activity_action) >= 0) {
		// if ( ! authStore.authenticated || authStore.user.id == item.model.seller_user_id) {
		return item.model.listing.seller.username
		// } else {
		// 	return authStore.user.username;
		// }
	}
}

function getItemQuantity(item) {
	return item.quantity || 1
	if (['listing-created', 'listing-replaced', 'listing-cancelled'].indexOf(item.activity_action) >= 0) {
		return item.model.quantity
	} else if (item.activity_action == 'order-executed') {
		return item.model.listing.quantity
	} else if (item.activity_action == 'transfer') {
		return item.model.asset[0].amount
	}
}

defineExpose({
	load,
})
</script>
