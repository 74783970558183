<template>
	<main-navigation class="z-50 sticky top-0" />
	<banner-notice :notices="unref(accountStore.accountNotices)" @dismiss="accountStore.dismissNotice" />
	<slot />
	<div class="flex-auto"></div>
	<app-footer />
</template>
<script lang="ts" setup>
import { unref } from 'vue'
import BannerNotice from '@/components/notices/BannerNotice.vue'
import { useAccountStore } from '@/stores/AccountStore'
const accountStore = useAccountStore()
</script>
